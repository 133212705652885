import { ReactNode } from 'react';
import c from 'classnames';
import './index.scss';

interface ComponentProps {
  icon: ReactNode;
  title: string;
  content: string;
  onClick: () => void;
  className?: string;
  noBackgroundIcon?: boolean;
  disabled?: boolean;
}

const ConditionListItem: React.FC<ComponentProps> = ({
  title,
  icon,
  content,
  onClick,
  className,
  noBackgroundIcon,
  disabled,
}) => (
  <button key={title} className={c('condition-list__box', className)} disabled={disabled} onClick={onClick}>
    <div className={c('condition-list__box-icon', { 'no-background': noBackgroundIcon })}>{icon}</div>
    <div className="condition-list__box-wrapper">
      <div className="condition-list__box-title">{title}</div>
      <div className="condition-list__box-content">{content}</div>
    </div>
  </button>
);

export default ConditionListItem;
