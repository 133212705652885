import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  CustomBackdrop,
  ChannelItem,
  DialogConfirmation,
  IconDelay,
  IconWrapper,
  NavHeader,
  Page,
  PopUpNav,
} from '../../components';
import { IconDelete, IconDuplicate, IconEdit } from '../../components/popup-nav/icons';
import { TriggerChannelParameters } from '../../components/trigger/channel-parameters';
import { TriggerConditionParameters } from '../../components/trigger/condition-parameters';
import { PriceConditionDetails } from '../../components/trigger/price-condition-details';
import { TriggerSwitch } from '../../components/trigger/switch';
import { TimeConditionDetails } from '../../components/trigger/time-condition-details';
import { TriggerDetailsHeader } from '../../components/trigger/trigger-header';
import {
  DeleteTriggerMutation,
  DeleteTriggerMutationVariables,
  TriggerActionType,
  TriggerDetailsQuery,
  TriggerDetailsQueryVariables,
  TriggerValidationErrorType,
  UserPermissionType,
} from '../../data-access/gql-types/graphql';
import { DELETE_TRIGGER } from '../../data-access/mutations/trigger';
import { TRIGGER_DETAILS } from '../../data-access/queries/triggers';
import { useInstallation } from '../../hooks';
import { usePermissionsContext } from '../../hooks/user-permissions';
import { ROUTES } from '../../routes';
import { toastError, toastInfo, toastSuccess } from '../../utils/toast';
import { ActionItem, TriggerLastExecution, TriggerNextExecution, TriggerRemainingExecutionCount } from './components';
import './index.scss';

interface LocationStateType {
  back?: number;
}

const TriggerDetailsModule: React.FC = () => {
  const history = useHistory();
  const location = useLocation<LocationStateType>();
  const { selectedInstallationId, skipLavvaFetch } = useInstallation();
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { triggerId } = useParams<{ triggerId: string }>();
  const { permissions } = usePermissionsContext();
  const [showDuplicateDialog, setShowDuplicateDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const { data, loading, refetch } = useQuery<TriggerDetailsQuery, TriggerDetailsQueryVariables>(TRIGGER_DETAILS, {
    variables: { installationId: selectedInstallationId, id: triggerId },
    fetchPolicy: 'cache-and-network',
    onError: () => toastError({ content: tc('errors.downloadData') }),
    skip: skipLavvaFetch,
  });
  const [deleteTrigger] = useMutation<DeleteTriggerMutation, DeleteTriggerMutationVariables>(DELETE_TRIGGER, {
    onCompleted: (data) => {
      if (data.deleteTrigger) {
        onBack();
        toastSuccess({ content: t('trigger.removeSuccess') });
      }
    },
  });

  const refetchTrigger = useCallback(() => refetch({ installationId: selectedInstallationId, id: triggerId }), []);

  const onBack = () => {
    if (location.state?.back) history.go(-location.state.back);
    else history.goBack();
  };

  const duplicateTrigger = useCallback(
    (triggerId: string) => {
      history.push({
        pathname: ROUTES.TriggerCreate(),
        state: {
          triggerId,
        },
      });
    },
    [triggerId],
  );

  const trigger = useMemo(() => data?.trigger, [data]);

  useEffect(() => {
    if (data) {
      if (!data.trigger) return onBack();
      if (!data.trigger.isValid) {
        const errors = new Set(data.trigger.validationErrors.map((error) => error.errorType));
        Array.from(errors).forEach((error) => {
          switch (error) {
            case TriggerValidationErrorType.ChannelNotFound:
              toastInfo({ content: t('trigger.validationErrors.deviceNotFound') });
              break;
            case TriggerValidationErrorType.CircularExecution:
              toastInfo({ content: t('trigger.validationErrors.circularExecution') });
              break;
            case TriggerValidationErrorType.General:
              toastInfo({ content: t('trigger.validationErrors.general') });
              break;
          }
        });
      }
    }
  }, [data]);

  const onDuplicateCancel = useCallback(() => setShowDuplicateDialog(false), [setShowDuplicateDialog]);
  const onDuplicate = useCallback(() => duplicateTrigger(triggerId), [setShowDuplicateDialog, triggerId]);
  const onDeleteCancel = useCallback(() => setShowDeleteDialog(false), [setShowDeleteDialog]);
  const onDelete = useCallback(
    () => deleteTrigger({ variables: { input: { triggerId, installationId: selectedInstallationId } } }),
    [setShowDeleteDialog, triggerId],
  );

  const popupLinks = useMemo(() => {
    if (!trigger) return [];
    return [
      ...(permissions[UserPermissionType.TriggersEdit]
        ? [
            {
              onClick: () => history.push(ROUTES.TriggerEdit(triggerId)),
              label: t('edit'),
              hasLineBelow: true,
              icon: <IconEdit />,
            },
          ]
        : []),
      ...(permissions[UserPermissionType.TriggersCreate]
        ? [{ onClick: () => setShowDuplicateDialog(true), label: t('duplicate'), icon: <IconDuplicate /> }]
        : []),
      ...(permissions[UserPermissionType.TriggersDelete]
        ? [{ onClick: () => setShowDeleteDialog(true), label: t('remove'), icon: <IconDelete /> }]
        : []),
    ];
  }, [trigger, permissions]);

  if (loading && !data) return <CustomBackdrop loading />;
  if (!trigger) return null;

  return (
    <Page
      containerClassName={'p-t-0 p-r-0 p-b-0 p-l-0 page__container--flex-height'}
      headerClassName={'p-t-24 p-r-24 p-b-0 p-l-24'}
      contentClassName={'page__content--background-element page__content--unset-height p-r-24 p-b-48 p-l-24'}
      header={<NavHeader onClick={onBack}>{<PopUpNav links={[...popupLinks]} />}</NavHeader>}
    >
      <div className="trigger-details">
        <TriggerDetailsHeader trigger={trigger} />
        <div className="m-t-28">
          <div className="trigger-header">
            <div className="trigger-header__switch">
              <h4 className="trigger-header__heading">
                {trigger.isActive ? t('trigger.active') : t('trigger.inactive')}
              </h4>
              <TriggerSwitch refetch={refetchTrigger} trigger={trigger} />
            </div>
          </div>

          <div className="trigger-header m-b-24">
            <h4 className="trigger-header__heading">{`${t('trigger.failureMode.label')}: ${
              data?.trigger?.onFailureMode ? t(`trigger.failureMode.${data.trigger.onFailureMode}`) : '-'
            } `}</h4>
          </div>

          <hr className="m-b-24" />

          <div className="action-conditions">
            <h2 className="action-conditions__heading">{t('trigger.if')}</h2>
            {Object.values(trigger.timeCondition ?? {}).length && trigger.timeCondition ? (
              <TimeConditionDetails timeCondition={trigger?.timeCondition} />
            ) : null}
            {trigger.stateConditions.map((stateCondition) => {
              const isOn = stateCondition.booleanValue ?? false;

              return (
                <div key={stateCondition.id} className="action-condition">
                  <ChannelItem
                    id={stateCondition.id}
                    isOn={isOn}
                    details={<TriggerConditionParameters condition={stateCondition} />}
                  />
                </div>
              );
            })}
            {Object.values(trigger.priceAndTimeCondition ?? {}).length && trigger.priceAndTimeCondition ? (
              <PriceConditionDetails priceCondition={trigger?.priceAndTimeCondition} />
            ) : null}
          </div>

          <hr className="m-b-24" />

          <div className="action-conditions">
            <h2 className="action-conditions__heading">{t('trigger.then')}</h2>
            {trigger.actions.map((action, index) =>
              action.actionType === TriggerActionType.DelayInSeconds ? (
                <div key={`${action.id}-${index}`} className="action-condition">
                  <IconWrapper>
                    <IconDelay />
                  </IconWrapper>
                  <div className="action-condition__content">
                    <h4 className="action-condition__title">{t('trigger.delay')}</h4>
                    <p className="action-condition__text">
                      <span>
                        {action.integerValue} {t('trigger.delayUnitShort')}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <ActionItem
                  key={action.id}
                  isOn
                  action={action}
                  details={<TriggerChannelParameters action={action} />}
                />
              ),
            )}
          </div>

          {Object.values(trigger.timeCondition ?? {}).length && trigger.timeCondition ? (
            <>
              <hr className="m-b-24" />
              <TriggerLastExecution trigger={trigger} />
              <TriggerNextExecution trigger={trigger} />
              <TriggerRemainingExecutionCount
                currentExecutionCount={trigger.timeCondition.currentExecutionCount}
                targetExecutionCount={trigger.timeCondition.targetExecutionCount}
              />
            </>
          ) : null}
          {Object.values(trigger.priceAndTimeCondition ?? {}).length && trigger.priceAndTimeCondition ? (
            <>
              <hr className="m-b-24" />
              <TriggerLastExecution trigger={trigger} />
              <TriggerNextExecution trigger={trigger} />
              <TriggerRemainingExecutionCount
                currentExecutionCount={trigger.priceAndTimeCondition.currentExecutionCount}
                targetExecutionCount={trigger.priceAndTimeCondition.targetExecutionCount}
              />
            </>
          ) : null}
        </div>
      </div>
      <DialogConfirmation
        show={showDuplicateDialog}
        setShow={setShowDuplicateDialog}
        header={t('advanced.duplicateDialog.header', { name: trigger?.name || '' })}
        primaryBtnText={tc('buttons.duplicate')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={onDuplicate}
        secondaryBtnAction={onDuplicateCancel}
      />
      <DialogConfirmation
        show={showDeleteDialog}
        setShow={setShowDeleteDialog}
        header={t('advanced.removeDialog.header', { name: trigger?.name || '' })}
        primaryBtnText={tc('buttons.remove')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={onDelete}
        secondaryBtnAction={onDeleteCancel}
      />
    </Page>
  );
};

export default TriggerDetailsModule;
