import React, { ChangeEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import * as uuid from 'uuid';
import { IconCheckbox } from '../icons';
import './index.scss';

interface Props {
  children?: ReactNode;
  checked?: boolean;
  id?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isFullWidth?: boolean;
  disabled?: boolean;
  isUnavailable?: boolean;
  reverse?: boolean;
  labelBold?: boolean;
  className?: string;
}

export const Checkbox: React.FC<Props> = ({
  children,
  id = uuid.v4(),
  checked = false,
  onChange,
  isFullWidth = false,
  disabled = false,
  isUnavailable = false,
  reverse = false,
  labelBold = false,
  className,
}: Props) => (
  <div
    className={classNames('checkbox', className, {
      'checkbox--full-width': isFullWidth,
      'checkbox--unavailable': isUnavailable,
      'checkbox--width-auto': !children,
      reverse: reverse,
    })}
  >
    <input type="checkbox" id={id} checked={checked} disabled={disabled} value={id} onChange={onChange} />
    <label htmlFor={id}>
      {children && <div className={classNames('checkbox__label', { bold: labelBold })}>{children}</div>}
      <div className="checkbox__icon">
        <IconCheckbox isChecked={checked} isDisabled={disabled} />
      </div>
    </label>
  </div>
);
