import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxesOptions } from '.';
import { Checkbox, DialogConfirmation } from '..';

interface Props<T> {
  show: boolean;
  setShow: (show: boolean) => void;
  options: CheckboxesOptions<T>[];
  onChange: (value: T) => void;
  handleSave: () => void;
  label?: string;
}

export const DialogCheckboxes = <T,>({ show, setShow, options, onChange, handleSave, label }: Props<T>) => {
  const { t } = useTranslation('common');

  return (
    <DialogConfirmation
      show={show}
      setShow={setShow}
      primaryBtnText={t('buttons.save')}
      secondaryBtnText={t('buttons.cancel')}
      primaryBtnAction={handleSave}
      secondaryBtnAction={() => setShow(false)}
      {...(label ? { header: label } : {})}
    >
      <ul>
        {options
          .filter((x) => x.label && !x.disabled)
          .map((option, index) => {
            return (
              <li key={option.label}>
                <Checkbox
                  reverse
                  className="m-b-0 color-primary"
                  checked={option.checked}
                  onChange={() => {
                    onChange(option.value);
                  }}
                >
                  <div className="dialog-select__option-item-label bold">{option.label}</div>
                </Checkbox>
                {index !== options.length - 1 && <hr />}
              </li>
            );
          })}
      </ul>
    </DialogConfirmation>
  );
};
