import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { WRONG_MEASUREMENT_VALUE } from '../../../../../const';
import { ChannelTypeInternal, OptimizerModeInternal } from '../../../../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../../../../types';
import { ChannelOptimizerInterface } from '../../../../../types/channel/optimizer';
import { OptimizerConfigurationHeaterPowerLimit } from '../components/heater-power-limit';
import { OptimizerConfigurationPhaseVoltageThreshold } from '../components/phase-voltage-threshold';
import { OptimizerConfigurationRelays } from '../components/relays';
import { TariffForm } from '../../../../common/tariff';
import { OptimizerConfigurationTemperature } from '../components/temperature';
import { OptimizerConfigurationTemperatureAutoconsumption } from '../components/temperature-autoconsumption';
import { WorkingHours } from '../components/working-hours';
import { useOptimizerFullConsumption } from '../hooks/use-optimizer-full-consumption';
import { useOptimizerSetData } from '../hooks/use-optimizer-set-data';
import { useOptimizerSupport } from '../hooks/use-optimizer-support';
import { FullConsumptionFormBody, initialRelay } from '../types';

interface ComponentProps {
  mode: OptimizerModeInternal;
  channel: ChannelInterface;
  temperature?: number;
}

export const OptimizerConfigurationWorkModeAutoConsumptionFull: React.FC<ComponentProps> = ({
  mode,
  channel,
  temperature,
}) => {
  const { t: tc } = useTranslation('common');
  const { submitConfiguration } = useOptimizerFullConsumption(channel);
  const form = useForm<FullConsumptionFormBody>({
    defaultValues: {
      tempMin: 0,
      tempMinEnabled: false,
      tempMax: 0,
      tempSelfConsumption: 0,
      heaterPowerLimit: 0,
      voltageThreshold1: 0,
      voltageThreshold2: 0,
      voltageThreshold3: 0,
      relayConfig1: { ...initialRelay, index: 1 },
      relayConfig2: { ...initialRelay, index: 2 },
      relayConfig3: { ...initialRelay, index: 3 },
      relayConfig4: { ...initialRelay, index: 4 },
      from: '00:00',
      to: '00:00',
      operator: '',
      tariffName: '',
      wholeWeekendCheaper: false,
      wholeHolidaysCheaper: false,
      ignoreSummerTimeChanges: false,
      hoursRanges: [],
    },
  });
  const { tempMinEnabled } = form.watch();
  const { tariffSupported } = useOptimizerSupport(channel);
  const { setOptimizerData } = useOptimizerSetData(form.setValue, 'fullSelfConsumption');

  useEffect(() => {
    if (channel) setOptimizerData(channel);
  }, [channel]);

  const tempMinOption = useMemo(() => {
    const payload = (channel.data as ChannelOptimizerInterface).payload;

    if (payload) return 'tempMinEnabled' in payload.fullSelfConsumption;
    return false;
  }, []);

  const onSubmit = form.handleSubmit((values) => {
    submitConfiguration(mode, values);
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <div
          className={classNames('optimizer-configuration-form__item', {
            disabled: temperature === WRONG_MEASUREMENT_VALUE,
          })}
        >
          <OptimizerConfigurationTemperature tempMinOption={tempMinOption} minTemperatureExists />
        </div>

        {tempMinEnabled && tariffSupported && (
          <>
            <hr className="m-t-24 m-b-24" />

            <div className="optimizer-configuration-form__item">
              <TariffForm type={ChannelTypeInternal.Optimizer} dynamicTariff={false} />
            </div>
          </>
        )}

        <hr className="m-t-24 m-b-24" />

        <div
          className={classNames('optimizer-configuration-form__item', {
            disabled: temperature === WRONG_MEASUREMENT_VALUE,
          })}
        >
          <OptimizerConfigurationTemperatureAutoconsumption />
        </div>

        <hr className="m-t-24 m-b-24" />

        <div className="optimizer-configuration-form__item">
          <OptimizerConfigurationHeaterPowerLimit />
        </div>

        <hr className="m-t-24 m-b-24" />

        <div className="optimizer-configuration-form__item">
          <OptimizerConfigurationPhaseVoltageThreshold />
        </div>

        <hr className="m-t-24 m-b-24" />

        <div className="optimizer-configuration-form__item">
          <WorkingHours />
        </div>

        <hr className="m-t-24 m-b-24" />

        <div className="optimizer-configuration-form__item">
          <OptimizerConfigurationRelays thresholdPercent={false} sumExists />
        </div>

        <div className="button-container">
          <button className="button m-t-48 max-width-desktop" type="submit">
            {tc('buttons.save')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
