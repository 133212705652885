import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { LockExecutionTask, NotifyTask } from 'lavva.exalushome/build/js/Services/Scenes/SceneTaskTypes';
import { ISequence, SceneTaskType } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import {
  ArgumentParams,
  SequenceBuilder,
  SupportedTaskTypes,
} from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import { ActionType } from '../../../../action-create/types';
import {
  ConditionSourceType,
  ConditionType,
  DelayTaskParams,
  DeviceTaskParams,
  LockTaskParams,
  NotifyTaskParams,
  Task,
  TaskState,
  TaskVariant,
} from '../types';

interface ExalusCreateActionProviderInterface {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  icon: string;
  setIcon: Dispatch<SetStateAction<string>>;
  actionType: ActionType;
  setActionType: Dispatch<SetStateAction<ActionType>>;
  conditionType: ConditionType;
  setConditionType: Dispatch<SetStateAction<ConditionType>>;
  taskType: SceneTaskType;
  setTaskType: Dispatch<SetStateAction<SceneTaskType>>;
  taskVariant: TaskVariant;
  setTaskVariant: Dispatch<SetStateAction<TaskVariant>>;
  sourceType: ConditionSourceType;
  setSourceType: Dispatch<SetStateAction<ConditionSourceType>>;
  channels: IDeviceChannel[];
  setChannels: Dispatch<SetStateAction<IDeviceChannel[]>>;
  conditionList: ISequence[];
  taskList: Task[];
  builder: SequenceBuilder | null;
  allowedArguments: ArgumentParams[];
  supportedTaskTypes: SupportedTaskTypes[];
  setNewBuilder: () => void;
  addConditionToList: (condition: ISequence) => void;
  addTaskToList: (task: Task) => void;
  editCondition: (condition: ISequence) => void;
  removeCondition: (conditionId: string) => void;
  removeTask: (taskItem: string) => void;
  editDeviceTask: (id: string, taskState: TaskState) => void;
  editDelayTask: (id: string, delay: string) => void;
  editLockTask: (id: string, lock: LockExecutionTask) => void;
  editNotifyTask: (id: string, notify: NotifyTask) => void;
  setConditionList: Dispatch<SetStateAction<ISequence[]>>;
  setTaskList: Dispatch<SetStateAction<Task[]>>;
  setBuilder: Dispatch<SetStateAction<SequenceBuilder | null>>;
  setAllowedArguments: Dispatch<SetStateAction<ArgumentParams[]>>;
  setSupportedTaskTypes: Dispatch<SetStateAction<SupportedTaskTypes[]>>;
}

const initialState: ExalusCreateActionProviderInterface = {
  name: '',
  setName: () => null,
  icon: '',
  setIcon: () => null,
  actionType: ActionType.Undefined,
  setActionType: () => null,
  conditionType: ConditionType.Undefined,
  setConditionType: () => null,
  taskType: SceneTaskType.Unknown,
  setTaskType: () => null,
  taskVariant: TaskVariant.Undefined,
  setTaskVariant: () => null,
  sourceType: ConditionSourceType.Undefined,
  setSourceType: () => null,
  channels: [],
  setChannels: () => null,
  conditionList: [],
  taskList: [],
  builder: null,
  allowedArguments: [],
  supportedTaskTypes: [],
  setNewBuilder: () => null,
  addConditionToList: () => null,
  addTaskToList: () => null,
  editCondition: () => null,
  removeCondition: () => null,
  removeTask: () => null,
  setConditionList: () => null,
  setTaskList: () => null,
  setBuilder: () => null,
  editDeviceTask: () => null,
  editDelayTask: () => null,
  editLockTask: () => null,
  editNotifyTask: () => null,
  setAllowedArguments: () => null,
  setSupportedTaskTypes: () => null,
};

export const ExalusCreateActionContext = createContext<ExalusCreateActionProviderInterface>(initialState);

export const useExalusCreateActionContext = (): ExalusCreateActionProviderInterface =>
  useContext(ExalusCreateActionContext);

export const ExalusCreateActionContextProvider: React.FC = ({ children }) => {
  const [name, setName] = useState<string>(initialState.name);
  const [icon, setIcon] = useState<string>(initialState.icon);
  const [actionType, setActionType] = useState<ActionType>(initialState.actionType);
  const [conditionType, setConditionType] = useState<ConditionType>(initialState.conditionType);
  const [taskType, setTaskType] = useState<SceneTaskType>(initialState.taskType);
  const [taskVariant, setTaskVariant] = useState<TaskVariant>(initialState.taskVariant);
  const [sourceType, setSourceType] = useState<ConditionSourceType>(initialState.sourceType);
  const [channels, setChannels] = useState<IDeviceChannel[]>(initialState.channels);
  const [conditionList, setConditionList] = useState<ISequence[]>(initialState.conditionList);
  const [taskList, setTaskList] = useState<Task[]>(initialState.taskList);
  const [builder, setBuilder] = useState<SequenceBuilder | null>(new SequenceBuilder());
  const [allowedArguments, setAllowedArguments] = useState<ArgumentParams[]>([]);
  const [supportedTaskTypes, setSupportedTaskTypes] = useState<SupportedTaskTypes[]>([]);

  const setNewBuilder = () => {
    setName('');
    setIcon('');
    setChannels([]);
    setConditionList([]);
    setTaskList([]);
    setAllowedArguments([]);
    setSupportedTaskTypes([]);
    const b = new SequenceBuilder();
    setBuilder(b);
  };

  const addConditionToList = useCallback(
    (condition: ISequence) => {
      setConditionList([...conditionList, condition]);
    },
    [conditionList],
  );

  const addTaskToList = (task: Task) => setTaskList((prevState) => [...prevState, task]);

  const editCondition = useCallback(
    (condition: ISequence) => {
      const conditionListTemp = Array.from(conditionList);
      const index = conditionListTemp.findIndex((x) => x.Guid === condition.Guid);

      if (index !== -1) {
        conditionListTemp[index] = condition;
        setConditionList(conditionListTemp);
      }
    },
    [conditionList],
  );

  const removeCondition = useCallback(
    (conditionId: string) => {
      const conditionListTemp = Array.from(conditionList);
      const index = conditionListTemp.findIndex((x) => x.Guid === conditionId);

      if (index !== -1) {
        conditionListTemp.splice(index, 1);
        setConditionList(conditionListTemp);
      }
    },
    [conditionList],
  );

  const removeTask = useCallback(
    (taskId: string) => {
      const taskListTemp = Array.from(taskList);
      const index = taskListTemp.findIndex((x) => x.id === taskId);

      if (index !== -1) {
        taskListTemp.splice(index, 1);
        setTaskList(taskListTemp);
      }
    },
    [taskList],
  );

  const editDeviceTask = useCallback(
    (id: string, taskState: TaskState) => {
      const taskListTemp = Array.from(taskList);
      const index = taskListTemp.findIndex((x) => x.id === id);

      if (index !== -1) {
        (taskListTemp[index].taskParams as DeviceTaskParams).state = taskState;
        setTaskList(taskListTemp);
      }
    },
    [taskList],
  );

  const editDelayTask = useCallback(
    (id: string, delay: string) => {
      const taskListTemp = Array.from(taskList);
      const index = taskListTemp.findIndex((x) => x.id === id);

      if (index !== -1) {
        (taskListTemp[index].taskParams as DelayTaskParams).delay = delay;
        setTaskList(taskListTemp);
      }
    },
    [taskList],
  );

  const editLockTask = useCallback(
    (id: string, lock: LockExecutionTask) => {
      const taskListTemp = Array.from(taskList);
      const index = taskListTemp.findIndex((x) => x.id === id);

      if (index !== -1) {
        (taskListTemp[index].taskParams as LockTaskParams).lockType = lock.LockType;
        (taskListTemp[index].taskParams as LockTaskParams).lockStart = lock.LockStartHour.Value;
        (taskListTemp[index].taskParams as LockTaskParams).lockEnd = lock.LockEndHour.Value;
        setTaskList(taskListTemp);
      }
    },
    [taskList],
  );

  const editNotifyTask = useCallback(
    (id: string, notify: NotifyTask) => {
      const taskListTemp = Array.from(taskList);
      const index = taskListTemp.findIndex((x) => x.id === id);

      if (index !== -1) {
        (taskListTemp[index].taskParams as NotifyTaskParams).message = notify.Message;
        (taskListTemp[index].taskParams as NotifyTaskParams).notifyType = notify.NotifyType;
        setTaskList(taskListTemp);
      }
    },
    [taskList],
  );

  const values: ExalusCreateActionProviderInterface = {
    name,
    icon,
    actionType,
    conditionType,
    sourceType,
    channels,
    conditionList,
    taskType,
    taskVariant,
    builder,
    taskList,
    allowedArguments,
    supportedTaskTypes,
    setName,
    setIcon,
    setActionType,
    setConditionType,
    setSourceType,
    setChannels,
    setTaskType,
    setTaskVariant,
    setNewBuilder,
    addConditionToList,
    editCondition,
    removeCondition,
    addTaskToList,
    removeTask,
    setConditionList,
    setTaskList,
    editDeviceTask,
    editDelayTask,
    editLockTask,
    editNotifyTask,
    setBuilder,
    setAllowedArguments,
    setSupportedTaskTypes,
  };

  return <ExalusCreateActionContext.Provider value={values}>{children}</ExalusCreateActionContext.Provider>;
};
