import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { useSubscription } from '@apollo/client';
import { useSetInputType } from '../../../../api/modules/generic-input/generic-input.hooks';
import {
  GenericInputBistableModeInternal,
  GenericInputMonostableModeInternal,
  GenericInputTypeInternal,
  OnGenericInputStateChangeSubscription,
  OnGenericInputStateChangeSubscriptionVariables,
} from '../../../../data-access/gql-types/graphql';
import { ON_GENERIC_INPUT_STATE_CHANGE } from '../../../../data-access/subscriptions/lavva-devices';
import { useBackdropContext } from '../../../../hooks';
import { useDevicesAndChannelsContext } from '../../../../hooks/devices-and-channels/provider';
import { useTimeout } from '../../../../hooks/timeout/use-timeout';
import { ChannelInterface } from '../../../../types';
import { ChannelGenericInputInterface } from '../../../../types/channel/generic-input';
import { toastSuccess } from '../../../../utils/toast';
import { bistableModeForRequest, inputTypeForRequest, monostableModeForRequest } from '../utils';

export const useUpdateInputType = (inputChannel: ChannelInterface) => {
  const { t } = useTranslation('channel-settings');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { setTimeoutError, clearTimeoutError } = useTimeout();
  const { setChannelList } = useDevicesAndChannelsContext();
  const inputTypeGeneric = useSetInputType();
  const { data } = useSubscription<
    OnGenericInputStateChangeSubscription,
    OnGenericInputStateChangeSubscriptionVariables
  >(ON_GENERIC_INPUT_STATE_CHANGE, {
    variables: { channelId: inputChannel.id },
    skip: !inputChannel.id,
  });

  useEffect(() => {
    if (data?.onGenericInputStateChange && inputChannel) {
      turnOffBackdrop();

      setChannelList((prev) => {
        const tempList = cloneDeep(prev);
        const index = tempList.findIndex((x) => x.id === inputChannel.id);

        if (index !== -1) {
          (tempList[index].data as ChannelGenericInputInterface).genericInputType =
            data.onGenericInputStateChange.genericInputType;
          (tempList[index].data as ChannelGenericInputInterface).monostableMode =
            data.onGenericInputStateChange.monostableMode || undefined;
          (tempList[index].data as ChannelGenericInputInterface).bistableMode =
            data.onGenericInputStateChange.bistableMode || undefined;
        }

        clearTimeoutError();
        toastSuccess({ content: `${t('toast.editInputTypeSuccess', { alias: inputChannel.alias })}` });
        return tempList;
      });
    }
  }, [data]);

  const editInputType = (
    inputType: GenericInputTypeInternal,
    monostableMode: GenericInputMonostableModeInternal,
    bistableMode: GenericInputBistableModeInternal,
  ) => {
    if (inputChannel) {
      turnOnBackdrop();
      inputTypeGeneric.mutate(
        {
          deviceId: inputChannel.deviceId,
          channelId: inputChannel.id,
          genericInputType: inputTypeForRequest[inputType],
          ...(inputType === GenericInputTypeInternal.Monostable
            ? { monostableMode: monostableModeForRequest[monostableMode] }
            : {}),
          ...(inputType === GenericInputTypeInternal.Bistable
            ? { bistableMode: bistableModeForRequest[bistableMode] }
            : {}),
        },
        {
          onSuccess: () => {
            turnOnBackdrop();
            setTimeoutError();
          },
          onError: () => {
            turnOffBackdrop();
          },
        },
      );
    }
  };

  return {
    editInputType,
  };
};
