import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ChannelDetailsHeader, CustomBackdrop, NavHeader, Page, PopUpNav } from '../../components';
import { useDevicesAndChannelsContext } from '../../hooks/devices-and-channels/provider';
import { GroupsModifyPopup } from './components/groups-modify-popup';
import DetailsBody from './details-body';
import Favourite from './favourite';
import { useChannelDetails } from './hooks/use-channel-details';
import { useGetChannelDetails } from './hooks/use-get-channel-details';

interface RouteParams {
  channelId: string;
}

const ChannelDetails: React.FC = () => {
  const { channelId } = useParams<RouteParams>();
  const { setChannelDetails, clearChannelDetails } = useDevicesAndChannelsContext();
  const { data, loading } = useGetChannelDetails(channelId);
  const { popUpNavLinks, channel, groupPopup, groupList, setGroupPopup, handleSaveGroup, toggleGroup } =
    useChannelDetails();

  useEffect(() => {
    if (data?.userChannel) setChannelDetails(data.userChannel);
    return () => clearChannelDetails();
  }, [data]);

  return (
    <Page
      containerClassName={'page__container--flex-height p-t-16'}
      header={
        <NavHeader>
          {channel && <Favourite channel={channel} />}
          <PopUpNav links={popUpNavLinks} />
        </NavHeader>
      }
    >
      {channel && (
        <>
          <ChannelDetailsHeader channel={channel} />
          <DetailsBody channel={channel} />
        </>
      )}
      <CustomBackdrop loading={loading} />
      <GroupsModifyPopup
        show={groupPopup}
        setShow={setGroupPopup}
        handleSave={handleSaveGroup}
        toggleGroup={toggleGroup}
        groupList={groupList}
      />
    </Page>
  );
};

export default ChannelDetails;
