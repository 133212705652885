import React from 'react';
import classNames from 'classnames';
import { IconEdit, IconRadio, SelectOptionInterface } from '../../../../../../components';
import { Dialog } from '../../../../../../components/dialog/base';
import { PriceConditionType } from '../../enums';
import '../../index.scss';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  title: string;
  options: SelectOptionInterface<string>[];
  selectedOptionIndex: number;
  onChange: (selectedOptionIndex: number) => void;
  renderOptionDetails: (val: PriceConditionType) => JSX.Element | null;
}

export const PriceConditionDialog: React.FC<Props> = ({
  show,
  setShow,
  title,
  options,
  selectedOptionIndex,
  onChange,
  renderOptionDetails,
}) => {

  return (
    <Dialog show={show} setShow={setShow}>
      <div className="dialog-select">
        <h2 className="dialog-select__header">{title}</h2>
        <ul>
          {options.map((option, optionIndex) => {
            const isSelected = optionIndex === selectedOptionIndex;

            return (
              <li
                key={option.label}
                className={classNames('dialog-select__option-item', {
                  'dialog-select__option-item--selected': isSelected,
                })}
                onClick={() => onChange(optionIndex)}
              >
                <IconRadio className="dialog-select__option-item-icon" selected={isSelected} />
                <span className="dialog-select__option-item-label">
                  <span>
                    {option.label}
                    {isSelected && renderOptionDetails(option.value as PriceConditionType)}
                  </span>
                </span>
                {isSelected ? <IconEdit /> : null}
              </li>
            );
          })}
        </ul>
      </div>
    </Dialog>
  );
};
