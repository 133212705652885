import { groupBy } from 'lodash';
import { ChannelTypeInternal, UserDevice } from '../../data-access/gql-types/graphql';
import { DeviceInterface } from '../../types';

const getDeviceType = (userDevice: UserDevice) => {
  const types = groupBy(
    userDevice.payload?.channelInfos?.filter((x) => x?.channelType !== ChannelTypeInternal.GenericInput),
    'channelType',
  );
  if (Object.keys(types).includes(ChannelTypeInternal.Blind)) {
    return ChannelTypeInternal.Blind;
  }
  if (Object.keys(types).includes(ChannelTypeInternal.Gate)) {
    return ChannelTypeInternal.Gate;
  }
  if (Object.keys(types).includes(ChannelTypeInternal.Switch)) {
    return ChannelTypeInternal.Switch;
  }
  if (Object.keys(types).includes(ChannelTypeInternal.Light)) {
    return ChannelTypeInternal.Light;
  }
  if (Object.keys(types).includes(ChannelTypeInternal.Meter)) {
    return ChannelTypeInternal.Meter;
  }
  if (Object.keys(types).includes(ChannelTypeInternal.Optimizer)) {
    return ChannelTypeInternal.Optimizer;
  }
  return ChannelTypeInternal.Unknown;
};
export const parseDevice = (userDevice: UserDevice) => {
  const newDevice: DeviceInterface = {
    id: userDevice.id,
    installationId: userDevice.installationId,
    type: getDeviceType(userDevice),
    isUpdating: false,
    payload: userDevice.payload
      ? { ...userDevice.payload }
      : {
          __typename: 'DeviceStateModel',
          channelInfos: [],
          connectedToSsid: '',
          macAddress: '',
          currentFirmwareVersion: '',
          deviceId: '',
          isUpdateAvailable: false,
          model: '',
          name: '',
          newestFirmwareVersion: '',
          timeZone: '',
          lastConnectedAtUtc: '',
          registeredAtUtc: '',
        },
  };
  return newDevice;
};
