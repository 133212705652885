import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceUpdateShortInfo } from 'lavva.exalushome/build/js/Services/Updates/IUpdateInfo';
import { DialogConfirmation } from '../../../../../../components';
import { useDeviceListContext } from '../../context';
import { useDevices } from '../../hooks/use-devices';
import { useDevicesUpdates } from '../../hooks/use-devices-updates';
import './index.scss';

interface Props {
  updatesAvailability: DeviceUpdateShortInfo[];
}

export const UpdateDevices: React.FC<Props> = ({ updatesAvailability }) => {
  const { t } = useTranslation('device-list');
  const { t: tc } = useTranslation('common');
  const [showUpdateAllDialog, setShowUpdateAllDialog] = useState<boolean>(false);
  const { updateMode, selectedDeviceIds, toggleUpdateMode } = useDeviceListContext();
  const { devices } = useDevices({ updateMode, updatesAvailability });
  const { handleUpdateAll, setUpdateResultDialog, updateResultDialog } = useDevicesUpdates(devices);

  const onUpdateAll = () => {
    handleUpdateAll(() => {
      setShowUpdateAllDialog(false);
    });
  };

  return (
    <>
      <button className="devices-update" disabled={!updatesAvailability.length} onClick={toggleUpdateMode}>
        {!updateMode ? t('update') : tc('buttons.cancel')}
      </button>
      {!!(selectedDeviceIds.length && updateMode) && (
        <button
          className="devices-update"
          disabled={!updatesAvailability.length}
          onClick={() => setShowUpdateAllDialog(true)}
        >
          {t('update')}
        </button>
      )}
      <DialogConfirmation
        show={showUpdateAllDialog}
        setShow={setShowUpdateAllDialog}
        header={t('updateAllContent')}
        primaryBtnText={t('update')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={onUpdateAll}
        secondaryBtnAction={() => setShowUpdateAllDialog(false)}
      />
      <DialogConfirmation
        show={!!updateResultDialog.length}
        setShow={() => setUpdateResultDialog([])}
        header={t('updateSummary')}
        primaryBtnText={tc('buttons.understand')}
        primaryBtnAction={() => setUpdateResultDialog([])}
      >
        <ul>
          {updateResultDialog.map((upd, i) => (
            <li key={`${upd.name}-${i}`} className="m-b-24">
              <span style={{ textDecoration: 'none' }}>{`${upd.name}: `}</span>
              <span style={{ color: upd.color, textDecoration: 'none' }}>{upd.status}</span>
            </li>
          ))}
        </ul>
      </DialogConfirmation>
    </>
  );
};
