import React from 'react';
import { IconType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { availableIcon } from './available-icons';

interface PropsInterface {
  iconType?: IconType;
}

export const DeviceIcon: React.FC<PropsInterface> = ({ iconType }) =>
  iconType ? availableIcon[iconType] : availableIcon[IconType.Unknown];
