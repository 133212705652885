import { useEffect, useState } from 'react';
import { useExalusServicesContext } from '../../modules/exalus/context/services';

export const useAndroidPermissions = () => {
  const [androidLocationServiceAvailable, setAndroidLocationServiceAvailable] = useState<boolean>(false);
  const [androidNotificationsServiceAvailable, setAndroidNotificationsServiceAvailable] = useState<boolean>(false);
  const { androidLocationService, androidNotificationsService } = useExalusServicesContext();

  const checkIfAndroidLocationAvailable = () => {
    const result = androidLocationService.IsAndroidLocationAvailable();
    setAndroidLocationServiceAvailable(result);
  };

  const checkIfAndroidNotificationsAvailable = () => {
    const result = androidNotificationsService.IsAndroidNotificationsAvailable();
    setAndroidNotificationsServiceAvailable(result);
  };

  useEffect(() => {
    checkIfAndroidLocationAvailable();
    checkIfAndroidNotificationsAvailable();
  }, []);

  const getAndroidLocationPermission = async () => {
    if (androidLocationServiceAvailable) return await androidLocationService.CheckLocationPermissionAsync();
    return null;
  };

  const requestLocationPermission = async () => {
    if (androidLocationServiceAvailable) {
      return await androidLocationService.RequestLocationPermissionAsync();
    }

    return null;
  };

  const getAndroidNotificationsPermission = async () => {
    if (androidNotificationsServiceAvailable) {
      return await androidNotificationsService.CheckNotificationPermissionAsync();
    }

    return null;
  };

  const requestNotificationsPermission = async () => {
    if (androidNotificationsServiceAvailable) {
      return await androidNotificationsService.RequestNotificationPermissionAsync();
    }

    return null;
  };

  return {
    getAndroidLocationPermission,
    requestLocationPermission,
    getAndroidNotificationsPermission,
    requestNotificationsPermission,
  };
};
