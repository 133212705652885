import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BlindCalibrateStatusChangePayload,
  BlindCalibrateStatusTypePayload,
  BlindPositionChangePayload,
  BlindStateDirectionInternal,
  DeviceConnectionState,
} from '../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../hooks';
import { useDevicesAndChannelsContext } from '../../../../hooks/devices-and-channels/provider';
import { ChannelCoverStateInterface } from '../../../../types';
import { toastError, toastSuccess } from '../../../toast';

export const useLavvaDeviceBlindUpdate = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation('channel-settings');
  const { t: tr } = useTranslation('backend-validation');
  const { channelList } = useDevicesAndChannelsContext();
  const { setChannelState } = useChannelsState();

  const clearAnimationIfExists = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const setCoverAnimation = (
    channelId: string,
    targetPosition: number,
    position: number,
    predictedTimeInMs: number,
  ) => {
    const range = Math.abs(targetPosition - position);

    intervalRef.current = setInterval(() => {
      setChannelState((oldState) => {
        const newState = { ...oldState };
        const prevVal = (newState[channelId] as ChannelCoverStateInterface).lavvaStatePosition || 0;
        const nextValue = targetPosition === prevVal ? prevVal : targetPosition < prevVal ? prevVal - 1 : prevVal + 1;

        if ((nextValue > 0 || nextValue < 100) && nextValue !== prevVal) {
          (newState[channelId] as ChannelCoverStateInterface).lavvaStatePosition = nextValue;
        }

        if (nextValue === targetPosition) {
          clearAnimationIfExists();
        }

        return newState;
      });
    }, predictedTimeInMs / range);
  };

  const updateLavvaBlindPosition = async ({
    channelId,
    position,
    direction,
    predictedTimeInMs,
    targetPosition,
    slatsPredictedTimeInMs,
  }: BlindPositionChangePayload) => {
    clearAnimationIfExists();

    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        lavvaStatePosition: position,
        lavvaStateDirection: direction,
        movingParams: {
          predictedTimeInMs: predictedTimeInMs != null ? predictedTimeInMs : null,
          targetPosition: targetPosition != null ? targetPosition : null,
          slatsPredictedTimeInMs: slatsPredictedTimeInMs != null ? slatsPredictedTimeInMs : null,
        },
        deviceConnectionState: DeviceConnectionState.Connected,
      } as ChannelCoverStateInterface;

      return newState;
    });

    if (predictedTimeInMs && targetPosition != null) {
      if (direction === BlindStateDirectionInternal.Opening && position === 100 && slatsPredictedTimeInMs) {
        timeoutRef.current = setTimeout(() => {
          setCoverAnimation(channelId, targetPosition, position, predictedTimeInMs);
        }, slatsPredictedTimeInMs);
      } else {
        setCoverAnimation(channelId, targetPosition, position, predictedTimeInMs);
      }
    } else {
      clearAnimationIfExists();
    }
  };

  const onBlindCalibrateStatusChange = ({
    channelId,
    progress,
    openingMilliseconds,
    closingMilliseconds,
    status,
    error,
  }: BlindCalibrateStatusChangePayload) => {
    if (error) {
      toastError({ content: tr(error) });

      setChannelState((oldState) => {
        const newState = { ...oldState };
        const calibrateState = (newState[channelId] as ChannelCoverStateInterface)?.calibrateState;
        newState[channelId] = {
          ...(newState[channelId] as ChannelCoverStateInterface),
          calibrateState: {
            openingMilliseconds: calibrateState?.openingMilliseconds || 0,
            closingMilliseconds: calibrateState?.closingMilliseconds || 0,
            error: null,
            progress: null,
            started: calibrateState?.started,
          },
        };

        return newState;
      });
    } else {
      setChannelState((oldState) => {
        const newState = { ...oldState };
        newState[channelId] = {
          ...(newState[channelId] as ChannelCoverStateInterface),
          calibrateState: {
            openingMilliseconds: openingMilliseconds || 0,
            closingMilliseconds: closingMilliseconds || 0,
            error: !error ? (newState[channelId] as ChannelCoverStateInterface)?.calibrateState?.error : error,
            progress,
            started: !!progress,
          },
        };

        return newState;
      });

      const channelAlias = channelList.find((channel) => channel.id === channelId)?.alias;

      if (status === BlindCalibrateStatusTypePayload.Data) {
        toastSuccess({
          content: `${t('toast.calibrationSuccess', { channel: channelAlias })}`,
        });
      }
    }
  };

  return {
    updateLavvaBlindPosition,
    onBlindCalibrateStatusChange,
    clearAnimationIfExists,
  };
};
