import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';

export interface BindingListItem {
  channelId: string;
  channelType: ChannelTypeInternal | 'Cloud';
  inputTrigger: InputBindingTrigger | null;
  actionType: ActionBindingType | null;
  slot: number | null;
  [ChannelActions.SetOnTimeValue]?: string;
  [ChannelActions.Position]?: number;
  [ChannelActions.Dir]?: number;
  [ChannelActions.Brightness]?: number;
  [ChannelActions.Temperature]?: number;
  [ChannelActions.R]?: number;
  [ChannelActions.G]?: number;
  [ChannelActions.B]?: number;
  [ChannelActions.OnSignalActionId]?: string;
  [ChannelActions.OffSignalActionId]?: string;
}

export interface BindingsItem {
  inputId: string;
  single: BindingListItem[];
  double: BindingListItem[];
}

export interface DeviceBindingsForm {
  bindings: BindingsItem[];
}

export enum InputBindingTrigger {
  SingleClick = 0,
  DoubleClick = 1,
}

export enum ActionBindingType {
  Default = 'default',
  Switch = 'Switch',
  Light = 'Light',
  Blind = 'Blind',
  BlindDefault1 = 'default-1',
  BlindDefault2 = 'default-2',
  Gate = 'Gate',
  GateDefault1 = 'default-1',
  GateDefault2 = 'default-2',
  GateDefault3 = 'default-3',
  GateDefault4 = 'default-4',
  Cloud = 'Cloud',
}

export const actionOptions = {
  [ChannelTypeInternal.Unknown]: [
    { label: 'bindings.purposeOptions.default', value: ActionBindingType.Default, weight: 1 },
  ],
  [ChannelTypeInternal.Switch]: [
    { label: 'bindings.purposeOptions.default', value: ActionBindingType.Default, weight: 1 },
    { label: 'bindings.purposeOptions.advanced', value: ActionBindingType.Switch, weight: 1000 },
  ],
  [ChannelTypeInternal.Light]: [
    { label: 'bindings.purposeOptions.default', value: ActionBindingType.Default, weight: 1 },
    { label: 'bindings.purposeOptions.advanced', value: ActionBindingType.Light, weight: 1000 },
  ],
  [ChannelTypeInternal.Blind]: [
    { label: 'bindings.purposeOptions.blindOpen', value: ActionBindingType.BlindDefault1, weight: 1 },
    { label: 'bindings.purposeOptions.blindClose', value: ActionBindingType.BlindDefault2, weight: 2 },
    { label: 'bindings.purposeOptions.advanced', value: ActionBindingType.Blind, weight: 1000 },
  ],
  [ChannelTypeInternal.Gate]: [
    { label: 'bindings.purposeOptions.limitSwitchOpen', value: ActionBindingType.GateDefault1, weight: 1 },
    { label: 'bindings.purposeOptions.limitSwitchClose', value: ActionBindingType.GateDefault2, weight: 2 },
    { label: 'bindings.purposeOptions.opticalBarrier', value: ActionBindingType.GateDefault3, weight: 3 },
    { label: 'bindings.purposeOptions.button', value: ActionBindingType.GateDefault4, weight: 4 },
    { label: 'bindings.purposeOptions.advanced', value: ActionBindingType.Gate, weight: 1000 },
  ],
};

export enum ChannelActions {
  SetOnTimeValue = 'setOnTimeValue',
  Position = 'position',
  Dir = 'dir',
  Brightness = 'brightness',
  Temperature = 'temperature',
  R = 'r',
  G = 'g',
  B = 'b',
  OffSignalActionId = 'offSignalActionId',
  OnSignalActionId = 'onSignalActionId',
}

export enum LightActionTypeEnum {
  SetOnTimeValue = 'setOnTimeValue',
  RGB = 'rgb',
  Brightness = 'brightness',
  Temperature = 'temperature',
}

export enum GateActionTypeEnum {
  Position = 'position',
  Direction = 'direction',
}

export enum GateDirection {
  Unknown = 0,
  Stop = 1,
  Open = 2,
  Close = 3,
}
