import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { cloneDeep, uniqBy } from 'lodash';
import { useSubscription } from '@apollo/client';
import {
  useConnectToNetwork,
  useConnectToUnknownNetwork,
  useGetWiFiNetworks,
} from '../../../../../api/modules/device/device.hooks';
import { Header, NavHeader, Page, SelectOptionInterface, SubmitButton } from '../../../../../components';
import { ONLY_LABEL_OPTION_VALUE } from '../../../../../const';
import {
  ChannelTypeInternal,
  DeviceConnectedToSsidUpdatedPayload,
  DeviceWifiNetworkPayload,
  DeviceWifiNetworksReceivedPayload,
} from '../../../../../data-access/gql-types/graphql';
import {
  ON_DEVICE_CONNECTED_TO_SSID_UPDATED,
  ON_DEVICE_WIFI_NETWORKS_RECEIVED,
} from '../../../../../data-access/subscriptions/lavva-devices';
import { useBackdropContext, useInstallation } from '../../../../../hooks';
import { useDevicesAndChannelsContext } from '../../../../../hooks/devices-and-channels/provider';
import { useErrors } from '../../../../../hooks/use-errors';
import { PublishStatus } from '../../../../../types';
import { getSignalStrength } from '../../../../../utils/helpers/network';
import { toastError, toastSuccess } from '../../../../../utils/toast';
import ConfigurationInput from '../../../../device-add/bluetooth/components/input-controller';
import { NetworkState } from '../../../../device-add/bluetooth/components/network-state';
import { NetworkFormValues } from '../../../../device-add/bluetooth/types';

interface ComponentProps {
  setUpdateNetwork: Dispatch<SetStateAction<boolean>>;
}

const UpdateNetwork: React.FC<ComponentProps> = ({ setUpdateNetwork }) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation('device-add');
  const { t: td } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const { skipLavvaFetch } = useInstallation();
  const [wifiList, setWifiList] = useState<DeviceWifiNetworkPayload[]>([]);
  const [wifiOptions, setWifiOptions] = useState<SelectOptionInterface<string>[]>([]);
  const [knowsWifiOptions, setKnowsWifiOptions] = useState<SelectOptionInterface<string>[]>([]);
  const [wifiLoading, setWifiLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { deviceId } = useParams<{ channelType: ChannelTypeInternal; deviceId: string }>();
  const { device, setDeviceList, updateDeviceDetails } = useDevicesAndChannelsContext();
  const { mutate: getWifi } = useGetWiFiNetworks();
  const { mutate: connectToNetwork } = useConnectToNetwork();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { mutate: connectToUnknownNetwork } = useConnectToUnknownNetwork();
  const { handleLavvaResolve } = useErrors();
  const form = useForm<NetworkFormValues>({
    mode: 'all',
    defaultValues: {
      ssid: device?.payload.connectedToSsid || '',
      password: '',
    },
  });
  const { data } = useSubscription(ON_DEVICE_WIFI_NETWORKS_RECEIVED, {
    variables: {
      deviceId,
    },
    skip: skipLavvaFetch,
  });

  const { data: connectedData } = useSubscription(ON_DEVICE_CONNECTED_TO_SSID_UPDATED, {
    variables: {
      deviceId,
    },
    skip: skipLavvaFetch,
  });

  const { ssid } = form.watch();

  useEffect(() => {
    setWifiLoading(true);
    getWifi(deviceId);
  }, [deviceId]);

  useEffect(() => {
    if (data) {
      const list = uniqBy(
        (data.onDeviceWifiNetworksReceived as DeviceWifiNetworksReceivedPayload).wifiNetworks,
        'ssid',
      );
      setWifiList(list);

      setWifiOptions(
        list
          .filter((x) => !x.remembered)
          .map((x) => ({
            label: x.ssid,
            value: x.ssid,
            id: x.ssid,
            icon: <NetworkState signal={x.rssi ? getSignalStrength(x.rssi) : 0} />,
          })),
      );

      setKnowsWifiOptions(
        list
          .filter((x) => x.remembered)
          .map((x) => ({
            label: x.ssid,
            value: x.ssid,
            id: x.ssid,
            icon: <NetworkState signal={x.rssi ? getSignalStrength(x.rssi) : 0} remembered />,
          })),
      );

      setWifiLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (connectedData) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      toastSuccess({
        content: td('network_connected.success', {
          ssid: (connectedData.onDeviceConnectedToSsidUpdated as DeviceConnectedToSsidUpdatedPayload).connectedToSsid,
        }),
      });
      setUpdateNetwork(false);

      setDeviceList((prev) => {
        const tempList = cloneDeep(prev);
        const index = tempList.findIndex((x) => x.id === deviceId);
        if (index !== -1) {
          tempList[index].payload.connectedToSsid = (
            connectedData.onDeviceConnectedToSsidUpdated as DeviceConnectedToSsidUpdatedPayload
          ).connectedToSsid;
        }

        return prev;
      });

      updateDeviceDetails((prev) => {
        const temp = cloneDeep(prev);
        if (temp) {
          temp.payload.connectedToSsid = (
            connectedData.onDeviceConnectedToSsidUpdated as DeviceConnectedToSsidUpdatedPayload
          ).connectedToSsid;
        }
        return temp;
      });

      turnOffBackdrop();
    }
  }, [connectedData]);

  useEffect(() => {
    const isRemembered = wifiList.find((x) => x.ssid === ssid)?.remembered;
    if ((!isRemembered || !wifiList.length) && ssid !== device?.payload.connectedToSsid) setShowPassword(true);
    else setShowPassword(false);
  }, [ssid, wifiList]);

  const handleChangeSuccess = (publishStatus: PublishStatus) => {
    handleLavvaResolve({
      status: publishStatus,
      deviceId,
      onSuccess: () => {
        turnOnBackdrop();

        timeoutRef.current = setTimeout(() => {
          toastError({ content: td('network_connected.failed') });
          setUpdateNetwork(false);
          turnOffBackdrop();
        }, 1000 * 20);
      },
    });
  };

  const onSubmit = (formData: NetworkFormValues) => {
    const isRemembered = wifiList.find((x) => x.ssid === ssid)?.remembered;

    if (isRemembered) {
      connectToNetwork(
        { deviceId, ssid: formData.ssid },
        {
          onSuccess: ({ data }) => {
            handleChangeSuccess(data.publishStatus);
          },
        },
      );
    } else {
      connectToUnknownNetwork(
        { deviceId, ssid: formData.ssid, password: formData.password },
        {
          onSuccess: ({ data }) => {
            handleChangeSuccess(data.publishStatus);
          },
        },
      );
    }
  };

  return (
    <Page
      kind="above"
      header={
        <>
          <NavHeader onClick={() => setUpdateNetwork(false)} />
          <Header title={t('bluetooth.networkConfiguration')} isUnderline />
        </>
      }
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ConfigurationInput
            name="ssid"
            label="SSID"
            wifiList={[
              ...(knowsWifiOptions.length
                ? [{ label: t('bluetooth.myNetworks'), value: ONLY_LABEL_OPTION_VALUE, disabled: true }]
                : []),
              ...knowsWifiOptions,
              ...(wifiOptions.length
                ? [{ label: t('bluetooth.otherNetworks'), value: ONLY_LABEL_OPTION_VALUE, disabled: true }]
                : []),
              ...wifiOptions,
            ]}
            wifiLoading={wifiLoading}
            readOnly
          />
          {showPassword && <ConfigurationInput name="password" label={t('bluetooth.password')} inputType="password" />}
          <SubmitButton disabled={ssid === device?.payload.connectedToSsid}>{tc('buttons.save')}</SubmitButton>
        </form>
      </FormProvider>
    </Page>
  );
};

export default UpdateNetwork;
