import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import omitDeep from 'omit-deep';
import { useMutation } from '@apollo/client';
import { useGetTariffs } from '../../../../api/modules/optimizer/optimizer.hooks';
import {
  ChannelTypeInternal,
  TariffConfiguration,
  TariffKindInternal,
  UpdateTariffConfigurationsMutation,
  UpdateTariffConfigurationsMutationVariables,
} from '../../../../data-access/gql-types/graphql';
import { UPDATE_TARIFF_CONFIGURATIONS } from '../../../../data-access/mutations/channels';
import { useBackdropContext, useInstallation } from '../../../../hooks';
import { useChannelErrors } from '../../../../hooks/backend-errors/use-channel-errors';
import { useDevicesAndChannelsContext } from '../../../../hooks/devices-and-channels/provider';
import { ChannelMeterInterface } from '../../../../types';
import { toastSuccess } from '../../../../utils/toast';
import { tariffKind } from '../../../channel-details/optimizer/configuration/utils/tariff-kind';
import { TariffPricesForm } from '../types';

export const useMeterTariffPricesConfiguration = (form: UseFormReturn<TariffPricesForm>) => {
  const { t } = useTranslation('channel-settings');
  const { channelId } = useParams<{ channelId: string }>();
  const { selectedInstallationId } = useInstallation();
  const { channel, updateChannelDetails } = useDevicesAndChannelsContext();
  const { data: allTariffs } = useGetTariffs(ChannelTypeInternal.Meter);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleErrors } = useChannelErrors();
  const [updateTariffConfigurations] = useMutation<
    UpdateTariffConfigurationsMutation,
    UpdateTariffConfigurationsMutationVariables
  >(UPDATE_TARIFF_CONFIGURATIONS);

  const setDefaultForm = () => {
    const tariffData = (channel?.data as ChannelMeterInterface).tariff;

    if (tariffData) {
      const temp = tariffKind[tariffData.kind];
      const tariffsOperator = allTariffs?.find((x) => x.operator.toLowerCase() === temp.operator);

      if (tariffsOperator) {
        const tariff = tariffsOperator.tariffs.find((x) => x.name.toLowerCase() === temp.tariff);

        if (tariff) {
          form.setValue('tariff', tariffData.kind);

          if (!tariff.hoursRanges.length) {
            form.setValue('prices', [{ price: 0, pricingTier: 2 }]);
          } else {
            if (tariff.hoursRanges.find((x) => x.pricingTier === 3)) {
              form.setValue('prices', [
                { price: 0, pricingTier: 1 },
                { price: 0, pricingTier: 2 },
                { price: 0, pricingTier: 3 },
              ]);
            } else {
              form.setValue('prices', [
                { price: 0, pricingTier: 1 },
                { price: 0, pricingTier: 2 },
              ]);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    const tariffConfigurations = (channel?.data as ChannelMeterInterface)?.sharedChannelSettings?.tariffConfigurations;

    if (tariffConfigurations?.length) {
      const savedData = tariffConfigurations.find(
        (x) => x.tariff === (channel?.data as ChannelMeterInterface).tariff?.kind,
      );

      if (savedData) {
        form.setValue('tariff', savedData.tariff as TariffKindInternal);
        form.setValue(
          'prices',
          savedData.prices.map((x) => ({ price: x.price, pricingTier: x.pricingTier })),
        );
      } else setDefaultForm();
    } else setDefaultForm();
  }, [allTariffs, channel?.data as ChannelMeterInterface]);

  const handleSubmitPrices = (values: TariffPricesForm) => {
    const { tariff, prices } = values;
    turnOnBackdrop();

    const tariffConfigurations = omitDeep(
      cloneDeep((channel?.data as ChannelMeterInterface)?.sharedChannelSettings?.tariffConfigurations || []),
      ['__typename'],
    ) as TariffConfiguration[];

    const index = tariffConfigurations.findIndex((x) => x.tariff === tariff);
    const formData = {
      tariff,
      prices: prices.map((x) => ({ ...x, price: parseFloat(x.price) })),
    };

    if (index !== -1) {
      tariffConfigurations[index] = formData;
    } else {
      tariffConfigurations.push(formData);
    }

    updateTariffConfigurations({
      variables: {
        input: {
          channelId,
          installationId: selectedInstallationId,
          tariffConfigurations,
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();
        const responseData = data.updateTariffConfigurations.meterSharedChannelSettings?.tariffConfigurations as
          | TariffConfiguration[]
          | undefined;

        if (responseData) {
          updateChannelDetails((prev) => {
            const temp = cloneDeep(prev);

            if (temp) {
              (temp.data as ChannelMeterInterface).sharedChannelSettings = {
                ...(temp.data as ChannelMeterInterface).sharedChannelSettings,
                tariffConfigurations: responseData,
              };
            }

            return temp;
          });

          toastSuccess({ content: t('toast.editConfigurationSuccess') });
        } else {
          turnOffBackdrop();
          handleErrors(data.updateTariffConfigurations.errors || []);
        }
      },
    });
  };

  return {
    handleSubmitPrices,
  };
};
