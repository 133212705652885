import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { useSubscription } from '@apollo/client';
import { useLightSupplyReturnBehaviour } from '../../../../../api/modules/light/light.hooks';
import { useSwitchSupplyReturnBehaviour } from '../../../../../api/modules/switch/switch.hooks';
import { InputSelect } from '../../../../../components';
import {
  ChannelTypeInternal,
  OnSupplyReturnBehaviorChangeSubscription,
  SupplyReturnBehaviorInternal,
  OnSupplyReturnBehaviorChangeSubscriptionVariables,
} from '../../../../../data-access/gql-types/graphql';
import { ON_SUPPLY_RETURN_BEHAVIOR_CHANGE } from '../../../../../data-access/subscriptions/lavva-devices';
import { useBackdropContext } from '../../../../../hooks';
import { useDevicesAndChannelsContext } from '../../../../../hooks/devices-and-channels/provider';
import { useTimeout } from '../../../../../hooks/timeout/use-timeout';
import {
  ChannelInterface,
  ChannelLightInterface,
  ChannelSwitchInterface,
  convertedReturnBehavior,
} from '../../../../../types';
import { toastSuccess } from '../../../../../utils/toast';
import Info from '../info';

interface ComponentProps {
  channel: ChannelInterface;
}

const ReturnBehaviorSelect: React.FC<ComponentProps> = <T extends ChannelSwitchInterface | ChannelLightInterface>({
  channel,
}) => {
  const { t } = useTranslation('configuration');
  const { mutate: switchMutate } = useSwitchSupplyReturnBehaviour();
  const { mutate: lightMutate } = useLightSupplyReturnBehaviour();
  const { updateChannelDetails } = useDevicesAndChannelsContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { setTimeoutError, clearTimeoutError } = useTimeout();
  const { data } = useSubscription<
    OnSupplyReturnBehaviorChangeSubscription,
    OnSupplyReturnBehaviorChangeSubscriptionVariables
  >(ON_SUPPLY_RETURN_BEHAVIOR_CHANGE, {
    variables: { channelId: (channel as ChannelInterface)?.id },
    skip: !channel,
  });

  useEffect(() => {
    if (data?.onSupplyReturnBehaviorChange) {
      updateChannelDetails((prev) => {
        const temp = cloneDeep(prev);

        if (temp) {
          (temp.data as T).supplyReturnBehavior = data.onSupplyReturnBehaviorChange.supplyReturnBehavior;
        }

        return temp;
      });

      turnOffBackdrop();
      clearTimeoutError();
      toastSuccess({ content: t('returnBehavior.behaviorSaved') });
    }
  }, [data]);

  const onChangeReturnBehavior = (value: SupplyReturnBehaviorInternal) => {
    if (channel.data.type === ChannelTypeInternal.Switch) {
      turnOnBackdrop();

      switchMutate(
        { channelId: channel.id, deviceId: channel.deviceId, value: convertedReturnBehavior[value] },
        {
          onSuccess: () => {
            setTimeoutError();
          },
        },
      );
    } else if (channel.data.type === ChannelTypeInternal.Light) {
      turnOnBackdrop();

      lightMutate(
        { channelId: channel.id, deviceId: channel.deviceId, value: convertedReturnBehavior[value] },
        {
          onSuccess: () => {
            setTimeoutError();
          },
        },
      );
    }
  };

  const value = useMemo(() => {
    return (channel.data as T)?.supplyReturnBehavior;
  }, [(channel.data as T)?.supplyReturnBehavior]);

  return (
    <>
      <Info title={t('returnBehavior.label')} />
      <InputSelect
        value={value}
        onChange={onChangeReturnBehavior}
        options={[
          { label: t('returnBehavior.options.AlwaysOff'), value: SupplyReturnBehaviorInternal.AlwaysOff },
          { label: t('returnBehavior.options.AlwaysOn'), value: SupplyReturnBehaviorInternal.AlwaysOn },
          { label: t('returnBehavior.options.KeepState'), value: SupplyReturnBehaviorInternal.KeepState },
        ]}
        label={''}
      />
    </>
  );
};

export default ReturnBehaviorSelect;
