import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation } from '../../../../../components';
import ActionCreateTasks from '../../../../action-create/components/task-list';
import TaskItem from '../components/task-item';
import { useExalusCreateActionContext } from '../context';
import { useActionTasks } from '../hooks/use-action-tasks';
import { CreateTaskValidateMethod, Task } from '../types';

const ExalusActionCreateTasks: React.FC = () => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { taskList } = useExalusCreateActionContext();
  const { addNewTask, handleSubmit, validateDialog, setValidateDialog, validateErrors } = useActionTasks();

  const tasks = useMemo(() => taskList.map((task: Task) => <TaskItem key={task.id} task={task} />), [taskList]);

  return (
    <>
      <ActionCreateTasks
        handleSubmit={handleSubmit}
        addNewTask={addNewTask}
        tasks={tasks}
        taskListLength={taskList.length}
      />
      <DialogConfirmation
        show={validateDialog}
        setShow={setValidateDialog}
        header={t('action.create.conditions.validate.validationHeader')}
        primaryBtnText={tc('buttons.yes')}
        secondaryBtnText={tc('buttons.ignoreAndSave')}
        primaryBtnAction={() => handleSubmit(CreateTaskValidateMethod.TryFix)}
        secondaryBtnAction={() => handleSubmit(CreateTaskValidateMethod.Ignore)}
      >
        <ul>
          {validateErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
          <p>{t('action.create.conditions.validate.autoFixQuestion')}</p>
        </ul>
      </DialogConfirmation>
    </>
  );
};

export default ExalusActionCreateTasks;
