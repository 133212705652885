import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import {
  FeaturesByVersionMap,
  Query,
  VersionedFeatureType,
  VersionMapQueryVariables,
} from '../../data-access/gql-types/graphql';
import { VERSION_MAP } from '../../data-access/queries/devices';
import { isLocalApp } from '../../utils/helpers/local-app';
import { compareVersions } from '../../utils/helpers/versions';
import { toastError } from '../../utils/toast';
import { useInstallation } from '../installation';

interface SupportPayload {
  feature: VersionedFeatureType;
  supportKey: 'Core' | 'Gate' | 'Optimizer';
  currentVersion?: string;
}

interface HookInterface {
  versionMapData: FeaturesByVersionMap[];
  checkSupport: (payload: SupportPayload) => boolean;
}

export default function (): HookInterface {
  const { t: tc } = useTranslation('common');
  const { skipLavvaFetch } = useInstallation();
  const { data } = useQuery<Query, VersionMapQueryVariables>(VERSION_MAP, {
    fetchPolicy: 'no-cache',
    skip: skipLavvaFetch || isLocalApp,
    onError: () => toastError({ content: tc('errors.downloadData') }),
  });

  const checkSupport = ({ feature, supportKey, currentVersion }: SupportPayload) => {
    if (!currentVersion) return false;

    const core = data?.versionMap.find((x) => x.key === supportKey);
    if (core) {
      const foundFeature = core.versionedFeatures.find((x) => x.feature === feature);

      if (foundFeature) {
        const { major, minor, build } = foundFeature.minVersion;

        return compareVersions(currentVersion, `${major}.${minor}.${build}`) >= 0;
      }
    }

    return false;
  };

  return {
    versionMapData: data?.versionMap || [],
    checkSupport,
  };
}
