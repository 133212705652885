import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { aggregatedParameters } from '../../../modules/channel-details/measurement/utils';
import { ChartDataItem } from '../../../modules/channel-details/meter/analysis/types';
import { lineChartParameters } from '../../../modules/channel-details/meter/analysis/utils/helpers';
import { MeasurementRange } from '../../../types';
import { IconSchedule } from '../../icons';
import './index.scss';

interface PropsInterface {
  summaryData: ChartDataItem[];
  activeMeasurementRange: MeasurementRange;
}

export const Summary: React.FC<PropsInterface> = ({ summaryData, activeMeasurementRange }) => {
  const { t } = useTranslation('channel-details');
  const [energyStats, setEnergyStats] = useState({});

  useEffect(() => {
    const stats = {};

    summaryData.forEach((entry) => {
      entry.values.forEach((val) => {
        const type = val.type;
        if (lineChartParameters.includes(type)) return;

        if (!stats[val.index]) {
          stats[val.index] = {};

          if (!stats[val.index][type]) {
            stats[val.index][type] = { sum: 0, max: null, count: 0, color: val.color };
          }

          if (val.empty) return;

          stats[val.index][type].sum += val.value;
          stats[val.index][type].max = Math.max(stats[val.index][type].max, val.value);
          stats[val.index][type].count++;
        } else {
          if (!stats[val.index][type]) {
            stats[val.index][type] = { sum: 0, max: null, count: 0, color: val.color };
          }

          if (val.empty) return;

          stats[val.index][type].sum += val.value;
          stats[val.index][type].max = Math.max(stats[val.index][type].max, val.value);
          stats[val.index][type].count++;
        }
      });
    });

    Object.keys(stats).forEach((phase) => {
      Object.keys(stats[phase]).forEach((type) => {
        const average = stats[phase][type].count ? stats[phase][type].sum / stats[phase][type].count : 0;
        stats[phase][type].average = average;
      });
    });

    setEnergyStats(stats);
  }, [summaryData]);

  const summarySectionName = useMemo(() => {
    switch (activeMeasurementRange) {
      case MeasurementRange.DayInHours:
        return t('summary.daily');
      case MeasurementRange.WeekInDays:
        return t('summary.weekly');
      case MeasurementRange.MonthInDays:
        return t('summary.monthly');
      case MeasurementRange.YearInMonths:
        return t('summary.yearly');
      case MeasurementRange.TenYearsInYears:
        return t('summary.tenYears');
      default:
        return '';
    }
  }, [activeMeasurementRange]);

  return (
    <div className="summary max-width-desktop">
      {Object.keys(energyStats).length ? (
        <div className="summary__caption">
          <IconSchedule />
          <span className="summary__caption-text">{summarySectionName}</span>
        </div>
      ) : null}
      {Object.keys(energyStats).map((phase) =>
        Object.keys(energyStats[phase]).map((type) => (
          <div key={type} className="summary__section summary__section--border">
            {(phase === '0' && Object.keys(energyStats[phase]).length !== 1) || ['1', '2', '3'].includes(phase) ? (
              <div className="summary__section-label">
                {phase === '0' ? t(`energyMeasurements.types.summary.${type}`) : `${t('phase')} ${phase}`}
                <div className="label-color" style={{ backgroundColor: energyStats[phase][type].color }} />
              </div>
            ) : null}
            <div className="summary__section-row">
              <span>{t('summary.sum')}</span>
              <span className="summary__section-value">
                {`${energyStats[phase][type].sum.toFixed(aggregatedParameters[type].fixed)} ${aggregatedParameters[type].unit}`}
              </span>
            </div>
            <div className="summary__section-row">
              <span>{t('summary.average')}</span>
              <span className="summary__section-value">
                {`${energyStats[phase][type].average.toFixed(aggregatedParameters[type].fixed)} ${aggregatedParameters[type].unit}`}
              </span>
            </div>
            <div className="summary__section-row">
              <span>{t('summary.max')}</span>
              <span className="summary__section-value">
                {`${energyStats[phase][type].max !== null ? energyStats[phase][type].max.toFixed(aggregatedParameters[type].fixed) : '-'} ${
                  aggregatedParameters[type].unit
                }`}
              </span>
            </div>
          </div>
        )),
      )}
    </div>
  );
};
