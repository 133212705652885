import { SceneTaskType } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import {
  IconBulb,
  IconDevices,
  IconFacadeBlind,
  IconNotification,
  IconPower,
  IconRGBCircles,
  IconTime,
  IconWindFan,
  IconWoodenBlind,
} from '../../../../../components/icons';
import '../../../../../components/icons';
import { IconLock } from '../../../../../components/icons/scenario';
import { isDevelopment } from '../../../../../utils/helpers/environment';
import { ChannelType } from '../../../enums';
import { ActionTaskOption, ActionTaskVariantOption, TaskVariant } from '../types';

export const actionTaskVariants: ActionTaskVariantOption[] = [
  {
    title: 'action.create.tasks.variants.onOff',
    icon: <IconPower colorAccent />,
    variant: TaskVariant.OnOff,
    types: [ChannelType.Switch, ChannelType.Light],
  },
  {
    title: 'action.create.tasks.variants.dim',
    icon: <IconBulb isOn />,
    variant: TaskVariant.Dim,
    types: [ChannelType.Light],
  },
  {
    title: 'action.create.tasks.variants.setMicrowave',
    icon: <IconWindFan isOn={false} />,
    variant: TaskVariant.SetMicrowave,
    types: [ChannelType.Blind, ChannelType.Facade],
  },
  {
    title: 'action.create.tasks.variants.setRGBW',
    icon: <IconRGBCircles isOn={true} />,
    variant: TaskVariant.SetRGBW,
    types: [ChannelType.Light],
  },
  {
    title: 'action.create.tasks.variants.setBlind',
    icon: <IconWoodenBlind position={33} />,
    variant: TaskVariant.SetBlind,
    types: [ChannelType.Blind],
  },
  {
    title: 'action.create.tasks.variants.setFacade',
    icon: <IconFacadeBlind position={100} tilt={0} fullRange />,
    variant: TaskVariant.SetFacade,
    types: [ChannelType.Facade],
  },
];

export const actionTasks: ActionTaskOption[] = [
  {
    title: 'action.create.tasks.list.stateChange',
    icon: <IconDevices />,
    type: SceneTaskType.DeviceTask,
  },
  {
    title: 'action.create.tasks.list.delay',
    icon: <IconTime />,
    type: SceneTaskType.DelayTask,
  },
  {
    title: 'action.create.tasks.list.lockExecution',
    icon: <IconLock />,
    type: SceneTaskType.LockExecution,
  },
  ...(isDevelopment
    ? [
        {
          title: 'action.create.tasks.list.notify',
          icon: <IconNotification />,
          type: SceneTaskType.NotifyTask,
        },
      ]
    : []),
];
