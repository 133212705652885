import React, { useEffect, useMemo, useState } from 'react';
import { ControlWrapper, Slider } from '../../../../../components';
import { useChannel } from '../../../../../components/channel/hooks/use-channel';
import { GateControls } from '../../../../../components/gate-controls';
import { ChannelTypeInternal, GateStateDirectionInternal } from '../../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../../hooks';
import { useDevicesAndChannelsContext } from '../../../../../hooks/devices-and-channels/provider';
import { useGate } from '../../../../../hooks/use-gate';
import { ChannelInterface } from '../../../../../types';
import { ChannelGateInterface, ChannelGateStateInterface } from '../../../../../types/channel/gate';
import CalibrationInfoDetails from '../../../controls/calibration';
import { useGateSupportedFeatures } from '../../hooks/use-gate-supported';

interface PropsInterface {
  channel: ChannelInterface;
  setPreventSwipeViews: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GateRoll: React.FC<PropsInterface> = ({ channel, setPreventSwipeViews }) => {
  const { channelState } = useChannelsState();
  const { handleAction, handleVentilation, gateActive } = useGate(channel);
  const { calibrationNeeded } = useChannel({ channel });
  const { channelList } = useDevicesAndChannelsContext();
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [targetValue, setTargetValue] = useState<number>(0);
  const { setDirectionSupported, setPositionSupported, ventilationSupported, calibrationSupported } =
    useGateSupportedFeatures(channel);
  const state = channelState[channel.id] as ChannelGateStateInterface | undefined;

  if (channel.data.type !== ChannelTypeInternal.Gate) return null;

  useEffect(() => {
    if (state?.movingParams?.targetPosition != null) {
      setTargetValue(state.movingParams.targetPosition);
    } else {
      if (
        state?.position != null &&
        (state.direction === GateStateDirectionInternal.Stopped ||
          state.direction === GateStateDirectionInternal.Open ||
          state.direction === GateStateDirectionInternal.Closed)
      ) {
        setTargetValue(state.position);
      }
    }
  }, [state?.movingParams.targetPosition, state?.direction]);

  useEffect(() => {
    if (channel.data.type !== ChannelTypeInternal.Gate) return;
    setSliderValue(state?.position || 0);
  }, [state?.position]);

  useEffect(() => {
    const timeout = setTimeout(() => setPreventSwipeViews(false), 500);
    return () => clearTimeout(timeout);
  }, [targetValue]);

  const handleSliderEvent = (event: React.PointerEvent<HTMLInputElement>) => {
    handleAction(Number((event.target as HTMLInputElement).value));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreventSwipeViews(true);
    setTargetValue(Number(event.target.value));
  };

  const dependantChannels = useMemo(() => {
    return (channel.data as ChannelGateInterface).dependantChannels.filter((x) =>
      channelList.find((y) => y.id === x.channelId && y.isVisible),
    );
  }, [(channel.data as ChannelGateInterface).dependantChannels, channelList]);

  const microventilationVisible = useMemo(() => {
    if (ventilationSupported && calibrationNeeded) return false;
    return ventilationSupported;
  }, [ventilationSupported, calibrationNeeded]);

  return (
    <>
      {calibrationSupported && calibrationNeeded && <CalibrationInfoDetails channel={channel} />}

      <ControlWrapper className="control-wrapper--full-space ">
        <div>
          {(setDirectionSupported || microventilationVisible || dependantChannels.length) && (
            <GateControls
              kind="channel"
              channel={channel}
              open={() => {
                handleAction('open');
                setTargetValue(0);
              }}
              close={() => {
                handleAction('close');
                setTargetValue(100);
              }}
              stop={() => handleAction('stop')}
              ventilation={handleVentilation}
              ventilationVisible={microventilationVisible}
              directionVisible={setDirectionSupported}
              dependantChannels={dependantChannels}
            />
          )}
          {setPositionSupported && !calibrationNeeded && (
            <Slider
              onPointerUp={handleSliderEvent}
              value={sliderValue}
              active={gateActive}
              targetValue={targetValue}
              onChange={onChange}
              position="horizontal"
              animationWhenActive
              noBgElement
            />
          )}
        </div>
      </ControlWrapper>
    </>
  );
};
