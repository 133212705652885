import { Dispatch, SetStateAction } from 'react';
import {
  ChannelTypeInternal,
  OptimizerConfigValidation,
  UserChannel,
  UserDevice,
} from '../data-access/gql-types/graphql';
import { ChannelInterface, ChannelStateType } from './channel';
import { DeviceInterface } from './devices';

export interface DevicesAndChannelsStateInterface {
  deviceList: DeviceInterface[];
  setDeviceList: Dispatch<SetStateAction<DeviceInterface[]>>;
  channelList: ChannelInterface[];
  setChannelList: Dispatch<SetStateAction<ChannelInterface[]>>;
  channel: ChannelInterface | undefined;
  setChannelDetails: (userChannel: UserChannel) => void;
  optimizerConfigValidation: OptimizerConfigValidation | undefined;
  setOptimizerConfigValidation: Dispatch<SetStateAction<OptimizerConfigValidation | undefined>>;
  updateChannelDetails: Dispatch<SetStateAction<ChannelInterface | undefined>>;
  clearChannelDetails: () => void;
  device: DeviceInterface | undefined;
  setDeviceDetails: (userDevice: UserDevice) => void;
  updateDeviceDetails: Dispatch<SetStateAction<DeviceInterface | undefined>>;
  clearDeviceDetails: () => void;
}

export interface ChannelsStateInterface {
  channelState: Record<string, ChannelStateType>;
  setChannelState: Dispatch<SetStateAction<Record<string, ChannelStateType>>>;
}

export enum Visibility {
  All = 'ALL',
  Visible = 'VISIBLE',
  InVisible = 'INVISIBLE',
}

export interface UpdateDataInterface {
  alias: string;
  isVisible: boolean;
  iconName: string;
}

export interface ChannelGroupItem {
  label: string;
  type: ChannelTypeInternal;
  channels: ChannelInterface[];
}

export interface UseDevicesAndChannelsInterface extends DevicesAndChannelsStateInterface {
  channel: ChannelInterface | undefined;
  device: DeviceInterface | undefined;
  deviceChannels: ChannelInterface[];
  deviceChannelsById: (id: string) => ChannelInterface[];
  channelGroups: ChannelGroupItem[];
  channelTypesList: string[];
  editDeviceName: (editDeviceId: string, editDeviceName: string) => void;
}

export enum RGBType {
  RGB = 'RGB',
  RGBW = 'RGBW',
  WHITE = 'White',
  WW = 'WW',
}
