import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, SelectOptionInterface } from '../../../../../../../../components';
import { ChannelNotifyAvailable } from '../../../../types';
import { messageByType } from '../../../../utils/notify-helpers';
import './index.scss';

interface ComponentProps {
  channelsAvailable: ChannelNotifyAvailable[];
  handleTileSelect: (val: string) => void;
}

export const Tiles: React.FC<ComponentProps> = ({ channelsAvailable, handleTileSelect }) => {
  const { t } = useTranslation('action');
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);

  const addChannelTiles = (c: ChannelNotifyAvailable) => {
    const more = messageByType[c.type].more;

    return c.measurementType && more
      ? [
          {
            label: t(`action.create.tasks.notifyLabels.energyLabels.${more[c.measurementType].key}`),
            value: `{${more[c.measurementType].key}}`,
          },
          {
            label: `<${t('action.create.tasks.value')} ${t(
              `action.create.tasks.notifyLabels.energyLabels.${more[c.measurementType].key}`,
            )}>${more[c.measurementType].unit}`,
            value: `{${c.channel.ChannelId}_${c.measurementType}:${c.type}}`,
          },
        ]
      : [
          {
            label: t(`action.create.tasks.notifyLabels.${messageByType[c.type].key}`),
            value: `{${messageByType[c.type].key}}`,
          },
          {
            label: `<${t('action.create.tasks.value')} ${t(
              `action.create.tasks.notifyLabels.${messageByType[c.type].key}`,
            )}>${messageByType[c.type].unit}`,
            value: `{${c.channel.ChannelId}:${c.type}}`,
          },
        ];
  };

  const tilesOptions = useMemo(() => {
    let tiles: Record<string, SelectOptionInterface<string>[]> = {
      [t('action.create.tasks.other')]: [
        { label: t('action.create.tasks.controllerTime'), value: `{controller_time}` },
      ],
    };

    channelsAvailable.map((c) => {
      if (!tiles[c.channel.Name]) {
        tiles = {
          ...tiles,
          [c.channel.Name]: [
            { label: t('action.create.tasks.channelName'), value: `{${c.channel.ChannelId}_name}` },
            ...addChannelTiles(c),
          ],
        };
      } else {
        tiles = {
          ...tiles,
          [c.channel.Name]: [...tiles[c.channel.Name], ...addChannelTiles(c)],
        };
      }
    });

    return tiles;
  }, [channelsAvailable, t]);

  return (
    <div className="notify-channel-tiles">
      {Object.entries(tilesOptions).map(([channelName, tiles], i) => (
        <Accordion
          kind="controlled"
          key={channelName}
          title={channelName}
          hideExpandedBorder
          handleExpand={setExpandedIndex}
          expanded={i === expandedIndex}
          position={i}
        >
          {tiles.map((x, i) => (
            <button key={i} onClick={() => handleTileSelect(x.value)}>
              {x.label}
            </button>
          ))}
        </Accordion>
      ))}
    </div>
  );
};
