import { useRef } from 'react';

interface HookParamsInterface {
  holdMillisecond: number;
  onHold?: () => void;
  onClick?: () => void;
}

interface UseHoldInterface {
  onPointerDown: () => void;
  onPointerUp: () => void;
  onPointerLeave: () => void;
}

export const useHold = ({ holdMillisecond, onHold, onClick }: HookParamsInterface): UseHoldInterface => {
  const timeout = useRef<NodeJS.Timeout>();

  const removeTimeout = () => {
    clearTimeout(timeout.current as number | undefined);
    timeout.current = undefined;
  };

  const onPointerDown = () => {
    removeTimeout();

    timeout.current = setTimeout(() => {
      removeTimeout();
      onHold?.();
    }, holdMillisecond);
  };

  const onPointerUp = () => {
    if (timeout.current) {
      removeTimeout();
      onClick?.();
    }
  };

  const onPointerLeave = () => {
    if (timeout.current) {
      removeTimeout();
    }
  };

  return { onPointerDown, onPointerUp, onPointerLeave };
};
