import { useTranslation } from 'react-i18next';
import { BlindConfigurationErrorCode } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigService';
import { SwitchConfigurationErrorCode } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchService';
import { DiagnosticErrorCode } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/DiagnosticService/DiagnosticParams';
import { FacadeConfigurationErrorCode } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigService';
import { GetParamsErrorCode } from 'lavva.exalushome.extalife/build/js/ExtaLife';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { useBackdropContext } from '../../../../../hooks';
import { toastError, toastInfo, toastSuccess } from '../../../../../utils/toast';
import { useHandleDataFrameErrors } from '../../../hooks';

export const useHandleConfigurationErrors = () => {
  const { t } = useTranslation('device-info');
  const { handleError } = useHandleDataFrameErrors();
  const { turnOffBackdrop, turnOffBackdropPopup } = useBackdropContext();

  const showGetParamsError = (data: ResponseResult<GetParamsErrorCode>) => {
    turnOffBackdrop();
    turnOffBackdropPopup();

    switch (data.Type) {
      case GetParamsErrorCode.ResourceIsNotAvailable: {
        toastError({ content: t('exalus.getParams.resourceIsNotAvailable') });
        break;
      }
      case GetParamsErrorCode.UnknownError: {
        toastError({ content: t('exalus.getParams.unknownError') });
        break;
      }
      case GetParamsErrorCode.OtherError: {
        toastError({ content: t('exalus.getParams.otherError') });
        break;
      }
      case GetParamsErrorCode.InvalidChannelNumber: {
        toastError({ content: t('exalus.getParams.InvalidChannelNumber') });
        break;
      }
      case GetParamsErrorCode.DeviceNotResponding: {
        toastError({ content: t('exalus.getParams.deviceNotResponding') });
        break;
      }
      default:
        break;
    }
  };

  const showGetFacadeParamsError = (data: ResponseResult<FacadeConfigurationErrorCode>) => {
    turnOffBackdrop();

    switch (data.Type) {
      case FacadeConfigurationErrorCode.InvalidChannelNumber: {
        toastError({ content: t('exalus.getParams.invalidChannelNumber') });
        break;
      }
      case FacadeConfigurationErrorCode.OtherError: {
        toastError({ content: t('exalus.getParams.otherError') });
        break;
      }
      case FacadeConfigurationErrorCode.CalibrationsIsCurrentlyRunning: {
        toastInfo({ content: t('exalus.getParams.calibrationsIsCurrentlyRunning') });
        break;
      }
      case FacadeConfigurationErrorCode.DeviceNotResponding: {
        toastError({ content: t('exalus.getParams.deviceNotResponding') });
        break;
      }
      case FacadeConfigurationErrorCode.WindSensorNotPaired: {
        toastError({ content: t('exalus.getParams.windSensorNotPaired') });
        break;
      }
      case FacadeConfigurationErrorCode.NoData: {
        toastError({ content: t('exalus.getParams.noData') });
        break;
      }
      case FacadeConfigurationErrorCode.TravelTimeCalibrationNotValid: {
        toastError({ content: t('exalus.getParams.travelTimeCalibrationNotValid') });
        break;
      }
      default:
        break;
    }
  };

  const showGetBlindParamsError = (data: ResponseResult<BlindConfigurationErrorCode>) => {
    turnOffBackdrop();

    switch (data.Type) {
      case BlindConfigurationErrorCode.InvalidChannelNumber: {
        toastError({ content: t('exalus.getParams.invalidChannelNumber') });
        break;
      }
      case BlindConfigurationErrorCode.OtherError: {
        toastError({ content: t('exalus.getParams.otherError') });
        break;
      }
      case BlindConfigurationErrorCode.CalibrationsIsCurrentlyRunning: {
        toastError({ content: t('exalus.getParams.calibrationsIsCurrentlyRunning') });
        break;
      }
      case BlindConfigurationErrorCode.CalibrationCancelled: {
        toastError({ content: t('exalus.getParams.calibrationCancelled') });
        break;
      }
      case BlindConfigurationErrorCode.DeviceNotResponding: {
        toastError({ content: t('exalus.getParams.deviceNotResponding') });
        break;
      }
      case BlindConfigurationErrorCode.Timeout: {
        toastError({ content: t('exalus.getParams.timeout') });
        break;
      }
      case BlindConfigurationErrorCode.NoData: {
        toastError({ content: t('exalus.getParams.noData') });
        break;
      }
      case BlindConfigurationErrorCode.IncorrectResponse: {
        toastError({ content: t('exalus.getParams.incorrectResponse') });
        break;
      }
      case BlindConfigurationErrorCode.CannotCheckIfCalibrationsIsRunning: {
        toastError({ content: t('exalus.getParams.cannotCheckIfCalibrationsIsRunning') });
        break;
      }
      case BlindConfigurationErrorCode.InputOutputNotFound: {
        toastError({ content: t('exalus.getParams.inputOutputNotFound') });
        break;
      }
      case BlindConfigurationErrorCode.WrongStepOfOperation: {
        toastError({ content: t('exalus.getParams.wrongStepOfOperation') });
        break;
      }
      default:
        break;
    }
  };

  const showGetSwitchParamsError = (data: ResponseResult<SwitchConfigurationErrorCode>) => {
    turnOffBackdrop();

    switch (data.Type) {
      case SwitchConfigurationErrorCode.InvalidChannelNumber: {
        toastError({ content: t('exalus.getParams.invalidChannelNumber') });
        break;
      }
      case SwitchConfigurationErrorCode.OtherError: {
        toastError({ content: t('exalus.getParams.otherError') });
        break;
      }
      case SwitchConfigurationErrorCode.NoData: {
        toastError({ content: t('exalus.getParams.noData') });
        break;
      }
      default:
        break;
    }
  };
  const showDiagnosticError = (data: ResponseResult<DiagnosticErrorCode>) => {
    turnOffBackdrop();

    switch (data.Type) {
      case DiagnosticErrorCode.CannotFindDevice: {
        toastError({ content: t('exalus.diagnosticResult.cannotFindDevice') });
        break;
      }
      case DiagnosticErrorCode.OtherError: {
        toastError({ content: t('exalus.diagnosticResult.otherError') });
        break;
      }
      case DiagnosticErrorCode.FatalError: {
        toastError({ content: t('exalus.diagnosticResult.fatalError') });
        break;
      }
      case DiagnosticErrorCode.DeviceRepairFailed: {
        toastError({ content: t('exalus.diagnosticResult.deviceRepairFailed') });
        break;
      }
      case DiagnosticErrorCode.RepairingIsUnsupported: {
        toastError({ content: t('exalus.diagnosticResult.repairingIsUnsupported') });
        break;
      }
      default:
        break;
    }
  };

  const handleGetParamsResponse = <T,>(data: T | ResponseResult<GetParamsErrorCode>, onSuccess: () => void) => {
    if ((data as ResponseResult<GetParamsErrorCode>).Type) {
      showGetParamsError(data as ResponseResult<GetParamsErrorCode>);
    } else {
      onSuccess();
    }
  };

  const handleGetSwitchParamsResponse = <T,>(
    data: T | ResponseResult<SwitchConfigurationErrorCode>,
    onSuccess: () => void,
  ) => {
    if ((data as ResponseResult<SwitchConfigurationErrorCode>).Type) {
      showGetSwitchParamsError(data as ResponseResult<SwitchConfigurationErrorCode>);
    } else {
      onSuccess();
    }
  };

  const handleGetBlindParamsResponse = <T,>(
    data: T | ResponseResult<BlindConfigurationErrorCode>,
    onSuccess: () => void,
  ) => {
    if ((data as ResponseResult<BlindConfigurationErrorCode>).Type) {
      showGetBlindParamsError(data as ResponseResult<BlindConfigurationErrorCode>);
    } else {
      onSuccess();
    }
  };

  const handleDiagnosticResponse = <T,>(data: T | ResponseResult<DiagnosticErrorCode>, onSuccess: () => void) => {
    if ((data as ResponseResult<BlindConfigurationErrorCode>).Type) {
      showDiagnosticError(data as ResponseResult<DiagnosticErrorCode>);
    } else {
      onSuccess();
    }
  };

  const handleGetFacadeParamsResponse = <T,>(
    data: T | ResponseResult<FacadeConfigurationErrorCode>,
    onSuccess: () => void,
    onError?: () => void,
  ) => {
    if ((data as ResponseResult<FacadeConfigurationErrorCode>).Type) {
      showGetFacadeParamsError(data as ResponseResult<FacadeConfigurationErrorCode>);
      onError?.();
    } else {
      onSuccess();
    }
  };

  const handleSubmitResponse = (result: Status, message?: string) => {
    if (result === Status.OK) {
      toastSuccess({ content: t(`exalus.${message || 'configurationSavedSuccessfully'}`) });
    } else {
      handleError(result);
    }
  };

  return {
    handleSubmitResponse,
    handleGetFacadeParamsResponse,
    handleDiagnosticResponse,
    handleGetParamsResponse,
    handleGetBlindParamsResponse,
    handleGetSwitchParamsResponse,
    showGetFacadeParamsError,
  };
};
