import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { MeasuredEnergyState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ArgumentType } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { useChannels } from '../../channel-list/hooks/use-channels';
import { useExalusCreateActionContext } from '../context';
import { ChannelNotifyAvailable } from '../types';
import { messageByType, supportedResponseType } from '../utils/notify-helpers';

export const useNotifyTask = () => {
  const { t } = useTranslation('action');
  const { channels } = useChannels();
  const { conditionList } = useExalusCreateActionContext();

  const channelsAvailable = useMemo(() => {
    const list: ChannelNotifyAvailable[] = [];

    conditionList
      .filter((x) => x.LeftArgumentType === ArgumentType.ArgumentAsDeviceState)
      .filter(
        (x) =>
          x.LeftArgument.Argument && supportedResponseType.includes(x.LeftArgument.Argument.ArgumentAsDeviceState.Type),
      )
      .map((c) => {
        const arg = c.LeftArgument.Argument?.ArgumentAsDeviceState;
        const st = arg?.GetCheckDeviceState();
        if (arg && st) {
          const ch = (channels || []).find((x) => x.ChannelId === `${arg.DeviceGuid}_${st.Channel}`);

          if (ch) {
            list.push({
              channel: ch,
              type: arg.Type,
              ...(arg.Type === DeviceResponseType.MeasuredEnergy
                ? {
                    measurementType: (st as MeasuredEnergyState).MeasurementParameters.keys().next().value,
                  }
                : {}),
            });
          }
        }
      });

    return list;
  }, [conditionList, channels]);

  const getMessageParsed = (message: string) => {
    const messageParam = message;
    let finalMessage = messageParam.replaceAll('{controller_time}', '12:00:00');

    const messageNamesTemp = messageParam.match(/\{[^{}]+?_name\}/g) || [];
    const replaceNames = messageNamesTemp.map((x) => {
      const parts = x.replace('{', '').replace('}', '').split('_');
      const [deviceId, channelNumber] = parts;

      return {
        original: x,
        replace:
          channelsAvailable.find((x) => x.channel.ChannelId === `${deviceId}_${channelNumber}`)?.channel.Name || '',
      };
    });

    replaceNames.forEach((x) => {
      finalMessage = finalMessage.replace(x.original, x.replace);
    });

    const messageStatesTemp = messageParam.match(/\{[^{}]+?_\d+(?:_[^{}]+)?:[^{}]+?\}/g) || [];
    const replaceStates = messageStatesTemp.map((x) => {
      const parts = x.replace('{', '').replace('}', '').split(':');
      const leftParts = parts[0].split('_');

      const more = messageByType[parts[1]].more;

      if (!isNaN(leftParts[1]) && more) {
        return {
          original: x,
          replace: messageByType[parts[1]]
            ? `<${t('action.create.tasks.value')} ${t(
                `action.create.tasks.notifyLabels.energyLabels.${more[leftParts[2]].key}`,
              )}>${more[leftParts[2]].unit}`
            : '',
        };
      } else {
        return {
          original: x,
          replace: messageByType[parts[1]]
            ? `<${t('action.create.tasks.value')} ${t(`action.create.tasks.notifyLabels.${messageByType[parts[1]].key}`)}>${
                messageByType[parts[1]].unit
              }`
            : '',
        };
      }
    });

    replaceStates.forEach((x) => {
      finalMessage = finalMessage.replace(x.original, x.replace);
    });

    Object.values(messageByType).map((x) => {
      if (x.more) {
        Object.values(x.more).map((m) => {
          finalMessage = finalMessage.replaceAll(
            `{${m.key}}`,
            t(`action.create.tasks.notifyLabels.energyLabels.${m.key}`),
          );
        });
      } else {
        finalMessage = finalMessage.replaceAll(`{${x.key}}`, t(`action.create.tasks.notifyLabels.${x.key}`));
      }
    });

    return finalMessage;
  };

  return { channelsAvailable, getMessageParsed };
};
