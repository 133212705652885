import React, { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { meanBy, sumBy } from 'lodash';
import { groupBy } from 'lodash';
import { useQuery } from '@apollo/client';
import {
  CustomBackdrop,
  EmptyStateBox,
  IconWarning,
  InputSelect,
  SelectOptionInterface,
  Switch,
} from '../../../../../components';
import {
  ChannelTypeInternal,
  MeasurementChannelKind,
  MeterCommonMeasurementsQueryVariables,
  MeterTypeInternal,
  PeriodWeeklyPeriod,
  Query,
  ScalarParameterTypeInternal,
  VectorParameterTypeInternal,
} from '../../../../../data-access/gql-types/graphql';
import { METER_COMMON_MEASUREMENTS } from '../../../../../data-access/queries/meter';
import { useApi, useInstallation } from '../../../../../hooks';
import { ChannelInterface } from '../../../../../types';
import * as storage from '../../../../../utils/storage/lavva';
import { MeasurementItem, MeasurementGroup } from '../../../measurement/components/measurement-item';
import { PhasesButtonGroup } from '../../../measurement/components/phases-button-group';
import {
  averageSummaryMeasurements,
  filteredPhasesSumMeasurements,
  filteredSummaryMeasurements,
  measurementParameters,
} from '../../../measurement/utils';
import { sortedParameters, sortedSummary } from '../../analysis/utils/helpers';
import '../index.scss';
import {
  measurementNameParse,
  period1MeasurementByPeriod,
  period2MeasurementByPeriod,
  period3MeasurementByPeriod,
} from '../utils';

interface PropsInterface {
  channel: ChannelInterface;
}

export const MeterMeasurements: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { selectedInstallationId, skipLavvaFetch } = useInstallation();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [advancedParameters, setAdvancedParameters] = useState<boolean>(!!storage.getItem('meterAdvancedParameters'));
  const [activePeriod, setActivePeriod] = useState<PeriodWeeklyPeriod>(PeriodWeeklyPeriod.Total);
  const { convertNumberToMeasurement } = useApi();
  const kind = MeasurementChannelKind.Meter;

  const isStandalone = useMemo(() => {
    return channel.data.type === ChannelTypeInternal.Meter && channel.data.meterType === MeterTypeInternal.Standalone;
  }, [channel]);

  const { data, loading } = useQuery<Query, MeterCommonMeasurementsQueryVariables>(METER_COMMON_MEASUREMENTS, {
    variables: {
      installationId: selectedInstallationId,
      input: {
        channelId: channel.id,
        deviceId: channel.deviceId,
        kind,
        indices: [],
        advancedCommonMeasurements: isStandalone ? advancedParameters : true,
        periods: activePeriod,
      },
    },
    skip: skipLavvaFetch,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 60 * 1000,
  });

  const phaseTabs = useMemo(() => {
    const phases = groupBy(data?.meterCommonMeasurements.data?.vectorMeasurements, 'index');
    return Object.keys(phases).map((phase) => Number(phase));
  }, [data]);

  const vectorMeasurements = useMemo(() => {
    return data?.meterCommonMeasurements.data?.vectorMeasurements || [];
  }, [data?.meterCommonMeasurements.data?.vectorMeasurements]);

  const phaseMeasurementsGroupedByPhase = useMemo(() => {
    const vectorMeasurements = sortedParameters(data?.meterCommonMeasurements.data?.vectorMeasurements || []);
    return groupBy(vectorMeasurements, 'index');
  }, [data?.meterCommonMeasurements.data?.vectorMeasurements]);

  const phaseMeasurementsGroupedByType = useMemo(() => {
    const vectorMeasurements = data?.meterCommonMeasurements.data?.vectorMeasurements;
    return groupBy(vectorMeasurements || [], 'type');
  }, [data?.meterCommonMeasurements.data?.vectorMeasurements]);

  const tariffsQuantity = useMemo(() => {
    const period1 = !!vectorMeasurements.find((x) => x.type === period1MeasurementByPeriod[activePeriod]);
    const period2 = !!vectorMeasurements.find((x) => x.type === period2MeasurementByPeriod[activePeriod]);
    const period3 = !!vectorMeasurements.find((x) => x.type === period3MeasurementByPeriod[activePeriod]);
    return +period1 + +period2 + +period3;
  }, [vectorMeasurements, activePeriod]);

  const timeRanges: SelectOptionInterface<PeriodWeeklyPeriod>[] = useMemo(
    () => [
      { value: PeriodWeeklyPeriod.Total, label: t('periods.total') },
      { value: PeriodWeeklyPeriod.Daily, label: t('periods.daily') },
      { value: PeriodWeeklyPeriod.Weekly, label: t('periods.weekly') },
      { value: PeriodWeeklyPeriod.Monthly, label: t('periods.monthly') },
    ],
    [t],
  );

  const handleControl = (e: ChangeEvent<HTMLInputElement>) => {
    storage.setItem('meterAdvancedParameters', e.target.checked);
    setAdvancedParameters(e.target.checked);
  };

  if (
    !data?.meterCommonMeasurements.data?.scalarMeasurements.length &&
    !data?.meterCommonMeasurements.data?.vectorMeasurements.length &&
    !loading
  ) {
    return (
      <div className="p-t-16 p-l-24 p-r-24">
        <EmptyStateBox content={t('noDataToDisplay')} icon={<IconWarning />} />
      </div>
    );
  }

  return (
    <div className="list-measurement p-l-24 p-r-24">
      <div className="list-measurement__tab-container">
        <PhasesButtonGroup
          onSelectedPhases={(phases) => setActiveTabIndex(phases[0]?.value)}
          defaultPhases={[0, ...(phaseTabs || [])]}
          className="m-t-0 m-b-16 phases-button-group--background-element"
          singleChoice
          {...(!isStandalone ? { itemPrefix: t('outputShort') } : {})}
        />
      </div>
      {isStandalone && (
        <div className="filters m-t-16 m-b-16">
          <div className="row-container">
            <Switch checked={advancedParameters} onChange={handleControl} />
            <p>{t('advancedParameters')}</p>
          </div>
          {!advancedParameters && (
            <InputSelect options={timeRanges} value={activePeriod} onChange={setActivePeriod} label={''} />
          )}
        </div>
      )}
      <div className="list-measurement__wrapper grid-list-16">
        {activeTabIndex === 0 ? (
          <>
            {sortedSummary(phaseMeasurementsGroupedByType)
              ?.filter((phaseMeasurementGroupType) =>
                filteredPhasesSumMeasurements.includes(
                  convertNumberToMeasurement(kind)(
                    'vectors',
                    phaseMeasurementGroupType[0],
                  ) as VectorParameterTypeInternal,
                ),
              )
              .map((phaseMeasurementGroupType) => {
                const type = convertNumberToMeasurement(kind)(
                  'vectors',
                  phaseMeasurementGroupType[0],
                ) as VectorParameterTypeInternal;
                const value = averageSummaryMeasurements.includes(type)
                  ? meanBy(phaseMeasurementGroupType[1], 'value')
                  : sumBy(phaseMeasurementGroupType[1], 'value');

                if (measurementParameters[type])
                  return (
                    <MeasurementItem
                      key={type}
                      channelId={channel.id}
                      measurement={type}
                      measurementGroup={MeasurementGroup.SinglePhase}
                      text={t(`energyMeasurements.types.summary.${measurementNameParse(type)}`)}
                      value={value}
                      parameters={measurementParameters[type]}
                      kind={kind}
                      tariffsQuantity={tariffsQuantity}
                    />
                  );
              })}
            {data?.meterCommonMeasurements.data?.scalarMeasurements
              ?.filter((absoluteMeasurement) =>
                filteredSummaryMeasurements.includes(
                  convertNumberToMeasurement(kind)('scalars', absoluteMeasurement.type) as ScalarParameterTypeInternal,
                ),
              )
              .map((absoluteMeasurement) => {
                const type = convertNumberToMeasurement(kind)(
                  'scalars',
                  absoluteMeasurement.type,
                ) as ScalarParameterTypeInternal;
                if (measurementParameters[type]) {
                  return (
                    <MeasurementItem
                      key={type}
                      channelId={channel.id}
                      measurement={type}
                      measurementGroup={MeasurementGroup.Absolute}
                      text={t(`energyMeasurements.types.summary.${measurementNameParse(type)}`)}
                      value={absoluteMeasurement.value}
                      parameters={measurementParameters[type]}
                      kind={kind}
                    />
                  );
                }
              })}
          </>
        ) : (
          phaseMeasurementsGroupedByPhase[activeTabIndex]?.map((phaseMeasurement) => {
            const type = convertNumberToMeasurement(kind)('vectors', phaseMeasurement.type);

            if (measurementParameters[type]) {
              return (
                <MeasurementItem
                  key={phaseMeasurement.type}
                  channelId={channel.id}
                  measurement={type}
                  measurementGroup={MeasurementGroup.SinglePhase}
                  text={t(`energyMeasurements.types.phase.${measurementNameParse(type)}`)}
                  value={phaseMeasurement.value}
                  parameters={measurementParameters[type]}
                  phase={activeTabIndex}
                  kind={kind}
                  tariffsQuantity={tariffsQuantity}
                />
              );
            }
          })
        )}
      </div>
      <CustomBackdrop loading={loading} />
    </div>
  );
};
