import React from 'react';
import { IntegrationType } from '../data-access/gql-types/graphql';
import { useInstallation } from '../hooks';
import ActionCreateLavva from '../modules/action-create/steps/name-icon-form';
import LavvaActionCreateTasks from '../modules/action-create/steps/task-list';
import LavvaActionTasksTypes from '../modules/action-create/steps/task-types';
import LavvaActionTasksVariants from '../modules/action-create/steps/task-variants';
import LavvaActionChannelSettings from '../modules/action-create/steps/task-variants/channel-settings';
import LavvaDeviceVariants from '../modules/action-create/steps/task-variants/devices-variants';
import ActionListLavva from '../modules/action-list';
import ChannelDetailsLavva from '../modules/channel-details';
import ChannelListLavva from '../modules/channel-list';
import ChannelsVisibilityLavva from '../modules/channels-visiblity';
import ChannelConfigurationLavva from '../modules/configuration/configuration-form';
import DashboardLavva from '../modules/dashboard';
import DeviceListLavva from '../modules/device-list';
import DeviceDetailsLavva from '../modules/device-settings';
import ActionCreateExalus from '../modules/exalus/pages/action-create';
import ExalusActionCreateTasks from '../modules/exalus/pages/action-create/tasks';
import ExalusActionTasksTypes from '../modules/exalus/pages/action-create/tasks/task-types';
import ExalusActionTasksVariants from '../modules/exalus/pages/action-create/tasks/task-variants';
import ExalusActionChannelSettings from '../modules/exalus/pages/action-create/tasks/task-variants/channel-settings';
import ExalusDeviceVariants from '../modules/exalus/pages/action-create/tasks/task-variants/devices-variants';
import ActionListExalus from '../modules/exalus/pages/action-list';
import ChannelConfigurationExalus from '../modules/exalus/pages/channel-configuration';
import ChannelDetailsExalus from '../modules/exalus/pages/channel-details';
import ChannelListExalus from '../modules/exalus/pages/channel-list';
import ChannelsVisibilityExalus from '../modules/exalus/pages/channels-visiblity';
import DashboardExalus from '../modules/exalus/pages/dashboard';
import DeviceDetailsExalus from '../modules/exalus/pages/device-details';
import DeviceListExalus from '../modules/exalus/pages/device-list';
import DeviceListProvider from '../modules/exalus/pages/device-list/context';
import GroupSortChannelsExalus from '../modules/exalus/pages/group-sort-channels';
import GroupCreateExalus from '../modules/exalus/pages/group/create';
import GroupEditExalus from '../modules/exalus/pages/group/edit';
import GroupListExalus from '../modules/exalus/pages/group/group-list';
import GroupSortExalus from '../modules/exalus/pages/group/group-sort';
import GroupListLavva from '../modules/group-list';
import GroupSortLavva from '../modules/group-sort';
import GroupSortChannelsLavva from '../modules/group-sort-channels';
import GroupCreateLavva from '../modules/group/create';
import GroupEditLavva from '../modules/group/edit';
import { isLocalApp } from '../utils/helpers/local-app';

interface HookParams {
  Lavva: React.ReactElement;
  Exalus: React.ReactElement;
}

const useIntegrationPage = ({ Lavva, Exalus }: HookParams) => {
  const { integrationType } = useInstallation();

  if (isLocalApp) return Exalus;
  if (integrationType === IntegrationType.Exalus) return Exalus;
  return Lavva;
};

export const GroupList: React.FC = () => useIntegrationPage({ Lavva: <GroupListLavva />, Exalus: <GroupListExalus /> });
export const ActionList: React.FC = () =>
  useIntegrationPage({ Lavva: <ActionListLavva />, Exalus: <ActionListExalus /> });
export const Dashboard: React.FC = () => useIntegrationPage({ Lavva: <DashboardLavva />, Exalus: <DashboardExalus /> });
export const ChannelList: React.FC = () =>
  useIntegrationPage({ Lavva: <ChannelListLavva />, Exalus: <ChannelListExalus /> });
export const GroupCreate: React.FC = () =>
  useIntegrationPage({ Lavva: <GroupCreateLavva />, Exalus: <GroupCreateExalus /> });
export const GroupEdit: React.FC = () => useIntegrationPage({ Lavva: <GroupEditLavva />, Exalus: <GroupEditExalus /> });
export const DeviceList: React.FC = () =>
  useIntegrationPage({
    Lavva: <DeviceListLavva />,
    Exalus: (
      <DeviceListProvider>
        <DeviceListExalus />
      </DeviceListProvider>
    ),
  });
export const DeviceDetails: React.FC = () =>
  useIntegrationPage({ Lavva: <DeviceDetailsLavva />, Exalus: <DeviceDetailsExalus /> });
export const ChannelDetails: React.FC = () =>
  useIntegrationPage({ Lavva: <ChannelDetailsLavva />, Exalus: <ChannelDetailsExalus /> });
export const ChannelConfiguration: React.FC = () =>
  useIntegrationPage({ Lavva: <ChannelConfigurationLavva />, Exalus: <ChannelConfigurationExalus /> });
export const ChannelsVisibility: React.FC = () =>
  useIntegrationPage({ Lavva: <ChannelsVisibilityLavva />, Exalus: <ChannelsVisibilityExalus /> });
export const GroupSort: React.FC = () => useIntegrationPage({ Lavva: <GroupSortLavva />, Exalus: <GroupSortExalus /> });
export const GroupSortChannels: React.FC = () =>
  useIntegrationPage({ Lavva: <GroupSortChannelsLavva />, Exalus: <GroupSortChannelsExalus /> });
export const ActionCreate: React.FC = () =>
  useIntegrationPage({ Lavva: <ActionCreateLavva />, Exalus: <ActionCreateExalus /> });
export const ActionCreateTasks: React.FC = () =>
  useIntegrationPage({ Lavva: <LavvaActionCreateTasks />, Exalus: <ExalusActionCreateTasks /> });
export const ActionTasksTypes: React.FC = () =>
  useIntegrationPage({ Lavva: <LavvaActionTasksTypes />, Exalus: <ExalusActionTasksTypes /> });
export const ActionTasksVariants: React.FC = () =>
  useIntegrationPage({ Lavva: <LavvaActionTasksVariants />, Exalus: <ExalusActionTasksVariants /> });
export const ActionDeviceVariants: React.FC = () =>
  useIntegrationPage({ Lavva: <LavvaDeviceVariants />, Exalus: <ExalusDeviceVariants /> });
export const ActionChannelSettings: React.FC = () =>
  useIntegrationPage({ Lavva: <LavvaActionChannelSettings />, Exalus: <ExalusActionChannelSettings /> });
export const ActionEdit: React.FC = () =>
  useIntegrationPage({ Lavva: <ActionCreateLavva />, Exalus: <ActionCreateExalus /> });
export const ActionEditTasks: React.FC = () =>
  useIntegrationPage({ Lavva: <LavvaActionCreateTasks />, Exalus: <ExalusActionCreateTasks /> });
