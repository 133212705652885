import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSetMeterPurpose } from '../../../../../api/modules/meter/meter.hooks';
import { PurposeValueRequest } from '../../../../../api/modules/meter/meter.types';
import { InputSelect } from '../../../../../components';
import { MeterPurposeInternal } from '../../../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../../../hooks';
import { useDevicesAndChannelsContext } from '../../../../../hooks/devices-and-channels/provider';
import { ChannelMeterInterface } from '../../../../../types';
import Info from '../info';

interface ComponentProps {
  setTimeoutError: () => void;
}

const MeterPurpose: React.FC<ComponentProps> = ({ setTimeoutError }) => {
  const { t } = useTranslation('configuration');
  const { channelId } = useParams<{ channelId: string }>();
  const { channel } = useDevicesAndChannelsContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { mutate } = useSetMeterPurpose();

  const onChangePurpose = (value: MeterPurposeInternal) => {
    if (meterPurpose !== value && channel) {
      turnOnBackdrop();

      mutate(
        { channelId, value: PurposeValueRequest[value] },
        {
          onSuccess: () => {
            setTimeoutError();
          },
          onError: () => {
            turnOffBackdrop();
          },
        },
      );
    }
  };

  const meterPurpose = useMemo(
    () => (channel?.data as ChannelMeterInterface).meterPurpose,
    [(channel?.data as ChannelMeterInterface).meterPurpose],
  );

  return (
    <>
      <Info title={t('meterPurpose.purpose')} />
      <InputSelect
        value={meterPurpose}
        onChange={onChangePurpose}
        options={[
          { label: t('meterPurpose.main'), value: MeterPurposeInternal.Main },
          { label: t('meterPurpose.pv'), value: MeterPurposeInternal.Pv },
          { label: t('meterPurpose.energyStorage'), value: MeterPurposeInternal.EnergyStorage },
          { label: t('meterPurpose.consumption'), value: MeterPurposeInternal.Consumption },
        ]}
        label={''}
      />
    </>
  );
};

export default MeterPurpose;
