import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelBox } from '../../../../components';
import { useDevicesAndChannelsContext } from '../../../../hooks/devices-and-channels/provider';
import { Calibration } from '../../../device-settings/device-settings-form/sections';
import { useConfiguration } from '../../hooks/use-configuration';
import { InfoPageType } from '../../types';
import Info from '../components/info';
import { InputsType } from '../components/inputs-type';
import SwapIn from '../components/swap-in';

interface ComponentProps {
  openInfoPage: (type: InfoPageType) => void;
}

const CoverConfiguration: React.FC<ComponentProps> = ({ openInfoPage }) => {
  const { t } = useTranslation('configuration');
  const { channel } = useDevicesAndChannelsContext();
  const { swapOut } = useConfiguration({ channel });

  if (!channel) return null;

  return (
    <>
      <Info title={t('channelTest')} onClick={() => openInfoPage(InfoPageType.CHANNEL)} />
      <ChannelBox channel={channel} isListItem />
      <div className="row_container max-width-desktop">
        <button className="button button--secondary" onClick={swapOut}>
          {t('reverseOutputs')}
        </button>
      </div>
      <div className="row_container margin_top">
        <span>{t('reverseOutputsContent')}</span>
      </div>
      <hr />
      <Info title={t('calibration')} onClick={() => openInfoPage(InfoPageType.CALIBRATION)} />
      <Calibration channel={channel} />
      <hr />
      <Info title={t('inService')} onClick={() => openInfoPage(InfoPageType.INSERVICE)} />
      <SwapIn channel={channel} />
      <InputsType openInfoPage={openInfoPage} isUnderlineUp />
    </>
  );
};

export default CoverConfiguration;
