import { GateKindInternal } from '../../../../data-access/gql-types/graphql';

export const gateHasVentilation = (gateKind: GateKindInternal) => {
  return [
    GateKindInternal.OnePieceDoor,
    GateKindInternal.Sectional,
    GateKindInternal.RollUp,
    GateKindInternal.Unknown,
  ].includes(gateKind);
};
