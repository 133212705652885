import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelBox } from '../../../../components';
import { useDevicesAndChannelsContext } from '../../../../hooks/devices-and-channels/provider';
import { InfoPageType } from '../../types';
import Info from '../components/info';
import { InputsType } from '../components/inputs-type';
import ReturnBehaviorSelect from '../components/return-behavior-select';
import SwapIn from '../components/swap-in';

interface ComponentProps {
  openInfoPage: (type: InfoPageType) => void;
}

const SwitchConfiguration: React.FC<ComponentProps> = ({ openInfoPage }) => {
  const { t } = useTranslation('configuration');
  const { channel } = useDevicesAndChannelsContext();

  if (!channel) return null;

  return (
    <>
      <Info title={t('channelTest')} onClick={() => openInfoPage(InfoPageType.CHANNEL)} />
      <ChannelBox channel={channel} isListItem />
      <hr />

      <Info title={t('inService')} onClick={() => openInfoPage(InfoPageType.INSERVICE)} />
      <SwapIn channel={channel} />
      <hr />
      <InputsType openInfoPage={openInfoPage} isUnderlineDown />
      <ReturnBehaviorSelect channel={channel} />
    </>
  );
};

export default SwitchConfiguration;
