import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChannelItem,
  IconAdd,
  IconDelete,
  IconEdit,
  IconSortHandle,
  InputSelect,
  PopUpNav,
  SubmitButton,
} from '../../../../components';
import { IconAdd as IconAddOption, IconTimeDelay } from '../../../../components/popup-nav/icons';
import { TriggerChannelParameters } from '../../../../components/trigger/channel-parameters';
import { TriggerConditionParameters } from '../../../../components/trigger/condition-parameters';
import { PriceConditionDetails } from '../../../../components/trigger/price-condition-details';
import { TimeConditionDetails } from '../../../../components/trigger/time-condition-details';
import {
  TriggerActionType,
  TriggerPriceAndTimeConditionResponse,
  TriggerTimeConditionResponse,
} from '../../../../data-access/gql-types/graphql';
import { DelayItem } from '../../components/delay-item';
import { DelayDialog } from '../../condition-types/time-condition/components';
import { useTriggerFormContext } from '../../context';
import { ChosenConditionType } from '../../enums';
import { useTrigger } from '../../hooks';
import { StepComponentProps } from '../../steps';

export const TriggerSummaryStep: React.FC<StepComponentProps> = ({
  goToCondition,
  goToChannels,
  goToChannelSetup,
  goToActionsSort,
  goToStateConditionSetup,
  goToName,
  goToFailureMode,
  setConditionType,
  isEdit,
}) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { handleSaveTrigger, isLoading, failureModes } = useTrigger();
  const {
    timeCondition,
    stateConditions,
    priceCondition,
    channels,
    removeAction,
    removeTimeCondition,
    removeStateCondition,
    removePriceCondition,
    actions,
    addTimeDelay,
    name,
    onFailureMode,
    setOnFailureMode,
  } = useTriggerFormContext();
  const [delayValue, setDelayValue] = useState<number>(1);
  const [showDelayDialog, setShowDelayDialog] = useState<boolean>(false);

  const handleConditionClick = (type: ChosenConditionType) => {
    setConditionType(type);

    if (goToCondition) {
      goToCondition();
    }
  };

  const selectedStateConditions = useMemo(() => {
    return stateConditions.map((stateCondition) => {
      const handleRemoveCondition = () => {
        removeStateCondition(stateCondition.id);
      };
      const handleConditionClick = () => {
        setConditionType(ChosenConditionType.STATE);
        if (goToStateConditionSetup) {
          goToStateConditionSetup(stateCondition.id);
        }
      };
      const isOn = stateCondition.booleanValue ?? false;

      return (
        <li key={stateCondition.id} className="action-condition action-condition--with-delete">
          <div className="action-advanced-form__device-item">
            <div onClick={handleConditionClick}>
              <ChannelItem
                id={stateCondition.id}
                isOn={isOn}
                details={<TriggerConditionParameters condition={stateCondition} />}
              />
            </div>
            <IconDelete className="action-advanced-form__device-item-button" onClick={handleRemoveCondition} />
          </div>
        </li>
      );
    });
  }, [stateConditions]);

  const mappedActions = useMemo(
    () =>
      actions.map((action) => {
        const channel = channels.find((channel) => channel.id === action.id);
        return {
          ...action,
          channel,
        };
      }),
    [actions],
  );

  const selectedActions = useMemo(
    () =>
      mappedActions.map((action) => {
        const handleRemoveAction = () => {
          removeAction(action.id);
        };
        const handleActionClick = () => {
          if (
            'betweenHoursFromInclusive' in priceCondition ||
            'betweenHoursToInclusive' in priceCondition ||
            'numberOfCheapestPeriodsInDay' in priceCondition ||
            'priceGreaterThanOrEqual' in priceCondition ||
            'priceLesserThanOrEqual' in priceCondition
          ) {
            return;
          }

          if (goToChannelSetup) {
            if (action.actionType === TriggerActionType.DelayInSeconds) {
              setShowDelayDialog(true);
            } else {
              goToChannelSetup(action.id);
            }
          }
        };
        const isOn = action.booleanValue ?? false;

        return (
          <li key={action.id} className="action-advanced-form__device-item">
            {action.actionType === TriggerActionType.DelayInSeconds ? (
              <DelayItem action={action} showDialog />
            ) : (
              <div onClick={handleActionClick}>
                <ChannelItem id={action.id} isOn={isOn} details={<TriggerChannelParameters action={action} />} />
              </div>
            )}
            <IconDelete className="action-advanced-form__device-item-button" onClick={handleRemoveAction} />
          </li>
        );
      }),
    [actions],
  );

  const handleClick = () => {
    if (isEdit) {
      handleSaveTrigger();
    } else {
      if (goToFailureMode) {
        goToFailureMode();
      }
    }
  };

  const isValid = useMemo(() => {
    return (
      actions.length &&
      (Object.values(timeCondition).length || stateConditions.length || Object.values(priceCondition).length)
    );
  }, [actions, timeCondition, stateConditions, priceCondition]);

  return (
    <>
      {isEdit ? (
        <>
          <div className="action-advanced-form__name-wrapper m-t-8 m-b-24">
            <span className="action-advanced-form__name-label">{t('trigger.name')}</span>
            <div className="action-advanced-form__name-box">
              <h5 className="action-advanced-form__name">{name}</h5>
              <button
                type="button"
                className="action-advanced-form__edit-button"
                onClick={() => {
                  if (goToName) {
                    goToName();
                  }
                }}
              >
                <IconEdit />
              </button>
            </div>
          </div>

          <InputSelect
            onChange={setOnFailureMode}
            value={onFailureMode}
            options={failureModes}
            label={t('trigger.failureMode.label')}
          />
          <hr className="m-b-24" />
        </>
      ) : null}

      <div className="action-conditions--container">
        <div className="action-conditions max-width-desktop">
          <div className="action-conditions__header">
            <h2>{t('trigger.if')}</h2>
            <div className="action-conditions__buttons">
              <div
                onClick={() => {
                  setConditionType(ChosenConditionType.NONE);
                  if (goToCondition) goToCondition();
                }}
              >
                <IconAdd />
              </div>
            </div>
          </div>

          {Object.values(timeCondition ?? {}).length && timeCondition ? (
            <TimeConditionDetails
              timeCondition={timeCondition as TriggerTimeConditionResponse}
              removeTimeCondition={removeTimeCondition}
              handleTimeConditionClick={() => handleConditionClick(ChosenConditionType.TIME)}
            />
          ) : null}
          {Object.values(priceCondition ?? {}).length && priceCondition ? (
            <PriceConditionDetails
              priceCondition={priceCondition as TriggerPriceAndTimeConditionResponse}
              removePriceCondition={removePriceCondition}
              handlePriceConditionClick={() => handleConditionClick(ChosenConditionType.PRICE)}
            />
          ) : null}
          {selectedStateConditions}
        </div>

        <hr className="m-b-32 max-width-desktop" />

        <div className="action-conditions max-width-desktop">
          <div className="action-conditions__header">
            <h2>{t('trigger.then')}</h2>
            <div className="action-conditions__buttons">
              <PopUpNav
                icon={<IconAdd />}
                links={[
                  ...(goToChannels
                    ? [
                        {
                          onClick: goToChannels,
                          label: t('trigger.addChannel'),
                          icon: <IconAddOption />,
                        },
                      ]
                    : []),
                  {
                    onClick: () => setShowDelayDialog(true),
                    label: t('trigger.addTimeDelay'),
                    icon: <IconTimeDelay />,
                  },
                ]}
              />
              <button className="p-t-0 p-r-0 p-b-0 p-l-16" onClick={goToActionsSort}>
                <IconSortHandle size={32} />
              </button>
            </div>
          </div>
          <ul>{selectedActions}</ul>
        </div>
      </div>

      <DelayDialog
        open={showDelayDialog}
        setOpen={setShowDelayDialog}
        title={t('trigger.delay')}
        onSave={(value) => {
          addTimeDelay(value);
          setDelayValue(1);
        }}
        time={delayValue}
      />

      <SubmitButton disabled={!isValid} onClick={handleClick} isLoading={isLoading}>
        {isEdit ? tc('buttons.save') : tc('buttons.next')}
      </SubmitButton>
    </>
  );
};
