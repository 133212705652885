import { DeviceConnectionState } from '../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../hooks';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { ChannelCoverStateInterface, ChannelGateStateInterface, PublishStatus } from '../../../types';
import { DeviceResponse } from '../device/device.types';

interface Hook {
  setChannelAsDisconnected: (deviceId: string) => void;
  resetCalibrateState: (channelId: string, data: DeviceResponse) => void;
}

export const useUpdateChannel = (): Hook => {
  const { setChannelState } = useChannelsState();
  const { channelList } = useDevicesAndChannelsContext();

  const setChannelAsDisconnected = (deviceId: string) => {
    const channelsToUpdate = channelList.filter((ch) => ch.deviceId === deviceId);

    setChannelState((oldState) => {
      const newState = { ...oldState };

      for (let i = 0; i < channelsToUpdate.length; i++) {
        newState[channelsToUpdate[i].id] = {
          ...newState[channelsToUpdate[i].id],
          deviceConnectionState: DeviceConnectionState.Disconnected,
        };
      }

      return newState;
    });
  };

  const resetCalibrateState = <T extends ChannelCoverStateInterface | ChannelGateStateInterface>(
    channelId: string,
    data: DeviceResponse,
  ) => {
    if (data.publishStatus === PublishStatus.Ok) {
      setChannelState((oldState) => {
        const newState = { ...oldState };
        const calibrateState = (newState[channelId] as T)?.calibrateState;

        newState[channelId] = {
          ...newState[channelId],
          calibrateState: {
            openingMilliseconds: calibrateState?.openingMilliseconds || 0,
            closingMilliseconds: calibrateState?.closingMilliseconds || 0,
            error: calibrateState?.error,
            progress: calibrateState?.progress,
            started: false,
          },
        } as T;

        return newState;
      });
    }
  };

  return {
    setChannelAsDisconnected,
    resetCalibrateState,
  };
};
