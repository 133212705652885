import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import { ChannelIcon, Checkbox, DialogConfirmation, Header, NavHeader, Page, SubmitButton } from '../../components';
import {
  Action,
  ActivityType,
  ChannelTypeInternal,
  EditActionMutation,
  EditActionMutationVariables,
  EditActionInput,
  UserAction,
} from '../../data-access/gql-types/graphql';
import { EDIT_ACTION } from '../../data-access/mutations/action';
import { ACTION_DETAILS } from '../../data-access/queries/actions';
import { useApi, useInstallation } from '../../hooks';
import { useDevicesAndChannelsContext } from '../../hooks/devices-and-channels/provider';
import { useRefetchData } from '../../hooks/refresh-data';
import { ChannelInterface } from '../../types';
import { toastSuccess } from '../../utils/toast';

const ActionEditChannels: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { actionId } = useParams<{ actionId: string }>();
  const { userActions } = useApi();
  const { selectedInstallationId } = useInstallation();
  const { channelList } = useDevicesAndChannelsContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [action, setAction] = useState<Action>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
  const { refetchActions } = useRefetchData();
  const [editActionVariables, setEditActionVariables] = useState<EditActionInput>({
    installationId: selectedInstallationId,
    iconName: '',
    name: '',
    id: '',
    channels: [],
  });

  const [editAction, { loading }] = useMutation<EditActionMutation, EditActionMutationVariables>(EDIT_ACTION, {
    refetchQueries: [
      {
        query: ACTION_DETAILS,
        variables: {
          actionId,
          installationId: selectedInstallationId,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: async (response) => {
      if (response.editAction?.idResponse?.id) {
        await refetchActions();
        toastSuccess({ content: t('basic.editSuccess') });
        history.goBack();
      }
    },
  });

  useEffect(() => {
    const data = userActions?.find((userAction) => userAction.action.id === actionId) as UserAction;
    setAction(data.action);
    setEditActionVariables({
      installationId: selectedInstallationId,
      name: data.action.name,
      iconName: data.iconName,
      id: actionId,
      channels: [],
    });

    setSelectedChannels(data?.action.channelActions.map((channel) => channel.channelId || '') as string[]);
  }, [setAction, userActions, setSelectedChannels, selectedInstallationId]);

  const list = useMemo(
    () => channelList.filter((channel) => channel && channel.data.type === action?.channelType) as ChannelInterface[],
    [action, channelList],
  );

  useEffect(() => {
    setIsValid(selectedChannels?.length !== 0);
  }, [setIsValid, selectedChannels, action]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setShowSaveDialog(true);
      if (action && selectedChannels) {
        const newVariables = { ...editActionVariables };

        newVariables.channels = selectedChannels.map((channelId, index) => {
          const channel = channelList.find((channel) => channel && channel.id === channelId);
          const channelType = channel?.data.type;

          return {
            channelId,
            channelType: channelType as ChannelTypeInternal,
            order: index,
            parameter: { activityType: ActivityType.ClientControlled, value: '' },
          };
        });

        setEditActionVariables(newVariables);
      }
    },
    [setEditActionVariables, selectedChannels, setShowSaveDialog],
  );

  const onCancel = useCallback(() => setShowSaveDialog(false), [setShowSaveDialog]);

  const onSave = useCallback(() => {
    editAction({
      variables: {
        input: editActionVariables,
      },
    });
  }, [editActionVariables]);

  const handleChannelOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = e.target;

      if (checked && !selectedChannels.includes(value)) {
        const values = [...selectedChannels, value];
        setSelectedChannels(values);
      }

      if (!checked && selectedChannels.includes(value)) {
        const values = selectedChannels.filter((v) => v !== value);
        setSelectedChannels(values);
      }
    },
    [setSelectedChannels, selectedChannels],
  );

  if (!action) return null;

  return (
    <Page
      header={
        <>
          <NavHeader />
          <Header title={t('basicActionForm.edit')} isUnderline />
        </>
      }
    >
      <div className="action-form__devices">
        <form ref={formRef} onSubmit={onSubmit}>
          <div className="action-form__devices-header">{tc(`typesPlural.${action?.channelType}`)}</div>
          <div className="action-form__devices-list">
            {list.map((element) => (
              <Checkbox
                id={element.id}
                key={element.id}
                checked={selectedChannels.includes(element.id || '')}
                onChange={handleChannelOnChange}
              >
                <div className={classNames('action-form__devices-element', { invisible: !element.isVisible })}>
                  <div className="action-form__devices-icon">
                    <ChannelIcon channel={element} statusOn />
                  </div>
                  <span>{element.alias}</span>
                </div>
              </Checkbox>
            ))}
          </div>
          <SubmitButton disabled={!isValid} isLoading={loading} />
        </form>
        <DialogConfirmation
          show={showSaveDialog}
          setShow={setShowSaveDialog}
          header={t('confirmDialog.header')}
          primaryBtnText={tc('buttons.save')}
          secondaryBtnText={tc('buttons.cancel')}
          primaryBtnAction={onSave}
          secondaryBtnAction={onCancel}
        />
      </div>
    </Page>
  );
};

export default ActionEditChannels;
