import { useEffect, useState } from 'react';
import { Api } from 'lavva.exalushome';
import { DeviceUpdateShortInfo } from 'lavva.exalushome/build/js/Services/Updates/IUpdateInfo';
import { IUpdatesService } from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';
import { UpdatesService } from 'lavva.exalushome/build/js/Services/Updates/UpdatesService';
import { isDevelopment } from '../../../../../utils/helpers/environment';
import { useExalusContext } from '../../../context';

export const useDevicesUpdatesAvailability = () => {
  const [updatesAvailability, setUpdatesAvailability] = useState<DeviceUpdateShortInfo[]>([]);
  const { synchronized, connected } = useExalusContext();

  const checkDevicesUpdateAvailability = async () => {
    if (!isDevelopment) return;
    const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);
    const result: DeviceUpdateShortInfo[] = await updateService.CheckDevicesUpdatesAvailabilityAsync();

    if (result.length) {
      setUpdatesAvailability(result);
    }
  };

  useEffect(() => {
    if (synchronized && connected) {
      checkDevicesUpdateAvailability();
    }
  }, [synchronized, connected]);

  return { updatesAvailability };
};
