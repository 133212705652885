import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconInitiate, ControlWrapper, InitiateButton } from '../../../components';
import LavvaDetailsWrapper from '../../../components/details-wrapper';
import { ChannelTypeInternal, DeviceConnectionState } from '../../../data-access/gql-types/graphql';
import { useChannelsState, useSwitch } from '../../../hooks';
import { ChannelInterface } from '../../../types';
import { ChannelTimesActivations } from '../components/channel-times-activation';
import { ChannelDetailsMeasurements } from './measurements';

interface PropsInterface {
  channel: ChannelInterface;
}

const ChannelDetailsSwitch: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { handleControlOn: onSwitch, isOn: isOnSwitch } = useSwitch({
    channelId: channel.id,
    deviceId: channel.deviceId,
  });
  const { channelState } = useChannelsState();

  const statusOn = useMemo(() => isOnSwitch, [isOnSwitch, channel]);

  const handleControl = useCallback((status: boolean) => onSwitch(status), [onSwitch, channel]);

  return (
    <LavvaDetailsWrapper
      tabs={[{ label: t('control') }, { label: t('measurement') }]}
      additionalTabs={
        channel.data.type === ChannelTypeInternal.Switch &&
        channel.data.measuredBy &&
        channelState[channel.id]?.deviceConnectionState !== DeviceConnectionState.Recovery
          ? [<ChannelDetailsMeasurements key={2} channel={channel} measurementChannel={channel.data.measuredBy} />]
          : []
      }
    >
      <ControlWrapper>
        <InitiateButton isOn={statusOn} onClickControl={handleControl}>
          <IconInitiate />
        </InitiateButton>
      </ControlWrapper>
      <ChannelTimesActivations channel={channel} />
    </LavvaDetailsWrapper>
  );
};

export default ChannelDetailsSwitch;
