import { gql } from '@apollo/client';

export const CREATE_TIME_TRIGGER = gql`
  mutation CreateTimeTrigger($input: CreateTimeTriggerInput!) {
    createTimeTrigger(input: $input) {
      idResponse {
        id
      }
      errors {
        ... on TriggerError {
          error
          message
        }
      }
    }
  }
`;

export const CREATE_STATE_TRIGGER = gql`
  mutation CreateStateTrigger($input: CreateStateTriggerInput!) {
    createStateTrigger(input: $input) {
      idResponse {
        id
      }
      errors {
        ... on TriggerError {
          error
          message
        }
      }
    }
  }
`;

export const CREATE_PRICE_TRIGGER = gql`
  mutation CreatePriceAndTimeTrigger($input: CreatePriceAndTimeTriggerInput!) {
    createPriceAndTimeTrigger(input: $input) {
      idResponse {
        id
      }
      errors {
        ... on TriggerError {
          error
          message
        }
      }
    }
  }
`;

export const ACTIVATE_TRIGGER = gql`
  mutation ActivateTrigger($input: ActivateTriggerInput!) {
    activateTrigger(input: $input) {
      result {
        ok
      }
      errors {
        ... on TriggerError {
          error
          message
        }
      }
    }
  }
`;

export const DEACTIVATE_TRIGGER = gql`
  mutation DeactivateTrigger($input: DeactivateTriggerInput!) {
    deactivateTrigger(input: $input) {
      result {
        ok
      }
      errors {
        ... on TriggerError {
          error
          message
        }
      }
    }
  }
`;

export const DELETE_TRIGGER = gql`
  mutation DeleteTrigger($input: DeleteTriggerInput!) {
    deleteTrigger(input: $input) {
      result {
        ok
      }
      errors {
        ... on TriggerError {
          error
          message
        }
      }
    }
  }
`;

export const EDIT_TIME_TRIGGER = gql`
  mutation EditTimeTrigger($input: EditTimeTriggerInput!) {
    editTimeTrigger(input: $input) {
      idResponse {
        id
      }
      errors {
        ... on TriggerError {
          error
          message
        }
      }
    }
  }
`;

export const EDIT_STATE_TRIGGER = gql`
  mutation EditStateTrigger($input: EditStateTriggerInput!) {
    editStateTrigger(input: $input) {
      idResponse {
        id
      }
      errors {
        ... on TriggerError {
          error
          message
        }
      }
    }
  }
`;

export const EDIT_PRICE_TRIGGER = gql`
  mutation EditPriceAndTimeTrigger($input: EditPriceAndTimeTriggerInput!) {
    editPriceAndTimeTrigger(input: $input) {
      idResponse {
        id
      }
      errors {
        ... on TriggerError {
          error
          message
        }
      }
    }
  }
`;

export const UPDATE_TRIGGER_NAME = gql`
  mutation UpdateTriggerName($input: UpdateTriggerNameInput!) {
    updateTriggerName(input: $input) {
      result {
        ok
      }
      errors {
        ... on TriggerError {
          error
          message
        }
      }
    }
  }
`;

export const RUN_TRIGGER = gql`
  mutation RunTrigger($input: RunTriggerInput!) {
    runTrigger(input: $input) {
      result {
        ok
      }
      errors {
        ... on TriggerError {
          error
          message
        }
      }
    }
  }
`;
