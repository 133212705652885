import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Header, NavHeader, Page } from '../../components';
import { TriggerActionsSort } from './components/actions-sort';
import ChannelSetup from './components/channel-setup';
import { ConditionList } from './condition-list';
import { LocationCondition } from './condition-types/location-condition';
import { PriceCondition } from './condition-types/price-condition';
import { StateCondition } from './condition-types/state-condition';
import { TimeCondition } from './condition-types/time-condition';
import { WeatherCondition } from './condition-types/weather-condition';
import { useTriggerFormContext } from './context';
import { ChosenConditionType } from './enums';
import './index.scss';
import { TriggerChannelsStep } from './steps/channels';
import { TriggerFailureModeStep } from './steps/failure';
import { TriggerNameStep } from './steps/name';
import { TriggerSummaryStep } from './steps/summary';

export interface StepComponentProps {
  isEdit?: boolean;
  conditionType: ChosenConditionType;
  setConditionType: (type: ChosenConditionType) => void;
  goToCondition?: () => void;
  goToChannels?: () => void;
  goToSummary?: () => void;
  goToChannelSetup?: (channelId: string) => void;
  goToActionsSort?: () => void;
  goToStateConditionSetup?: (conditionId: string) => void;
  goToName?: () => void;
  goToFailureMode?: () => void;
}

const TimeConditionSteps = [
  TimeCondition,
  TriggerChannelsStep,
  TriggerSummaryStep,
  TriggerFailureModeStep,
  TriggerNameStep,
];
const StateConditionSteps = [
  StateCondition,
  TriggerChannelsStep,
  TriggerSummaryStep,
  TriggerFailureModeStep,
  TriggerNameStep,
];
const LocationConditionSteps = [
  LocationCondition,
  TriggerChannelsStep,
  TriggerSummaryStep,
  TriggerFailureModeStep,
  TriggerNameStep,
];
const WeatherConditionSteps = [
  WeatherCondition,
  TriggerChannelsStep,
  TriggerSummaryStep,
  TriggerFailureModeStep,
  TriggerNameStep,
];
const PriceConditionSteps = [
  PriceCondition,
  TriggerChannelsStep,
  TriggerSummaryStep,
  TriggerFailureModeStep,
  TriggerNameStep,
];

export const TriggerFormSteps: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const [conditionType, setConditionType] = useState<ChosenConditionType>(ChosenConditionType.NONE);
  const [channelId, setChannelId] = useState<string>('');
  const [stepIndex, setStepIndex] = useState<number>(0);
  const { triggerId } = useParams<{ triggerId: string }>();
  const goStepBack = useCallback(() => setStepIndex((prevState) => prevState - 1), []);
  const goToCondition = () => setStepIndex(0);
  const goToChannels = () => setStepIndex(1);
  const goToSummary = () => setStepIndex(2);
  const goToFailureMode = () => setStepIndex(3);
  const goToName = () => setStepIndex(4);
  const goToChannelSetup = (channelId) => {
    setChannelId(channelId);
    setStepIndex(5);
  };
  const goToActionsSort = () => setStepIndex(6);
  const goToStateConditionSetup = (conditionId) => {
    setChannelId(conditionId);
    setStepIndex(0);
  };
  const isEdit = useMemo(() => !!triggerId, [triggerId]);

  const { shouldRedirect, timeCondition } = useTriggerFormContext();

  useEffect(() => {
    if (shouldRedirect) {
      if (!Object.values(timeCondition).length) {
        setConditionType(ChosenConditionType.STATE);
        goToSummary();
      } else {
        setConditionType(ChosenConditionType.TIME);
        goToCondition();
      }
    }
  }, [shouldRedirect]);

  const getChosenCondition = (chosenCondition: ChosenConditionType) => {
    switch (chosenCondition) {
      case ChosenConditionType.TIME:
        return TimeConditionSteps[stepIndex];
      case ChosenConditionType.STATE:
        return StateConditionSteps[stepIndex];
      case ChosenConditionType.LOCATION:
        return LocationConditionSteps[stepIndex];
      case ChosenConditionType.WEATHER:
        return WeatherConditionSteps[stepIndex];
      case ChosenConditionType.PRICE:
        return PriceConditionSteps[stepIndex];
      default:
        return TimeConditionSteps[stepIndex];
    }
  };

  const ActiveConditionComponent = getChosenCondition(conditionType);

  const onNavHeaderClick = (): void => {
    if (stepIndex === 5 || stepIndex === 6) {
      goToSummary();
    } else if (stepIndex === 2 && isEdit) {
      history.goBack();
    } else if (stepIndex === 1 && isEdit) {
      goToSummary();
    } else {
      if (stepIndex === 0) {
        setConditionType(ChosenConditionType.NONE);
      } else {
        goStepBack();
      }
    }
  };

  const setAction = useCallback(() => {
    goToSummary();
  }, [channelId]);

  const headerContent = useMemo(() => {
    switch (stepIndex) {
      case 6:
        return <Header title={t('trigger.actionsSort')} isUnderline />;
      case 4:
        return <Header title={t('trigger.name')} isUnderline />;
      case 3:
        return <Header title={t('trigger.failureMode.label')} isUnderline />;
      case 1:
        return (
          <Header
            title={t('trigger.selectChannels')}
            titleClassName={'m-b-8'}
            subtitle={t('trigger.selectChannelsSubtitle')}
            isUnderline
            column
          />
        );
      case 0:
        switch (conditionType) {
          case ChosenConditionType.STATE: {
            return (
              <Header
                title={t('trigger.selectChannel')}
                titleClassName={'m-b-8'}
                subtitle={t('trigger.selectChannelSubtitle')}
                column
              />
            );
          }
          case ChosenConditionType.PRICE: {
            return <Header title={t('trigger.conditions.time.price')} isUnderline />;
          }
          default: {
            return <Header title={t('trigger.conditions.time.determine')} isUnderline />;
          }
        }
      case 2:
        return triggerId ? <Header title={t('trigger.edit')} isUnderline /> : null;
      case 5:
      default:
        return null;
    }
  }, [conditionType, stepIndex, t]);

  if (conditionType === ChosenConditionType.NONE) {
    return <ConditionList setConditionType={setConditionType} isEdit={isEdit} goToSummary={goToSummary} />;
  }

  if (conditionType === ChosenConditionType.STATE && stepIndex === 0) {
    return (
      <ActiveConditionComponent
        setConditionType={setConditionType}
        goToCondition={goToCondition}
        goToChannels={goToChannels}
        goToSummary={goToSummary}
        conditionType={conditionType}
        goToChannelSetup={goToChannelSetup}
        goToActionsSort={goToActionsSort}
        goToFailureMode={goToFailureMode}
        goToStateConditionSetup={goToStateConditionSetup}
        goToName={goToName}
        channelId={channelId}
        setChannelId={setChannelId}
        isEdit={isEdit}
      />
    );
  }

  return (
    <Page
      header={
        <>
          <NavHeader isIconClose={stepIndex === 6} onClick={onNavHeaderClick} />
          {headerContent}
        </>
      }
    >
      {stepIndex === 5 ? (
        <ChannelSetup channelId={channelId} setAction={setAction} />
      ) : stepIndex === 6 ? (
        <TriggerActionsSort goToSummary={goToSummary} />
      ) : stepIndex === 7 ? (
        <StateCondition
          setConditionType={setConditionType}
          conditionType={conditionType}
          goToSummary={goToSummary}
          channelId={channelId}
          setChannelId={setChannelId}
        />
      ) : (
        <ActiveConditionComponent
          setConditionType={setConditionType}
          goToCondition={goToCondition}
          goToChannels={goToChannels}
          conditionType={conditionType}
          goToSummary={goToSummary}
          goToChannelSetup={goToChannelSetup}
          goToActionsSort={goToActionsSort}
          goToStateConditionSetup={goToStateConditionSetup}
          goToName={goToName}
          goToFailureMode={goToFailureMode}
          channelId={channelId}
          setChannelId={setChannelId}
          isEdit={isEdit}
        />
      )}
    </Page>
  );
};
