import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconRepeat } from '../../../../components';

interface PropsInterface {
  targetExecutionCount?: number | null;
  currentExecutionCount?: number | null;
}

export const TriggerRemainingExecutionCount: React.FC<PropsInterface> = ({
  targetExecutionCount,
  currentExecutionCount,
}) => {
  const { t } = useTranslation('action');

  const isVisible = useMemo(() => {
    return typeof targetExecutionCount === 'number' && typeof currentExecutionCount === 'number';
  }, [targetExecutionCount, currentExecutionCount]);

  const remainingExecutionCount = useMemo(() => {
    if (isVisible) return (targetExecutionCount || 0) - (currentExecutionCount || 0);
  }, [targetExecutionCount, currentExecutionCount]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="trigger-details__execution--remaining">
      <IconRepeat />
      <p>
        {t('trigger.remainingExecutionCount')}: {remainingExecutionCount}
      </p>
    </div>
  );
};
