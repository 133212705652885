import { Dispatch, SetStateAction } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import {
  Invitation,
  Query,
  UserAction,
  UserGroup,
  MeasurementMap,
  LearningScenarios,
  SupplyReturnBehaviorInternal,
  PublishStatus as PublishStatusGraphQL
} from '../data-access/gql-types/graphql';
import { NotificationCallback, NotificationInterface } from './notification';

export type EnvironmentType = {
  ENV: string | undefined;
  PUBLIC_URL: string | undefined;
  VERSION: string | undefined;
  API_URL: string | undefined;
  KEYCLOAK_CHANGE_PASSWORD_URL: string | undefined;
  KEYCLOAK_URL: string | undefined;
  KEYCLOAK_CLIENT_ID: string | undefined;
  KEYCLOAK_REALM: string | undefined;
  WS_URL: string | undefined;
  WSS_URL: string | undefined;
  UPLOAD_PHOTO: string | undefined;
  USER_REPORT_URL: string;
  USER_REPORT_ID: string;
  API_LAVVA_MANAGER: string | undefined;
  API_LAVVA_MANAGEMENT: string | undefined;
  MAPS_API_KEY: string;
  INTEGRATION_DEFAULT: IntegrationDefaultEnum;
  NATIVE_APP_ID: string;
  DISCOVERY_URL: string;
  SENTRY_DSN: string;
  BUILD_ID: string;
  VAPID_KEY: string;
};

export interface ApiStateInterface {
  channelsLoading: boolean;
  devicesLoading: boolean;
  groups: UserGroup[];
  dashboardLoading: boolean;
  notificationList: NotificationInterface[];
  notificationsLoading: boolean;
  favouriteGroup: UserGroup | undefined;
  allInvitationsLoading: boolean;
  allInvitations: Invitation[];
  userActions: UserAction[];
  measurementMap: MeasurementMap | null;
  convertNumberToMeasurement: any;
  convertMeasurementToNumber: any;
  setNotificationList: Dispatch<SetStateAction<NotificationInterface[]>>;
  markAsDisplayed: (ids: string[], callback?: NotificationCallback) => void;
  markAsHidden: (ids: string[], callback?: NotificationCallback) => void;
  invitationAcceptById: (invitationId: string, notificationId: string, onSuccess: () => void) => void;
  invitationRejectById: (invitationId: string, notificationId: string, onSuccess: () => void) => void;
  refetchInvitations?: () => Promise<ApolloQueryResult<Query>>;
}

export enum AvailableLanguage {
  PL = 'pl',
  EN = 'en',
}

export enum StatusTypeEnum {
  NO_INSTALLATION = 'NO_INSTALLATION',
  INTEGRATION = 'INTEGRATION',
  SENT = 'SENT',
  JOIN = 'JOIN',
  WAIT = 'WAIT',
  UPDATE = 'UPDATE',
  SHARE = 'SHARE',
  INTEGRATION_CONTROLLER = 'INTEGRATION_CONTROLLER',
  INSTALLATION_CREATED = 'INSTALLATION_CREATED',
  MIGRATION = 'MIGRATION',
}

export interface FaqOptionsInterface {
  show: boolean;
  header: string;
  content: string;
}

export enum PublishStatus {
  Ok,
  DeviceOrChannelNotFound,
  DeviceDisconnected,
  ContractNotSupported,
}

export const publishStatusMap = {
  [PublishStatusGraphQL.Ok]: PublishStatus.Ok,
  [PublishStatusGraphQL.DeviceOrChannelNotFound]: PublishStatus.DeviceOrChannelNotFound,
  [PublishStatusGraphQL.DeviceDisconnected]: PublishStatus.DeviceDisconnected,
  [PublishStatusGraphQL.ContractNotSupported]: PublishStatus.ContractNotSupported,
}

export const tipKey = {
  0: LearningScenarios.AddCentralAction,
  1: LearningScenarios.AddAction,
  2: LearningScenarios.AddAutomation,
};

export const tipTranslation = {
  0: 'CentralAddTip',
  1: 'ActionAddTip',
  2: 'AutomationAddTip',
};

export enum SupplyReturnBehavior {
  AlwaysOff = 0,
  AlwaysOn = 1,
  KeepState = 2,
}

export const convertedReturnBehavior = {
  [SupplyReturnBehaviorInternal.AlwaysOff]: SupplyReturnBehavior.AlwaysOff,
  [SupplyReturnBehaviorInternal.AlwaysOn]: SupplyReturnBehavior.AlwaysOn,
  [SupplyReturnBehaviorInternal.KeepState]: SupplyReturnBehavior.KeepState,
};

export enum IntegrationDefaultEnum {
  Exalus = 'EXALUS',
  Lavva = 'LAVVA',
  Wisniowski = 'WISNIOWSKI',
}

export enum EnvironmentName {
  Localhost = 'LOCALHOST',
  Dev = 'DEV',
  Stage = 'STAGE',
  Prod = 'PROD',
}
