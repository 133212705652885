import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDevicesAndChannelsContext } from '../../../../../../hooks/devices-and-channels/provider';
import { InputSliderDialog } from '../input-slider-dialog';

export const OptimizerConfigurationTemperatureAutoconsumption: React.FC = () => {
  const { t } = useTranslation('channel-details');
  const { watch } = useFormContext();
  const { optimizerConfigValidation } = useDevicesAndChannelsContext();
  const { tempSelfConsumption, tempMin, tempMax, tempMinEnabled } = watch();

  const minValue = useMemo(() => {
    if (tempMinEnabled) {
      if (optimizerConfigValidation) {
        const configMin = optimizerConfigValidation.tempSelfConsumptionMin.min;
        return tempMin > configMin ? tempMin : configMin;
      }

      return tempMin;
    }

    return optimizerConfigValidation?.tempSelfConsumptionMin.min || 0;
  }, [optimizerConfigValidation, tempMin]);

  const maxValue = useMemo(() => {
    if (optimizerConfigValidation) {
      const configMax = optimizerConfigValidation.tempSelfConsumptionMin.max;
      return tempMax < configMax ? tempMax : configMax;
    }

    return tempMax;
  }, [optimizerConfigValidation, tempMax]);

  return (
    <InputSliderDialog
      propertyName="tempSelfConsumption"
      propertyValue={tempSelfConsumption}
      unit="°C"
      dialogTitle={t('optimizer.configuration.temperature')}
      headerTitle={t('optimizer.configuration.temperatureAutoconsumption')}
      min={minValue}
      max={maxValue}
      maxWidth
      noBottomMargin
      {...(optimizerConfigValidation?.tempSelfConsumptionMin.step
        ? { step: optimizerConfigValidation.tempSelfConsumptionMin.step }
        : {})}
    />
  );
};
