import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import * as uuid from 'uuid';
import { IconChevron } from '../icons';
import { DialogCheckboxes } from './dialog-checkboxes';

export interface CheckboxesOptions<T> {
  label: string;
  value: T;
  checked: boolean;
  disabled?: boolean;
  data?: string;
}

interface PropsInterface<T> {
  options: CheckboxesOptions<T>[];
  multipleLabel: string;
  onSave: (options: CheckboxesOptions<T>[]) => void;
  id?: string;
  name?: string;
  disabled?: boolean;
  className?: string;
  label?: string;
  showDialogLabel?: boolean;
}

export const CheckboxSelect = <T extends string | number | readonly string[] | undefined>({
  options,
  id = uuid.v4(),
  name,
  disabled = false,
  onSave,
  className,
  multipleLabel,
  label,
  showDialogLabel = false,
}: PropsInterface<T>) => {
  const { t } = useTranslation('common');
  const [tempOptions, setTempOptions] = useState<CheckboxesOptions<T>[]>([]);
  const [showSelectDialog, setShowSelectDialog] = useState<boolean>(false);

  const onClick = () => {
    setTempOptions([...options]);
    setShowSelectDialog(true);
  };

  const inputTitle = useMemo(() => {
    const checkedOptions = options.filter((x) => x.checked);
    if (!checkedOptions.length) return t('select');

    if (checkedOptions.length === 1) return checkedOptions[0].label;
    return `${multipleLabel} (${checkedOptions.length})`;
  }, [options, multipleLabel]);

  const handleOnChange = (value: T) => {
    setTempOptions((prev) => {
      const temp = [...prev];
      const index = temp.findIndex((x) => x.value === value);

      if (index !== -1) {
        temp[index].checked = !temp[index].checked;
      }

      return [...temp];
    });
  };

  const handleSave = () => {
    onSave(tempOptions);
    setShowSelectDialog(false);
  };

  return (
    <div className="checkbox-select">
      <div
        className={classNames('input', {
          'input--disabled': disabled,
          [className as string]: className,
        })}
        {...(!disabled ? { onClick } : {})}
      >
        {label && (
          <label className="input__label" htmlFor={id}>
            {label}
          </label>
        )}
        <div className="input__wrapper">
          <input id={id} name={name} type="text" value={''} disabled={disabled} hidden readOnly />
          <span className={classNames('input__input', { clicable: !disabled })}>{inputTitle}</span>
          <div className="input__icon input__icon--unset">
            <IconChevron direction="right" noSpace />
          </div>
        </div>
      </div>

      <DialogCheckboxes
        show={showSelectDialog && !disabled}
        setShow={setShowSelectDialog}
        options={tempOptions}
        onChange={handleOnChange}
        handleSave={handleSave}
        {...(showDialogLabel ? { label } : {})}
      />
    </div>
  );
};
