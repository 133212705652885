import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { useMutation } from '@apollo/client';
import {
  useGateSetDirection,
  useGateSetPosition,
  useGateStepByStep,
  useGateTilt,
  useSetElectricStrikeState,
} from '../../api/modules/gate/gate.hooks';
import {
  GateStateDirectionInternal,
  SetQuickControlMutation,
  SetQuickControlMutationVariables,
} from '../../data-access/gql-types/graphql';
import { SET_QUICK_CONTROL } from '../../data-access/mutations/channels';
import { ChannelInterface } from '../../types';
import { ChannelGateInterface, ChannelGateStateInterface, GateDirection } from '../../types/channel/gate';
import { toastError, toastSuccess } from '../../utils/toast';
import { useChannelErrors } from '../backend-errors/use-channel-errors';
import { useChannelsState } from '../channels-state';
import { useDevicesAndChannelsContext } from '../devices-and-channels/provider';

export const useGate = (channel: ChannelInterface) => {
  const { t: td } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { handleErrors } = useChannelErrors();
  const { setChannelList } = useDevicesAndChannelsContext();
  const setGateDirection = useGateSetDirection();
  const setGatePosition = useGateSetPosition();
  const setGateTilt = useGateTilt();
  const stepByStepMutate = useGateStepByStep();
  const electricStrikeStateMutation = useSetElectricStrikeState();
  const { channelState } = useChannelsState();
  const state = channelState[channel.id] as ChannelGateStateInterface | undefined;
  const [setQuickControl] = useMutation<SetQuickControlMutation, SetQuickControlMutationVariables>(SET_QUICK_CONTROL);

  const gateActive = useMemo(
    () =>
      !!(
        (state?.direction &&
          [GateStateDirectionInternal.Opening, GateStateDirectionInternal.Closing].includes(state.direction)) ||
        state?.movingParams?.predictedTimeInMs ||
        state?.electricStrikeStatus
      ),
    [state],
  );

  const handleAction = (value: string | number) => {
    if (typeof value === 'string') {
      setGateDirection.mutate({
        channelId: channel.id,
        deviceId: channel.deviceId,
        direction: GateDirection[value],
      });
    } else {
      setGatePosition.mutate({ channelId: channel.id, deviceId: channel.deviceId, position: value });
    }
  };

  const handleVentilation = () => {
    setGateTilt.mutate({ channelId: channel.id, deviceId: channel.deviceId });
  };

  const handleStepByStep = () => {
    stepByStepMutate.mutate({ channelId: channel.id, deviceId: channel.deviceId });
  };

  const handleSetElectricStrikeState = () => {
    electricStrikeStateMutation.mutate({
      channelId: channel.id,
      deviceId: channel.deviceId,
      value: !(channelState[channel.id] as ChannelGateStateInterface)?.electricStrikeStatus,
    });
  };

  const handleQuickControl = (value: boolean) => {
    setQuickControl({
      variables: {
        input: {
          channelId: channel.id,
          value,
        },
      },
      onCompleted: (data) => {
        if (data.setQuickControl.result?.ok) {
          setChannelList((prev) => {
            const tempList = cloneDeep(prev);
            const index = tempList.findIndex((x) => x.id === channel.id);

            if (index !== -1) {
              (tempList[index].data as ChannelGateInterface).isQuickControl = value;
            }

            return tempList;
          });

          toastSuccess({ content: td('quickControlSuccess') });
        } else {
          handleErrors(data.setQuickControl.errors || []);
        }
      },
      onError: () => {
        toastError({ content: tc('errors.somethingWentWrong') });
      },
    });
  };

  return {
    gateActive,
    handleAction,
    handleVentilation,
    handleStepByStep,
    handleSetElectricStrikeState,
    handleQuickControl,
  };
};
