import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { DeviceDetailsQueryVariables, Query } from '../../../data-access/gql-types/graphql';
import { DEVICE_DETAILS } from '../../../data-access/queries/devices';
import { useInstallation } from '../../../hooks';
import { ROUTES } from '../../../routes';

export const useGetDeviceDetails = (deviceId: string) => {
  const history = useHistory();
  const { selectedInstallationId, skipLavvaFetch } = useInstallation();
  const { data, loading, refetch } = useQuery<Query, DeviceDetailsQueryVariables>(DEVICE_DETAILS, {
    variables: { installationId: selectedInstallationId, deviceId: deviceId },
    fetchPolicy: 'network-only',
    skip: skipLavvaFetch,
    onCompleted: (data) => {
      if (!data.userDevice) history.replace(ROUTES.Root());
    },
  });

  return {
    data,
    loading,
    refetch,
  };
};
