import React, { ChangeEvent, useState, useEffect, useRef, ReactNode, KeyboardEvent } from 'react';
import classNames from 'classnames';
import * as uuid from 'uuid';
import { IconClose } from '../icons';
import './index.scss';

interface TextAreaProps {
  defaultValue?: string;
  value?: string;
  id?: string;
  disabled?: boolean;
  label?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  children?: ReactNode;
  autoFocus?: boolean;
  onClear?: () => void;
  reverse?: boolean;
  rows?: number;
  maxLength?: number;
}

export const TextArea: React.FC<TextAreaProps> = ({
  id = uuid.v4(),
  defaultValue = '',
  disabled = false,
  label = '',
  onChange,
  placeholder,
  children,
  autoFocus = false,
  onClear,
  onKeyDown,
  reverse,
  rows,
  maxLength,
  value,
}) => {
  const [inputValue, setInputValue] = useState<string>(defaultValue || '');
  const [validate, setValidate] = useState<boolean>(false);
  const ref = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  const onFocus = () => {
    if (!ref.current) return;
  };

  const internalOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValidate(true);
    setInputValue(event.target.value);
    onChange?.(event);
  };

  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  const handleClear = () => {
    setInputValue('');
    onClear?.();
  };

  const onInvalid = () => setValidate(true);

  return (
    <div
      className={classNames('input', {
        'input--disabled': disabled,
        'input--reverse': reverse,
        'input--rows': !!rows,
      })}
    >
      {label && (
        <label htmlFor={id} className={classNames('input__label')}>
          {label}
        </label>
      )}
      <div className={classNames('input__wrapper', { with_clear: onClear })}>
        <textarea
          ref={ref}
          className={classNames('input__input', {
            'input__input--validate': validate,
          })}
          id={id}
          value={value || inputValue}
          disabled={disabled}
          onFocus={onFocus}
          autoComplete="new-password"
          onChange={internalOnChange}
          placeholder={placeholder !== undefined ? placeholder : label}
          onInvalid={onInvalid}
          {...(onKeyDown ? { onKeyDown } : {})}
          {...(rows ? { rows } : {})}
          {...(maxLength ? { maxLength } : {})}
        />
        {onClear && (value ? value : inputValue) && (
          <button className="clear_icon" onClick={handleClear}>
            <IconClose />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};
