import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { orderBy } from 'lodash';
import { IconHistory } from '../../../../components';
import { Trigger } from '../../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../../hooks';
import { isDevelopment } from '../../../../utils/helpers/environment';
import { formatExecution, getCurrentDate, getPlannedExecutionDate } from '../../utils';

interface PropsInterface {
  trigger: Trigger;
}

export const TriggerLastExecution: React.FC<PropsInterface> = ({ trigger }) => {
  const { t } = useTranslation('action');
  const { selectedInstallation } = useInstallation();

  const plannedExecutionAt = useMemo(() => {
    return trigger.executionInfo.map((x) => ({
      date: x.plannedExecutionAt?.date,
      time: x.plannedExecutionAt?.time,
      formatted: formatExecution(x, selectedInstallation?.payload?.timeZone || '').execution,
    }));
  }, [trigger.executionInfo]);

  if (isDevelopment) console.log('PlannedExecutionAt', plannedExecutionAt);

  const lastExecutions = useMemo(() => {
    const current = getCurrentDate();

    return trigger.executionInfo
      .filter((item) => {
        const plannedExecutionDate = getPlannedExecutionDate(item);
        return plannedExecutionDate.getTime() < current.getTime();
      })
      .map((x) => formatExecution(x, selectedInstallation?.payload?.timeZone || ''));
  }, [trigger.executionInfo]);

  return (
    <>
      <div className="trigger-details__execution--last">
        <IconHistory />
        <p>{`${t('trigger.lastExecution')}: ${!lastExecutions.length ? t('trigger.nextFireTimeNull') : ''}`}</p>
      </div>
      {lastExecutions.length
        ? orderBy(lastExecutions, 'date', 'asc').map((x, i) => (
            <div
              className={c('trigger-details__execution--last p-l-24', {
                'trigger-details__execution--last-failed': !x.success,
              })}
              key={i}
            >
              <p>{x.execution}</p>
            </div>
          ))
        : null}
    </>
  );
};
