import { PeriodWeeklyPeriod } from '../../../../../data-access/gql-types/graphql';

export const measurementNameParse = (measurement: string) => {
  return measurement.replace('_DAILY', '').replace('_WEEKLY', '').replace('_MONTHLY', '');
};

export const period1MeasurementByPeriod = {
  [PeriodWeeklyPeriod.Total]: 15,
  [PeriodWeeklyPeriod.Weekly]: 18,
  [PeriodWeeklyPeriod.Monthly]: 21,
  [PeriodWeeklyPeriod.Daily]: 24,
};

export const period2MeasurementByPeriod = {
  [PeriodWeeklyPeriod.Total]: 16,
  [PeriodWeeklyPeriod.Weekly]: 19,
  [PeriodWeeklyPeriod.Monthly]: 22,
  [PeriodWeeklyPeriod.Daily]: 25,
};

export const period3MeasurementByPeriod = {
  [PeriodWeeklyPeriod.Total]: 17,
  [PeriodWeeklyPeriod.Weekly]: 20,
  [PeriodWeeklyPeriod.Monthly]: 23,
  [PeriodWeeklyPeriod.Daily]: 26,
};
