import { gql } from '@apollo/client';

export const CALCULATE_NEXT_TRIGGER_FIRE_TIME = gql`
  query CalculateNextTriggerFireTime($trigger: TriggerTimeConditionRequestInput!, $installationId: UUID!) {
    calculateNextTriggerFireTime(trigger: $trigger, installationId: $installationId) {
      nextFireTime {
        date
        time
      }
    }
  }
`;

export const CALCULATE_FUTURE_FIRE_TIMES = gql`
  query CalculateFutureFireTimes($trigger: TriggerPriceAndTimeConditionRequestInput!, $installationId: UUID!) {
    calculateFutureFireTimes(trigger: $trigger, installationId: $installationId) {
      futureFires {
        date
        time
      }
    }
  }
`;

export const ALL_TRIGGERS = gql`
  query AllTriggers($installationId: UUID!) {
    allTriggers(installationId: $installationId) {
      triggerId
      installationId
      timeCondition {
        atSunrise
        sunriseAdvance
        sunriseDelay
        atSunset
        sunsetAdvance
        sunsetDelay
        timeOfDay
        startAt {
          date
          time
        }
        endAt {
          date
          time
        }
        daysOfWeek
        everyMonth
        everyYear
        currentExecutionCount
        targetExecutionCount
        nextFireTime {
          date
          time
        }
      }
      stateConditions {
        id
        booleanValue
        integerValue
        conditionType
        timeIntervalValue {
          daysOfWeek
          timeFrom
          timeTo
        }
      }
      priceAndTimeCondition {
        priceGreaterThanOrEqual
        priceLesserThanOrEqual
        numberOfCheapestPeriodsInDay
        betweenHoursFromInclusive
        betweenHoursToInclusive
        startAt {
          date
          time
        }
        endAt {
          date
          time
        }
        currentExecutionCount
        targetExecutionCount
        daysOfWeek
        nextFireTime {
          date
          time
        }
      }
      actions {
        actionType
        id
        integerValue
        booleanValue
        lightColorValue {
          r
          g
          b
        }
      }
      onFailureMode
      executionInfo {
        executedAt {
          date
          time
        }
        plannedExecutionAt {
          date
          time
        }
        isSuccessful
        actionsResults {
          actionType
          id
          isSuccessful
        }
      }
      isValid
      isActive
      createdAt {
        date
        time
      }
      name
      validationErrors {
        partType
        errorType
        value
      }
    }
  }
`;

export const TRIGGER_DETAILS = gql`
  query TriggerDetails($id: UUID!, $installationId: UUID!) {
    trigger(id: $id, installationId: $installationId) {
      triggerId
      installationId
      timeCondition {
        atSunrise
        sunriseAdvance
        sunriseDelay
        atSunset
        sunsetAdvance
        sunsetDelay
        timeOfDay
        startAt {
          date
          time
        }
        endAt {
          date
          time
        }
        daysOfWeek
        everyMonth
        everyYear
        currentExecutionCount
        targetExecutionCount
        nextFireTime {
          date
          time
        }
      }
      stateConditions {
        id
        booleanValue
        integerValue
        conditionType
        timeIntervalValue {
          daysOfWeek
          timeFrom
          timeTo
        }
      }
      priceAndTimeCondition {
        priceGreaterThanOrEqual
        priceLesserThanOrEqual
        numberOfCheapestPeriodsInDay
        betweenHoursFromInclusive
        betweenHoursToInclusive
        startAt {
          date
          time
        }
        endAt {
          date
          time
        }
        currentExecutionCount
        targetExecutionCount
        daysOfWeek
        nextFireTime {
          date
          time
        }
      }
      actions {
        actionType
        id
        integerValue
        booleanValue
        lightColorValue {
          r
          g
          b
        }
      }
      onFailureMode
      executionInfo {
        executedAt {
          date
          time
        }
        plannedExecutionAt {
          date
          time
        }
        isSuccessful
        actionsResults {
          actionType
          id
          isSuccessful
        }
      }
      isValid
      isActive
      createdAt {
        date
        time
      }
      name
      validationErrors {
        partType
        errorType
        value
      }
    }
  }
`;
