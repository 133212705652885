import { mean, sum } from 'lodash';
import { averageSummaryMeasurements } from '.';
import {
  OptimizerScalarParameterTypeInternal,
  OptimizerVectorParameterTypeInternal,
  ScalarParameterTypeInternal,
  VectorParameterTypeInternal,
} from '../../../../data-access/gql-types/graphql';
import { MeasurementGroup } from '../../meter/measurements/details';
import { SelectedPhase } from '../types';

export const getMeasurementValue = (
  measurement:
    | VectorParameterTypeInternal
    | ScalarParameterTypeInternal
    | OptimizerVectorParameterTypeInternal
    | OptimizerScalarParameterTypeInternal,
  selectedPhases: SelectedPhase[],
  phase: string,
  phasesValues: number[],
  summaryPhases: SelectedPhase[],
) => {
  const selectedPhasesTemp = selectedPhases.length ? [...selectedPhases] : [{ value: 0, label: '', color: '' }];

  const phaseValuesToCount = selectedPhasesTemp.find((x) => x.value === 0)
    ? phasesValues
    : phase
    ? [phasesValues[parseInt(phase) - 1]]
    : summaryPhases.length
    ? phasesValues.filter((_, index) => selectedPhasesTemp.find((y) => y.value === index + 1))
    : phasesValues;
    
    const summaryOfPhasesValue = averageSummaryMeasurements.includes(measurement as VectorParameterTypeInternal)
    ? mean(phaseValuesToCount)
    : sum(phaseValuesToCount);
    
  return summaryOfPhasesValue;
};

export const getPhaseMeasurements = (measurementGroup: MeasurementGroup, meterMeasurement: any) => {
  return measurementGroup === MeasurementGroup.SinglePhase
    ? meterMeasurement?.onMeasurement?.data?.vectorMeasurements
    : measurementGroup === MeasurementGroup.Absolute
    ? meterMeasurement?.onMeasurement?.data?.scalarMeasurements
    : null;
};
