import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import {
  BulkUpdateDeviceStatus,
  BulkUpdateResult,
  BulkUpdateStatus,
  IUpdatesService,
  UpdateErrorCode,
  UpdateProgressInfo,
  UpdateStatus,
} from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';
import { UpdatesService } from 'lavva.exalushome/build/js/Services/Updates/UpdatesService';
import { useBackdropContext } from '../../../../../hooks';
import { getEnumKeyByValue } from '../../../../../utils/helpers';
import { toastError, toastSuccess } from '../../../../../utils/toast';
import { useUpdateErrors } from '../../controller-settings/hooks/use-update-errors';
import { langCode } from '../../controller-settings/hooks/use-update-process';
import { useDeviceListContext } from '../context';
import { UpdateDeviceResult } from '../types';

export const useDevicesUpdates = (devices: IDevice[]) => {
  const { i18n, t } = useTranslation('device-list');
  const [updateResultDialog, setUpdateResultDialog] = useState<UpdateDeviceResult[]>([]);
  const { showUpdateError } = useUpdateErrors();
  const { turnOnBackdropPopup, turnOffBackdropPopup } = useBackdropContext();
  const { selectedDeviceIds, toggleUpdateMode } = useDeviceListContext();

  const handleUpdateProcess = (updateProgress: UpdateProgressInfo) => {
    turnOnBackdropPopup({
      header: t('updating'),
      message: <p>{t('updateInProgress')}</p>,
      updateContent: {
        progress: updateProgress.Status === UpdateStatus.Upgraded ? 100 : updateProgress.Percentage,
        progressTotal: updateProgress.PercentageTotal,
        status: updateProgress.Status,
        updateNumber: updateProgress.UpdateNumber,
        updatedResourceName: updateProgress.UpdatedResourceName.get(langCode[i18n.language]) || '',
        availableUpdates: [updateProgress.UpdatedResourceName.get(langCode[i18n.language]) || ''],
        handleClose: () => {
          turnOffBackdropPopup();
        },
      },
    });
  };

  const handleUpdateAll = async (onSuccess: () => void) => {
    setUpdateResultDialog([]);
    const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);

    try {
      const result: BulkUpdateResult | ResponseResult<UpdateErrorCode> =
        await updateService.InstallDevicesUpdateBulkAsync(selectedDeviceIds, handleUpdateProcess);

      onSuccess();
      toggleUpdateMode();

      setUpdateResultDialog(
        Object.entries(Object.fromEntries((result as BulkUpdateResult).DevicesUpdateResults)).map(([key, value]) => ({
          name: devices.find((x) => x.Guid === key)?.Name || 'N/A',
          status: t(`exalus.updateErrors.${getEnumKeyByValue(BulkUpdateDeviceStatus, value)}`),
          color: value === BulkUpdateDeviceStatus.Updated ? '#00B191' : '#CC0909',
        })),
      );
      turnOffBackdropPopup();

      switch ((result as BulkUpdateResult).BulkUpdateStatus) {
        case BulkUpdateStatus.Success: {
          toastSuccess({ content: t('exalus.updateErrors.Success') });
          break;
        }
        case BulkUpdateStatus.PartialSuccess: {
          toastSuccess({ content: t('exalus.updateErrors.PartialSuccess') });
          break;
        }
        case BulkUpdateStatus.UpdatesNotFound: {
          toastError({ content: t('exalus.updateErrors.UpdatesNotFound') });
          break;
        }
        case BulkUpdateStatus.CannotPairRetransmitters: {
          toastError({ content: t('exalus.updateErrors.CannotPairRetransmitters') });
          break;
        }
        case BulkUpdateStatus.CannotUnpairRetransmitters: {
          toastError({ content: t('exalus.updateErrors.CannotUnpairRetransmitters') });
          break;
        }
        default: {
          break;
        }
      }
    } catch (error) {
      showUpdateError(error as ResponseResult<UpdateErrorCode>);
    }
  };

  return { updateResultDialog, setUpdateResultDialog, handleUpdateAll, handleUpdateProcess };
};
