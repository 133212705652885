import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useGetTariffs } from '../../../../../api/modules/optimizer/optimizer.hooks';
import {
  ChannelTypeInternal,
  DynamicMarketPricesQueryVariables,
  Query,
  TariffKindInternal,
} from '../../../../../data-access/gql-types/graphql';
import { DYNAMIC_MARKET_PRICES } from '../../../../../data-access/queries/channels';
import { useInstallation } from '../../../../../hooks';
import { ChannelInterface, ChannelMeterInterface } from '../../../../../types';
import { SelectedPhase } from '../../../measurement/types';
import { tariffKind } from '../../../optimizer/configuration/utils/tariff-kind';
import { TariffPricesData } from '../types';

export const useMeterAnalysisPrices = (channel: ChannelInterface, selectedPhases: SelectedPhase[] | undefined) => {
  const [emptyPrices, setEmptyPrices] = useState<boolean>(false);
  const { skipLavvaFetch } = useInstallation();
  const { data: dynamicPrices } = useQuery<Query, DynamicMarketPricesQueryVariables>(DYNAMIC_MARKET_PRICES, {
    skip: skipLavvaFetch,
  });
  const { data: tariffs } = useGetTariffs(ChannelTypeInternal.Meter);
  const kind = (channel?.data as ChannelMeterInterface)?.tariff?.kind;

  const tariffPricesConfiguration = useMemo(() => {
    if (selectedPhases?.find((x) => [1, 2, 3].includes(x.value))) return null;

    const tariffConfigurations =
      (channel?.data as ChannelMeterInterface)?.sharedChannelSettings?.tariffConfigurations || [];
    const priceData = tariffConfigurations.find((x) => x.tariff === kind);

    if (
      kind &&
      priceData &&
      ![
        TariffKindInternal.TauronDynamic,
        TariffKindInternal.EonDynamic,
        TariffKindInternal.PgeDynamic,
        TariffKindInternal.EnergaDynamic,
        TariffKindInternal.EneaDynamic,
      ].includes(kind)
    ) {
      const myTariff = tariffKind[kind];

      const operatorTariff = tariffs?.find((x) => x.operator.toLowerCase() === myTariff.operator);

      if (operatorTariff) {
        if (kind === priceData.tariff) {
          setEmptyPrices(false);

          return {
            prices: priceData.prices.reduce((result, tier) => {
              result[tier.pricingTier] = tier.price;
              return result;
            }, {}),
            hours: operatorTariff.tariffs.find((x) => x.name.toLowerCase() === myTariff.tariff)?.hoursRanges || [],
          } as TariffPricesData;
        } else {
          setEmptyPrices(true);
        }
      }
    }

    return null;
  }, [channel.data, kind, tariffs, selectedPhases]);

  return {
    kind,
    tariffPricesConfiguration,
    dynamicPrices: dynamicPrices?.dynamicMarketPrices || [],
    emptyPrices,
  };
};
