import { useTranslation } from 'react-i18next';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import {
  DeviceTokenMetadata,
  NotificationClientRegistration,
  NotificationsServiceErrorCode,
} from 'lavva.exalushome/build/js/Services/Notifications/INotificationsService';
import { useBackdropContext } from '../../../hooks';
import { toastError } from '../../../utils/toast';
import { useExalusServicesContext } from '../context/services';

export const useExalusNotifications = () => {
  const { t } = useTranslation('notifications');
  const { notificationsApi } = useExalusServicesContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const handleNotificationsError = (data: ResponseResult<NotificationsServiceErrorCode>) => {
    switch ((data as ResponseResult<NotificationsServiceErrorCode>).Type) {
      case NotificationsServiceErrorCode.UnknownError: {
        toastError({ content: t('exalus.error.UnknownError') });
        break;
      }
      case NotificationsServiceErrorCode.NoData: {
        toastError({ content: t('exalus.error.NoData') });
        break;
      }
      case NotificationsServiceErrorCode.IncorrectGuid: {
        toastError({ content: t('exalus.error.IncorrectGuid') });
        break;
      }
      case NotificationsServiceErrorCode.IdentityIsEmpty: {
        toastError({ content: t('exalus.error.IdentityIsEmpty') });
        break;
      }
      case NotificationsServiceErrorCode.DeviceTokenIsEmpty: {
        toastError({ content: t('exalus.error.DeviceTokenIsEmpty') });
        break;
      }
      case NotificationsServiceErrorCode.ClientNameIsEmpty: {
        toastError({ content: t('exalus.error.ClientNameIsEmpty') });
        break;
      }
      case NotificationsServiceErrorCode.LavvaUserNameIsEmpty: {
        toastError({ content: t('exalus.error.LavvaUserNameIsEmpty') });
        break;
      }
      case NotificationsServiceErrorCode.TokenNotFound: {
        toastError({ content: t('exalus.error.TokenNotFound') });
        break;
      }
    }
  };

  const registerNotificationToken = async (registration: NotificationClientRegistration) => {
    turnOnBackdrop();
    const data: string | ResponseResult<NotificationsServiceErrorCode> =
      await notificationsApi.RegisterNotificationsClientAsync(registration);
    turnOffBackdrop();

    if ((data as ResponseResult<NotificationsServiceErrorCode>).Type) {
      handleNotificationsError(data as ResponseResult<NotificationsServiceErrorCode>);
    } else {
      alert('TOKEN REGISTERED');
    }
  };

  const getAllControllerNotificationTokens = async () => {
    const data: DeviceTokenMetadata[] | ResponseResult<NotificationsServiceErrorCode> =
      await notificationsApi.GetRegisteredNotificationsClientsMetadataAsync();

    if ((data as ResponseResult<NotificationsServiceErrorCode>).Type) {
      handleNotificationsError(data as ResponseResult<NotificationsServiceErrorCode>);
      return [];
    } else {
      console.log('NOTIFICATION TOKENS', data);
      return data as DeviceTokenMetadata[];
    }
  };

  const updateNotificationToken = async (tokenGuid: string, registration: NotificationClientRegistration) => {
    turnOnBackdrop();
    const data: Status.OK | ResponseResult<NotificationsServiceErrorCode> =
      await notificationsApi.UpdateNotificationsClientAsync(tokenGuid, registration);
    turnOffBackdrop();

    if ((data as ResponseResult<NotificationsServiceErrorCode>).Type) {
      handleNotificationsError(data as ResponseResult<NotificationsServiceErrorCode>);
    } else {
      alert('TOKEN UPDATED');
    }
  };

  const getNotificationTokenState = async (tokenIdentify: string) => {
    const data: boolean | ResponseResult<NotificationsServiceErrorCode> =
      await notificationsApi.IsNotifiacationsEnabledAsync(tokenIdentify);

    if ((data as ResponseResult<NotificationsServiceErrorCode>).Type) {
      handleNotificationsError(data as ResponseResult<NotificationsServiceErrorCode>);
    } else {
      console.log('NOTIFICATION TOKEN STATE', data);
    }
  };

  const enableNotifications = async (tokenIdentify?: string) => {
    turnOnBackdrop();
    const data: Status.OK | ResponseResult<NotificationsServiceErrorCode> =
      await notificationsApi.EnableNotificationsAsync(tokenIdentify);
    turnOffBackdrop();

    if ((data as ResponseResult<NotificationsServiceErrorCode>).Type) {
      handleNotificationsError(data as ResponseResult<NotificationsServiceErrorCode>);
    } else {
      alert('NOTIFICATIONS ENABLED');
    }
  };

  const disableNotifications = async (tokenIdentify?: string) => {
    turnOnBackdrop();
    const data: Status.OK | ResponseResult<NotificationsServiceErrorCode> =
      await notificationsApi.DisableNotificationsAsync(tokenIdentify);
    turnOffBackdrop();

    if ((data as ResponseResult<NotificationsServiceErrorCode>).Type) {
      handleNotificationsError(data as ResponseResult<NotificationsServiceErrorCode>);
    } else {
      alert('NOTIFICATIONS DISABLED');
    }
  };

  return {
    registerNotificationToken,
    getAllControllerNotificationTokens,
    updateNotificationToken,
    getNotificationTokenState,
    enableNotifications,
    disableNotifications,
  };
};
