import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { NativeContext as NtxContext } from 'lavva.webview/build/js/Services/Common/ICommonService';
import { useAndroidPermissions } from '../../../hooks/android-permissions';
import { useNativeFunctionsContext } from '../../../hooks/native/native-functions';
import { toastInfo } from '../../../utils/toast';
import { useLocationContext } from '../components/location/context/location.context';
import { LocationCoordsHook, LocationViews } from '../types';

export const useLocationCoords = (setLoader: Dispatch<SetStateAction<boolean>>): LocationCoordsHook => {
  const { t } = useTranslation('installation');
  const { setCoords, setCurrentLocation, setCenter, setZoom, goTo, currPage } = useLocationContext();
  const { getNativeContext } = useNativeFunctionsContext();
  const { getAndroidLocationPermission, requestLocationPermission } = useAndroidPermissions();

  const getCoords = async () => {
    setLoader(true);
    let canGetLocation = false;
    const nativeCtx = await getNativeContext();

    if (nativeCtx === NtxContext.AndroidGecko) {
      const permission = await getAndroidLocationPermission();
      if (permission) canGetLocation = true;
      else {
        const resultPermission = await requestLocationPermission();
        if (resultPermission) canGetLocation = true;
      }
    } else {
      canGetLocation = true;
    }

    if (canGetLocation) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            const crd: GeolocationCoordinates = pos.coords;
            setCurrentLocation({ latitude: crd.latitude, longitude: crd.longitude });
            setCenter({ lat: crd.latitude, lng: crd.longitude });
            setZoom(16);
            setLoader(false);

            if (currPage === LocationViews.ADD_LOCATION) {
              setCoords({ latitude: crd.latitude, longitude: crd.longitude });
              goTo(LocationViews.MAP);
            }
          },
          (error: GeolocationPositionError) => {
            setLoader(false);

            switch (error.code) {
              case error.PERMISSION_DENIED:
                toastInfo({ content: t('locationNeedPermissions') });
                break;
              case error.POSITION_UNAVAILABLE:
                toastInfo({ content: t('locationUnavailable') });
                break;
              case error.TIMEOUT:
                toastInfo({ content: t('locationTimeout') });
                break;
              default:
                toastInfo({ content: t('locationUnknownError') });
                break;
            }
          },
        );
      } else {
        setLoader(false);
        toastInfo({ content: t('locationUnavailable') });
      }
    } else {
      setLoader(false);
      toastInfo({ content: t('locationNeedPermissions') });
    }
  };

  return {
    getCoords,
  };
};
