import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { DialogConfirmation, DialogSelect, IconEye, Input, SelectOptionInterface } from '../../../../../components';
import { IconSearch } from '../../../../../components/icons/icon-search';

interface ComponentProps {
  name: string;
  label: string;
  placeholder?: string;
  inputType?: string;
  readOnly?: boolean;
  wifiList?: SelectOptionInterface<string>[];
  wifiLoading?: boolean;
  disabled?: boolean;
  onSearchClick?: () => void;
  onClickInput?: () => void;
  handleGetWifiList?: () => void;
  removeWifi?: (ssid: string) => void;
}

const ConfigurationInput: React.FC<ComponentProps> = ({
  name,
  label,
  placeholder,
  inputType,
  readOnly,
  wifiList,
  disabled,
  wifiLoading,
  removeWifi,
  handleGetWifiList,
}) => {
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const { control, watch, setValue } = useFormContext();
  const [type, setType] = useState<string>('text');
  const [showSelectDialog, setShowSelectDialog] = useState<boolean>(false);
  const [knowConfirmationPopup, setKnowConfirmationPopup] = useState<boolean>(false);
  const [tempSsid, setTempSsid] = useState<string>('');

  useEffect(() => {
    if (inputType) setType(inputType);
  }, [inputType]);

  const toggleEye = useCallback(() => setType(type === 'password' ? 'text' : 'password'), [type]);
  const toggleSsid = useCallback(() => {
    setShowSelectDialog(true);
  }, []);

  const dialogOnChange = useCallback(
    (value: string) => {
      const wifiItem = wifiList?.find((x) => x.value === value);

      if (wifiItem) {
        if (!wifiItem.weight) {
          setShowSelectDialog(false);
          setValue('ssid', wifiItem.value);
        } else {
          setKnowConfirmationPopup(true);
          setTempSsid(wifiItem.value);
        }
      }
    },
    [wifiList],
  );

  const handleClickInput = () => {
    if (name === 'ssid') {
      setShowSelectDialog(true);
      handleGetWifiList?.();
    }
  };

  const handleConfirmSsidChoice = () => {
    setKnowConfirmationPopup(false);
    setTempSsid('');
    setShowSelectDialog(false);
    setValue('ssid', tempSsid);
  };

  const handleForgetSsid = () => {
    setKnowConfirmationPopup(false);

    if (removeWifi) {
      removeWifi(tempSsid);
      setTempSsid('');
    }
  };

  const { ssid } = watch();

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required: tc('isRequired') as string,
        }}
        render={({ field, fieldState: { error } }) => (
          <Input
            label={label}
            name={field.name}
            onChange={field.onChange}
            value={field.value}
            defaultValue={field.value}
            {...(name === 'ssid' ? { onClickInput: handleClickInput } : {})}
            inputType={type}
            disabled={disabled}
            {...(inputType === 'password'
              ? {
                  endIcon: (
                    <IconButton onClick={toggleEye}>
                      <IconEye hideCircle={type === 'password'} />
                    </IconButton>
                  ),
                }
              : {})}
            {...(name === 'ssid'
              ? {
                  endIcon: (
                    <IconButton onClick={toggleSsid}>
                      <IconSearch />
                    </IconButton>
                  ),
                }
              : {})}
            {...(placeholder ? { placeholder } : {})}
            {...(readOnly ? { readOnly } : {})}
            {...(error ? { errorMessage: error.message } : {})}
          />
        )}
      />
      <DialogSelect
        show={showSelectDialog}
        setShow={setShowSelectDialog}
        title={label}
        options={wifiList || []}
        value={ssid}
        onChange={dialogOnChange}
        loading={wifiLoading}
      />
      <DialogConfirmation
        show={knowConfirmationPopup}
        setShow={setKnowConfirmationPopup}
        header={`${t('bluetooth.ssid')}: ${tempSsid}`}
        secondaryBtnText={tc('buttons.forget')}
        primaryBtnText={tc('buttons.select')}
        secondaryBtnAction={handleForgetSsid}
        primaryBtnAction={handleConfirmSsidChoice}
      />
    </>
  );
};

export default ConfigurationInput;
