import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import {
  DeviceConnectionState,
  GateCalibrateStatusChangePayload,
  GateCalibrateStatusTypePayload,
  GateConfigurationChangePayload,
  GateElectricStrikeStatusChangedPayload,
  GatePositionChangePayload,
} from '../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../hooks';
import { useDevicesAndChannelsContext } from '../../../../hooks/devices-and-channels/provider';
import { ChannelGateInterface, ChannelGateStateInterface } from '../../../../types';
import { toastError, toastSuccess } from '../../../toast';

export const useLavvaDeviceGateUpdate = () => {
  const { t } = useTranslation('channel-settings');
  const { t: tr } = useTranslation('backend-validation');
  const { updateChannelDetails, channelList, setChannelList } = useDevicesAndChannelsContext();
  const { setChannelState } = useChannelsState();

  // const setGateAnimation = (channelId: string, targetPosition: number, position: number, predictedTimeInMs: number) => {
  //   const range = Math.abs(targetPosition - position);

  //   intervalRef.current = setInterval(() => {
  //     setChannelState((oldState) => {
  //       const newState = { ...oldState };
  //       const prevVal = (newState[channelId] as ChannelGateStateInterface).position || 0;
  //       const nextValue = targetPosition === prevVal ? prevVal : targetPosition < prevVal ? prevVal - 1 : prevVal + 1;

  //       if ((nextValue > 0 || nextValue < 100) && nextValue !== prevVal) {
  //         (newState[channelId] as ChannelGateStateInterface).position = nextValue;
  //       }

  //       if (nextValue === targetPosition) {
  //         clearAnimationIfExists();
  //       }

  //       return newState;
  //     });
  //   }, predictedTimeInMs / range);
  // };

  const updateLavvaGatePosition = async ({
    channelId,
    position,
    direction,
    predictedTimeInMs,
    targetPosition,
  }: GatePositionChangePayload) => {
    // clearAnimationIfExists();

    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        position: position,
        direction: direction,
        movingParams: {
          predictedTimeInMs: predictedTimeInMs != null ? predictedTimeInMs : null,
          targetPosition: targetPosition != null ? targetPosition : null,
        },
        deviceConnectionState: DeviceConnectionState.Connected,
      } as ChannelGateStateInterface;
      return newState;
    });

    // if (predictedTimeInMs && targetPosition != null) {
    //   setGateAnimation(channelId, targetPosition, position, predictedTimeInMs);
    // } else {
    //   clearAnimationIfExists();
    // }
  };

  const updateLavvaGateElectricalStrike = async ({
    channelId,
    value,
  }: Pick<GateElectricStrikeStatusChangedPayload, 'channelId' | 'value'>) => {
    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        electricStrikeStatus: value,
        deviceConnectionState: DeviceConnectionState.Connected,
      } as ChannelGateStateInterface;
      return newState;
    });
  };

  const updateLavvaGateConfiguration = async ({
    channelId,
    supportedGateFeatures,
  }: Pick<GateConfigurationChangePayload, 'channelId' | 'supportedGateFeatures'>) => {
    setChannelList((prev) => {
      const tempList = cloneDeep(prev);
      const index = tempList.findIndex((x) => x.id === channelId);

      if (index !== -1) {
        (tempList[index].data as ChannelGateInterface).supportedGateFeatures = supportedGateFeatures;
      }

      return tempList;
    });

    updateChannelDetails((prev) => {
      const temp = cloneDeep(prev);
      if (temp) (temp.data as ChannelGateInterface).supportedGateFeatures = supportedGateFeatures;

      return temp;
    });
  };

  const onGateCalibrateStatusChange = ({
    channelId,
    progress,
    openingMilliseconds,
    closingMilliseconds,
    status,
    error,
  }: GateCalibrateStatusChangePayload) => {
    if (error) {
      toastError({ content: tr(error) });

      setChannelState((oldState) => {
        const newState = { ...oldState };
        const calibrateState = (newState[channelId] as ChannelGateStateInterface)?.calibrateState;
        newState[channelId] = {
          ...(newState[channelId] as ChannelGateStateInterface),
          calibrateState: {
            openingMilliseconds: calibrateState?.openingMilliseconds || 0,
            closingMilliseconds: calibrateState?.closingMilliseconds || 0,
            error: null,
            progress: null,
            started: calibrateState?.started,
          },
        };

        return newState;
      });
    } else {
      setChannelState((oldState) => {
        const newState = { ...oldState };
        newState[channelId] = {
          ...(newState[channelId] as ChannelGateStateInterface),
          calibrateState: {
            openingMilliseconds: openingMilliseconds || 0,
            closingMilliseconds: closingMilliseconds || 0,
            error: !error ? (newState[channelId] as ChannelGateStateInterface)?.calibrateState?.error : error,
            progress,
            started: !!progress,
          },
        };

        return newState;
      });

      const channelAlias = channelList.find((channel) => channel.id === channelId)?.alias;

      if (status === GateCalibrateStatusTypePayload.Data) {
        toastSuccess({
          content: `${t('toast.calibrationSuccess', { channel: channelAlias })}`,
        });
      }
    }
  };

  return {
    updateLavvaGatePosition,
    updateLavvaGateConfiguration,
    onGateCalibrateStatusChange,
    updateLavvaGateElectricalStrike,
  };
};
