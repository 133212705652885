import React, { useMemo } from 'react';
import { Trigger } from '../../../data-access/gql-types/graphql';
import { ChannelInterface, TriggerEvent } from '../../../types';
import { ChannelIcon } from '../../channel';
import { IconMore, IconTime, IconArrowLink, IconState } from '../../icons';
import { TriggerEventItem } from './components/trigger-event-item';
import './index.scss';

interface PropsInterface {
  channels: ChannelInterface[];
  trigger: Trigger;
}

export const EventList: React.FC<PropsInterface> = ({ channels, trigger }) => {
  const mappedArray = useMemo(() => channels.filter((channel) => channel.id), [channels.length]);

  const items = useMemo(
    () => [
      ...(trigger.priceAndTimeCondition
        ? [{ icon: <IconTime size={24} />, priceCondition: trigger.priceAndTimeCondition }]
        : []),
      ...(trigger.timeCondition ? [{ icon: <IconTime size={24} />, timeCondition: trigger.timeCondition }] : []),
      ...(trigger.stateConditions.length ? [{ icon: <IconState /> }] : []),
      {
        icon: <IconArrowLink />,
      },
      ...mappedArray.map((channel) => ({
        icon: <ChannelIcon channel={channel} statusOn />,
      })),
    ],
    [mappedArray],
  );

  const showMoreIcon = useMemo(() => items.length > 4, items);
  const events: TriggerEvent[] = useMemo(() => (showMoreIcon ? items.splice(0, 4) : items), items);

  return (
    <div className="trigger-event-list">
      {events.map((item, i) => (
        <TriggerEventItem key={i} event={item} />
      ))}
      {showMoreIcon ? (
        <div className="trigger-event-list__item trigger-event-list__item--background">
          <div className="trigger-event-list__item-icon">
            <IconMore shape="square" />
          </div>
        </div>
      ) : null}
    </div>
  );
};
