import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';
import { maxBy, meanBy, minBy, sumBy, groupBy } from 'lodash';
import { orderBy } from 'lodash';
import { Area, AreaChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useSubscription } from '@apollo/client';
import { ControlWrapper, IconError, IconSchedule, NavHeader, Page } from '../../../../../components';
import { IconPlug } from '../../../../../components/icons-group';
import { PHASES_COLORS } from '../../../../../const';
import {
  MeasurementChannelKind,
  ScalarParameterTypeInternal,
  VectorParameterTypeInternal,
  OptimizerScalarParameterTypeInternal,
  OptimizerVectorParameterTypeInternal,
  ChannelTypeInternal,
  MeterTypeInternal,
} from '../../../../../data-access/gql-types/graphql';
import { ON_MEASUREMENT } from '../../../../../data-access/subscriptions/on-measurement';
import { useApi, useDevicesAndChannels, useInstallation } from '../../../../../hooks';
import { AvailableLanguage, ChannelInterface } from '../../../../../types';
import { convertDateToFormat } from '../../../../../utils/helpers';
import { getParameterByName } from '../../../../../utils/location';
import { PhasesButtonGroup } from '../../../measurement/components/phases-button-group';
import { SelectedPhase } from '../../../measurement/types';
import {
  averageSummaryMeasurements,
  measurementParameters,
  measurementParametersOptimizer,
} from '../../../measurement/utils';
import { getMeasurementValue, getPhaseMeasurements } from '../../../measurement/utils/measurement-details';
import { CurrentValue } from './current-value';
import { DownloadMeasurementsData } from './download-data';
import { LiveTable } from './table';
import { LiveTooltip } from './tooltip';

const strokeLineColor = '#9A9BA2';

export interface MeasurementDetailItem {
  value: number;
  timestamp: number;
  date: string;
  phasesValues?: number[];
}

export enum MeasurementGroup {
  SinglePhase = 'SinglePhase',
  Absolute = 'Absolute',
}

const MeasurementDetails: React.FC = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation('channel-details');
  const { measurementMap, convertMeasurementToNumber } = useApi();
  const { skipLavvaFetch } = useInstallation();
  const { channelId, measurementGroup, measurement, kind } = useParams<{
    channelId: string;
    kind: MeasurementChannelKind;
    measurementGroup: MeasurementGroup;
    measurement:
      | VectorParameterTypeInternal
      | ScalarParameterTypeInternal
      | OptimizerVectorParameterTypeInternal
      | OptimizerScalarParameterTypeInternal;
  }>();
  const phase = getParameterByName('phase');
  const [selectedPhases, setSelectedPhases] = useState<SelectedPhase[]>(
    phase
      ? [{ value: parseInt(phase), label: `${t('phase')} ${parseInt(phase)}`, color: PHASES_COLORS[parseInt(phase)] }]
      : [],
  );
  const [phaseTabs, setPhaseTabs] = useState<number[]>([]);
  const { channelList } = useDevicesAndChannels({ channelId });
  const [chartData, setChartData] = useState<MeasurementDetailItem[]>([]);
  const [currentValue, setCurrentValue] = useState<number | null>(null);
  const shouldBeAbs =
    (kind === MeasurementChannelKind.Meter && measurement === VectorParameterTypeInternal.ActivePower) ||
    (kind === MeasurementChannelKind.Optimizer && measurement === OptimizerVectorParameterTypeInternal.Current) ||
    (kind === MeasurementChannelKind.Optimizer && measurement === OptimizerVectorParameterTypeInternal.ActivePower);

  const channel = useMemo(() => {
    return channelList.find((channel: ChannelInterface) => channel && channel?.id === channelId) as ChannelInterface;
  }, [channelId, channelList]);

  const parameters =
    kind === MeasurementChannelKind.Meter
      ? measurementParameters[measurement]
      : measurementParametersOptimizer[measurement];

  const { data: meterMeasurement } = useSubscription(ON_MEASUREMENT, {
    variables: {
      input: {
        channelId,
        kind,
        scalarParameterTypes: [
          ...(measurementGroup === MeasurementGroup.Absolute
            ? [convertMeasurementToNumber(kind)('scalars', measurement)]
            : []),
        ],
        vectorParameterTypes: [
          ...(measurementGroup === MeasurementGroup.SinglePhase
            ? [convertMeasurementToNumber(kind)('vectors', measurement)]
            : []),
        ],
        indices: [],
      },
    },
    skip: !measurementMap || skipLavvaFetch,
  });

  const convertValue = (value: number) => {
    if (kind === MeasurementChannelKind.Optimizer && measurement === OptimizerVectorParameterTypeInternal.ActivePower) {
      return value / 1000;
    }

    return value;
  };

  useEffect(() => {
    if (meterMeasurement?.onMeasurement?.data) {
      if (meterMeasurement.onMeasurement.data.unixTimestamp) {
        const phaseMeasurements = getPhaseMeasurements(measurementGroup, meterMeasurement);
        const phasesValues: number[] = phaseMeasurements?.map((phaseMeasurement) => phaseMeasurement.value);
        const summaryPhases = [{ value: 0 }] as SelectedPhase[];

        const summaryOfPhasesValue = getMeasurementValue(
          measurement,
          selectedPhases,
          phase,
          phasesValues,
          summaryPhases,
        );
        const phaseTabs = Object.keys(groupBy(phaseMeasurements, 'index')).map((phase) => Number(phase));
        setPhaseTabs(phaseTabs);

        if (summaryOfPhasesValue !== undefined) {
          setCurrentValue(+summaryOfPhasesValue.toFixed(parameters.fixed));

          setChartData((prev) => {
            return [
              ...prev,
              {
                value: shouldBeAbs
                  ? Math.abs(+convertValue(summaryOfPhasesValue).toFixed(parameters.fixed))
                  : +convertValue(summaryOfPhasesValue).toFixed(parameters.fixed),
                timestamp: meterMeasurement?.onMeasurement?.data?.unixTimestamp,
                date: format(fromUnixTime(meterMeasurement?.onMeasurement?.data?.unixTimestamp), 'HH:mm:ss'),
                ...(measurementGroup === MeasurementGroup.SinglePhase && !phase
                  ? {
                      phasesValues: phaseMeasurements?.map(
                        (phaseMeasurement) => +convertValue(phaseMeasurement.value).toFixed(parameters.fixed),
                      ),
                    }
                  : {}),
              },
            ];
          });
        }
      }
    }
  }, [meterMeasurement?.onMeasurement]);

  const isSummary = useMemo(() => {
    return !selectedPhases.length || (selectedPhases.length === 1 && selectedPhases[0].value === 0);
  }, [selectedPhases]);

  const chartDataBySelectedPhases: MeasurementDetailItem[] = useMemo(
    () =>
      chartData.map((chartDataItem) => {
        if (chartDataItem.phasesValues) {
          const values = chartDataItem.phasesValues
            ?.map((phaseValue, index) => ({
              phase: index + 1,
              value: shouldBeAbs ? Math.abs(phaseValue) : phaseValue,
            }))
            .filter((measurement) => {
              return !!selectedPhases.find((phase) => phase.value === measurement.phase);
            });

          return {
            ...chartDataItem,
            value: averageSummaryMeasurements.includes(measurement as VectorParameterTypeInternal)
              ? +meanBy(values, 'value').toFixed(parameters.fixed)
              : +sumBy(values, 'value').toFixed(parameters.fixed),
          };
        }

        return {
          ...chartDataItem,
        };
      }),
    [chartData, selectedPhases, shouldBeAbs],
  );

  const iconValue = useMemo(() => {
    if (measurement === OptimizerVectorParameterTypeInternal.RelayState) return 'R';
    return parameters.shortcut ?? parameters.symbol ? parameters.symbol : parameters.unit;
  }, [parameters]);

  const iconFontSize = useMemo(() => {
    switch (iconValue.length) {
      case 1:
      case 2:
        return 20;
      case 3:
        return 16;
      case 4:
        return 14;
      default:
        return 12;
    }
  }, [iconValue]);

  const itemPrefix = useMemo(() => {
    if (channel?.data?.type === ChannelTypeInternal.Optimizer) return t('phase');
    return !(
      channel?.data?.type === ChannelTypeInternal.Meter && channel.data.meterType === MeterTypeInternal.Standalone
    )
      ? t('outputShort')
      : t('phase');
  }, [channel]);

  const averageValueByPhases = useMemo(() => {
    let mean = meanBy(!isSummary ? chartDataBySelectedPhases : chartData, 'value');
    if (
      mean &&
      kind === MeasurementChannelKind.Optimizer &&
      measurement === OptimizerVectorParameterTypeInternal.ActivePower
    ) {
      mean = mean / 1000;
    }

    return shouldBeAbs ? Math.abs(mean).toFixed(parameters.fixed) : mean.toFixed(parameters.fixed);
  }, [selectedPhases, chartData, shouldBeAbs, kind, measurement]);

  const maxValueByPhases = useMemo(() => {
    let max = maxBy(!isSummary ? chartDataBySelectedPhases : chartData, 'value')?.value;
    if (
      max &&
      kind === MeasurementChannelKind.Optimizer &&
      measurement === OptimizerVectorParameterTypeInternal.ActivePower
    ) {
      max = max / 1000;
    }

    if (max !== undefined) return shouldBeAbs ? Math.abs(max).toFixed(parameters.fixed) : max.toFixed(parameters.fixed);
  }, [selectedPhases, chartData, shouldBeAbs, kind, measurement]);

  const minValueByPhases = useMemo(() => {
    let min = minBy(!isSummary ? chartDataBySelectedPhases : chartData, 'value')?.value;
    if (
      min &&
      kind === MeasurementChannelKind.Optimizer &&
      measurement === OptimizerVectorParameterTypeInternal.ActivePower
    ) {
      min = min / 1000;
    }

    if (min !== undefined) return shouldBeAbs ? Math.abs(min).toFixed(parameters.fixed) : min.toFixed(parameters.fixed);
  }, [selectedPhases, chartData, shouldBeAbs, kind, measurement]);

  const error = useMemo(() => meterMeasurement?.onMeasurement?.data.errorCode, [meterMeasurement?.onMeasurement]);

  const currentStateValue = useMemo(() => {
    if (currentValue === null) return '-';
    if (measurement === OptimizerVectorParameterTypeInternal.RelayState) return <IconPlug isOn={!!currentValue} />;
    if (kind === MeasurementChannelKind.Optimizer && measurement === OptimizerVectorParameterTypeInternal.ActivePower) {
      return shouldBeAbs
        ? Math.abs(currentValue / 1000).toFixed(parameters.fixed)
        : (currentValue / 1000).toFixed(parameters.fixed);
    } else {
      return shouldBeAbs ? Math.abs(currentValue).toFixed(parameters.fixed) : currentValue.toFixed(parameters.fixed);
    }
  }, [kind, measurement, currentValue, shouldBeAbs, parameters]);

  const handleSelectPhases = (options: SelectedPhase[]) => {
    setSelectedPhases(options);

    if (meterMeasurement?.onMeasurement?.data) {
      if (meterMeasurement.onMeasurement.data.unixTimestamp) {
        const summaryPhases = [{ value: 0 }] as SelectedPhase[];
        const phaseMeasurements = getPhaseMeasurements(measurementGroup, meterMeasurement);
        const phasesValues: number[] = phaseMeasurements?.map((phaseMeasurement) => phaseMeasurement.value);

        const currentValue = getMeasurementValue(measurement, options, phase, phasesValues, summaryPhases);

        if (currentValue) {
          setCurrentValue(+currentValue.toFixed(parameters.fixed));
        }
      }
    }
  };

  const showMultiPhasesAreas = !phase && measurementGroup === MeasurementGroup.SinglePhase && !isSummary;
  const showLineChart =
    showMultiPhasesAreas && averageSummaryMeasurements.includes(measurement as VectorParameterTypeInternal);

  return (
    <Page className="measurements-details" header={<NavHeader />}>
      <div className="details-header">
        <div className="details-header--left">
          <div className="details-header__icon details-header__icon--text" style={{ fontSize: iconFontSize }}>
            {iconValue}
          </div>
          <div className="details-header__name">
            <h2 className="text-ellipsis">
              {phase ? `${t('phase')} ${phase}` : t('sumOfPhases')} -{' '}
              {t(
                `energyMeasurements.types.${
                  measurementGroup === MeasurementGroup.SinglePhase ? 'phase' : 'summary'
                }.${measurement}`,
              )}
            </h2>
            <div className="details-header__type">{channel?.alias}</div>
          </div>
        </div>
      </div>
      <div className="details-page ">
        <ControlWrapper className="control-wrapper--column  control-wrapper--reverse p-l-24 p-r-24 p-b-16 p-t-16 m-b-8">
          {error && <IconError />}
          <div className="date-picker">
            <div className="date-picker__date">
              <h1 className="date-picker__date-value">
                {convertDateToFormat(new Date(), 'eeee, dd.MM.yyyy', i18n.language as AvailableLanguage)}
              </h1>
            </div>
          </div>
          <span className="tooltip__text tooltip__label m-b-16">{t('online')}</span>
          <div className="chart">
            <ResponsiveContainer width="100%" height={220}>
              {showLineChart ? (
                <LineChart data={chartData} margin={{ top: 8, right: 4, left: -20, bottom: 0 }}>
                  <CartesianGrid vertical={false} stroke={strokeLineColor} strokeOpacity={0.2} />
                  <XAxis
                    dataKey="timestamp"
                    tickMargin={8}
                    axisLine={false}
                    interval={'preserveStartEnd'}
                    tickLine={{ stroke: 'transparent' }}
                    tickFormatter={(value) => format(fromUnixTime(value), 'HH:mm')}
                  />
                  <YAxis
                    axisLine={{ strokeWidth: '0' }}
                    tickCount={6}
                    tickMargin={6}
                    tickLine={{ stroke: strokeLineColor, strokeWidth: '0.5px', strokeOpacity: '0.2' }}
                    domain={['auto', 'auto']}
                    dataKey={'phasesValues'}
                  />
                  <Tooltip
                    content={(props) => (
                      <LiveTooltip tooltipProps={props} unit={parameters.unit} shouldBeAbs={shouldBeAbs} />
                    )}
                    animationDuration={500}
                  />
                  {orderBy(selectedPhases, 'value', 'asc').map((selectedPhase) => {
                    return (
                      <Line
                        key={selectedPhase.value}
                        type="monotone"
                        dataKey={`phasesValues.${selectedPhase.value - 1}`}
                        stroke={PHASES_COLORS[selectedPhase.value]}
                        strokeWidth={2}
                        animationDuration={800}
                        animationEasing={'ease-in-out'}
                      />
                    );
                  })}
                </LineChart>
              ) : (
                <AreaChart data={chartData} margin={{ top: 8, right: 4, left: -20, bottom: 0 }}>
                  <CartesianGrid vertical={false} stroke={strokeLineColor} strokeOpacity={0.2} />
                  <XAxis
                    dataKey="timestamp"
                    tickMargin={8}
                    axisLine={false}
                    interval={'preserveStartEnd'}
                    tickLine={{ stroke: 'transparent' }}
                    tickFormatter={(value) => format(fromUnixTime(value), 'HH:mm')}
                  />
                  <YAxis
                    axisLine={{ strokeWidth: '0' }}
                    tickCount={6}
                    tickMargin={6}
                    tickLine={{ stroke: strokeLineColor, strokeWidth: '0.5px', strokeOpacity: '0.2' }}
                    {...(averageSummaryMeasurements.includes(measurement as VectorParameterTypeInternal)
                      ? { domain: ['auto', 'auto'] }
                      : {})}
                  />
                  <Tooltip
                    content={(props) => (
                      <LiveTooltip tooltipProps={props} unit={parameters.unit} shouldBeAbs={shouldBeAbs} />
                    )}
                    animationDuration={500}
                  />
                  {showMultiPhasesAreas ? (
                    <>
                      {orderBy(selectedPhases, 'value', 'asc').map((selectedPhase) => {
                        return (
                          <Area
                            key={selectedPhase.value}
                            dot={{
                              stroke: '#FFFFFF',
                              strokeWidth: 1.5,
                              fill: PHASES_COLORS[selectedPhase.value],
                              z: 2,
                            }}
                            type="monotone"
                            dataKey={`phasesValues.${selectedPhase.value - 1}`}
                            strokeWidth={2}
                            stroke={PHASES_COLORS[selectedPhase.value]}
                            fillOpacity={0.5}
                            fill={selectedPhase.color}
                            animationDuration={800}
                            animationEasing={'ease-in-out'}
                            stackId={selectedPhase.value - 1}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <Area
                      dot
                      type="monotone"
                      dataKey="value"
                      strokeWidth={2}
                      stroke={PHASES_COLORS[0]}
                      fillOpacity={1}
                      fill="#FFC6B9"
                      animationDuration={800}
                      animationEasing={'ease-in-out'}
                    />
                  )}
                </AreaChart>
              )}
            </ResponsiveContainer>
          </div>
          <CurrentValue unit={parameters.unit} currentValue={currentStateValue} />
          {!phase && measurementGroup === MeasurementGroup.SinglePhase ? (
            <PhasesButtonGroup
              onSelectedPhases={handleSelectPhases}
              defaultPhases={[0, ...(phaseTabs || [1, 2, 3])]}
              itemPrefix={itemPrefix}
            />
          ) : null}
        </ControlWrapper>
        {measurement !== OptimizerVectorParameterTypeInternal.RelayState && (
          <ControlWrapper className="control-wrapper--column  control-wrapper--height-auto control-wrapper--stretch p-l-0 p-r-0 p-b-16 p-t-16 m-b-8">
            <div className="summary max-width-desktop">
              <div className="summary__caption p-t-16 p-b-16">
                <IconSchedule />
                <span className="summary__caption-text">{t('energyMeasurements.summaryOnline')}</span>
              </div>
              <div className="summary__section">
                <div className="summary__section-row p-t-0 p-b-0">
                  <span>{t('summary.average')}</span>
                  <span className="summary__section-value">
                    {chartData.length ? averageValueByPhases : (0).toFixed(parameters.fixed)} {parameters.unit}
                  </span>
                </div>
              </div>
              <div className="summary__section">
                <div className="summary__section-row p-t-0 p-b-0">
                  <span>{t('summary.min')}</span>
                  <span className="summary__section-value">
                    {chartData.length ? minValueByPhases : (0).toFixed(parameters.fixed)} {parameters.unit}
                  </span>
                </div>
              </div>
              <div className="summary__section">
                <div className="summary__section-row p-t-0 p-b-0">
                  <span>{t('summary.max')}</span>
                  <span className="summary__section-value">
                    {chartData.length ? maxValueByPhases : (0).toFixed(parameters.fixed)} {parameters.unit}
                  </span>
                </div>
              </div>
            </div>
          </ControlWrapper>
        )}
        <ControlWrapper className="control-wrapper--column  p-l-24 p-r-24 p-b-24 p-t-24">
          <DownloadMeasurementsData
            unit={parameters.unit}
            data={chartData}
            phase={phase}
            measurementGroup={measurementGroup}
          />
          <LiveTable data={!isSummary ? chartDataBySelectedPhases : chartData} unit={parameters.unit} />
        </ControlWrapper>
      </div>
    </Page>
  );
};

export default MeasurementDetails;
