import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CorrectionSteps,
  CorrectionType,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';
import { BlindConfigurationErrorCode } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigService';
import {
  BlindWorkStats,
  ISsr_4_0_0_0,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/VersionedOptions/^4.0.0.0/ISsrVersionedService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { BlindCalibrationState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { AccessLevel } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { DialogConfirmation } from '../../../../../../components';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { toastSuccess } from '../../../../../../utils/toast';
import { useLoggedUser } from '../../../../hooks/common/logged-user';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { useSsr } from '../../hooks/use-ssr';
import AutoCalibration from '../common/auto-calibration/button';
import CorrectionStepsForm from './correction-steps';
import SsrDirections from './directions';
import HeadSensorForm from './head-sensor';
import { initialConfig, SSRConfigParams, SSRParamsEnum } from './types';
import WorkStatistics from './work-statistics';

interface ComponentProps {
  device: IDevice;
  channel?: IDeviceChannel;
}

const SsrConfigForm: React.FC<ComponentProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const [popup, setPopup] = useState<boolean>(false);
  const [config, setConfig] = useState<SSRConfigParams>(initialConfig);
  const [open, setOpen] = useState<boolean>(false);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { user } = useLoggedUser();
  const {
    handleGetBlindParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);
  const { configService, isGraterThan4 } = useSsr(device, firmwareService);

  const getDefaultParams = async (panel: SSRParamsEnum, correctionType?: CorrectionType) => {
    if (!configService) return;
    turnOnBackdrop();

    switch (panel) {
      case SSRParamsEnum.CORRECTION_STEPS: {
        if (channel && correctionType) {
          const data: CorrectionSteps | ResponseResult<BlindConfigurationErrorCode> =
            await configService.GetCorrectionStepAsync(device, channel.Number, correctionType);

          handleGetBlindParamsResponse<CorrectionSteps>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as CorrectionSteps });
            setOpen(true);
          });
        }
        break;
      }
      case SSRParamsEnum.HEAD_SENSOR: {
        if (channel) {
          const data: boolean | ResponseResult<BlindConfigurationErrorCode> = await (
            configService as ISsr_4_0_0_0
          ).GetHeadSensorStatusAsync(device, channel.Number);

          handleGetBlindParamsResponse<boolean>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as boolean });
            setOpen(true);
          });
        }
        break;
      }
      case SSRParamsEnum.WORK_STATISTICS: {
        if (channel && isGraterThan4) {
          const data: BlindWorkStats | ResponseResult<BlindConfigurationErrorCode> = await (
            configService as ISsr_4_0_0_0
          ).GetWorkLogAsync(device, channel.Number);

          handleGetBlindParamsResponse<BlindWorkStats>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as BlindWorkStats });
            setOpen(true);
          });
        }
        break;
      }
      case SSRParamsEnum.DIRECTIONS: {
        setConfig({ ...initialConfig, [panel]: true });
        setOpen(true);
        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const handleOpen = (panel: SSRParamsEnum, correctionType?: CorrectionType) => getDefaultParams(panel, correctionType);

  const handleConfirmReset = async () => {
    if (!channel) return;
    turnOnBackdrop();
    const result: BlindCalibrationState | ResponseResult<BlindConfigurationErrorCode> = await (
      configService as ISsr_4_0_0_0
    ).RunAutomaticCalibrationAsync(device, channel.Number);
    setPopup(false);

    handleGetBlindParamsResponse<BlindCalibrationState>(result, () => {
      toastSuccess({ content: t('exalus.params.SsrParams.resetSuccess') });
      turnOffBackdrop();
    });
  };

  const handleBack = () => {
    setOpen(false);
    setConfig({ ...initialConfig });
  };

  const activeConfig = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(config).find(([_, value]) => value !== null);
  }, [config]);

  const renderCorrentForm = () => {
    if (!activeConfig) return;
    const [configName, configParam] = activeConfig;

    switch (configName) {
      case SSRParamsEnum.CORRECTION_STEPS: {
        return channel ? <CorrectionStepsForm device={device} channel={channel} params={configParam} /> : null;
      }
      case SSRParamsEnum.HEAD_SENSOR: {
        return channel && configService ? (
          <HeadSensorForm
            device={device}
            channel={channel}
            params={configParam}
            configService={configService as ISsr_4_0_0_0}
          />
        ) : null;
      }
      case SSRParamsEnum.WORK_STATISTICS: {
        return channel ? <WorkStatistics params={configParam} /> : null;
      }
      case SSRParamsEnum.DIRECTIONS: {
        return channel && configService ? (
          <SsrDirections device={device} channel={channel} configService={configService as ISsr_4_0_0_0} />
        ) : null;
      }
      default:
        return null;
    }
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage
          handleBack={handleBack}
          {...(activeConfig?.[0] === SSRParamsEnum.WORK_STATISTICS
            ? { header: t('exalus.params.SsrParams.WorkStatistics') }
            : {})}
        >
          {renderCorrentForm()}
        </ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.SsrParams.LooseringParams')}
                onClick={() => handleOpen(SSRParamsEnum.CORRECTION_STEPS, 'UPPER')}
              />
              <ArrowButton
                className="m-b-24 m-b-24"
                title={t('exalus.params.SsrParams.PressureParams')}
                onClick={() => handleOpen(SSRParamsEnum.CORRECTION_STEPS, 'LOWER')}
              />
              {RemotesButton}
              {isGraterThan4 !== null && (
                <>
                  {isGraterThan4 ? (
                    <>
                      <ArrowButton
                        className="m-b-24"
                        title={t('exalus.params.SsrParams.ResetEndPositions')}
                        onClick={() => setPopup(true)}
                      />
                      <ArrowButton
                        className="m-b-24 m-b-24"
                        title={t('exalus.params.SsrParams.HeadSensor')}
                        onClick={() => handleOpen(SSRParamsEnum.HEAD_SENSOR)}
                      />
                      <ArrowButton
                        className="m-b-24 m-b-24"
                        title={t('exalus.params.SsrParams.Directions')}
                        onClick={() => handleOpen(SSRParamsEnum.DIRECTIONS)}
                      />
                      {user?.AccessLevel === AccessLevel.Support && (
                        <ArrowButton
                          className="m-b-24 m-b-24"
                          title={t('exalus.params.SsrParams.WorkStatistics')}
                          onClick={() => handleOpen(SSRParamsEnum.WORK_STATISTICS)}
                        />
                      )}
                    </>
                  ) : (
                    <AutoCalibration channel={channel} />
                  )}
                </>
              )}
              <DialogConfirmation
                show={popup}
                setShow={setPopup}
                header={t('exalus.params.SsrParams.ResetEndPositions')}
                content={t('exalus.params.SsrParams.resetMessage')}
                primaryBtnText={tc('buttons.continue')}
                secondaryBtnText={tc('buttons.cancel')}
                secondaryBtnAction={() => setPopup(false)}
                primaryBtnAction={handleConfirmReset}
                timer
              />
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default SsrConfigForm;
