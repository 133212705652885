import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { UpdateStatus } from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';
import { useBackdropContext } from '../../../hooks';
import LinearProgressCustom from '../../../modules/exalus/components/linear-progress';
import { Accordion } from '../../accordion';

export const UpdateContent: React.FC = () => {
  const { t: td } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const [accordion, setAccordion] = useState<boolean>(false);
  const { exalusPopupContent } = useBackdropContext();

  const isSuccess = useMemo(
    () =>
      exalusPopupContent?.updateContent?.status === UpdateStatus.Upgraded &&
      exalusPopupContent?.updateContent.updateNumber === exalusPopupContent?.updateContent?.availableUpdates.length,
    [exalusPopupContent?.updateContent],
  );

  const isError = useMemo(
    () =>
      exalusPopupContent?.updateContent?.status &&
      exalusPopupContent?.updateContent?.status < 0 &&
      exalusPopupContent?.updateContent.updateNumber === exalusPopupContent?.updateContent?.availableUpdates.length,
    [exalusPopupContent?.updateContent],
  );

  const handleCloseUpdatePopup = () => {
    if (exalusPopupContent?.updateContent?.handleClose) {
      exalusPopupContent?.updateContent?.handleClose();
    }
  };

  const handleChange = () => setAccordion(!accordion);

  if (!exalusPopupContent?.updateContent) return null;

  return (
    <>
      {!(isError || isSuccess) && (
        <div className={classNames('backdrop-popup--info', { bold: !exalusPopupContent.header })}>
          {exalusPopupContent.message}
        </div>
      )}
      {exalusPopupContent.updateContent.warning && !isError && !isSuccess && (
        <p className="backdrop-popup--warning">{exalusPopupContent.updateContent.warning}</p>
      )}
      {exalusPopupContent.updateContent.availableUpdates.map((name: string) => {
        if (exalusPopupContent.updateContent) {
          return (
            <LinearProgressCustom
              key={name}
              isCorrectLoader={name === exalusPopupContent.updateContent.updatedResourceName}
              progress={exalusPopupContent.updateContent.progress}
              updateName={name}
              status={exalusPopupContent.updateContent.status}
            />
          );
        }
      })}
      {exalusPopupContent.updateContent.progressTotal !== undefined && (
        <LinearProgressCustom
          isCorrectLoader
          progress={exalusPopupContent.updateContent.progressTotal}
          updateName={tc('totalProgress')}
        />
      )}
      {exalusPopupContent.updateContent.description && (
        <Accordion
          kind="controlled-base"
          title={td('exalus.params.Version.whatNew')}
          expanded={accordion}
          handleExpand={handleChange}
          titleNotbold
        >
          {exalusPopupContent.updateContent.description}
        </Accordion>
      )}
      {(exalusPopupContent.updateContent.status === UpdateStatus.Upgraded ||
        exalusPopupContent.updateContent.status < UpdateStatus.OK) &&
        exalusPopupContent.updateContent.updateNumber === exalusPopupContent.updateContent.availableUpdates.length && (
          <button className="close-btn" onClick={handleCloseUpdatePopup}>
            {tc('buttons.close')}
          </button>
        )}
    </>
  );
};
