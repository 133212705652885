import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import {
  AddNewGroupErrorCode,
  IChannelsGroup,
  SetObjectsInGroupAndOrderResponseCode,
} from 'lavva.exalushome/build/js/Services/Devices/IChannelsGroupsService';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { useBackdropContext } from '../../../../../hooks';
import { toastError, toastSuccess } from '../../../../../utils/toast';
import { useExalusContext } from '../../../context';
import { useExalusServicesContext } from '../../../context/services';
import { useExalusStorageContext } from '../../../context/storage';
import { GroupMode } from '../../../enums/group';
import {
  useExalusAddGroup,
  useExalusEditGroup,
  useExalusRemoveGroup,
  useExalusUpdateGroupWithChannels,
  useHandleDataFrameErrors,
} from '../../../hooks';
import {
  Callback,
  CreateGroupVariables,
  EditGroupVariables,
  GroupedDeviceChannelsCheckbox,
  GroupsHook,
} from '../types';

const groupModeToast = {
  CREATE: 'exalus.create_success',
  EDIT: 'exalus.edit_success',
};

export const useGroups = (): GroupsHook => {
  const history = useHistory();
  const { t } = useTranslation('groups');
  const { t: tc } = useTranslation('common');
  const [error, setError] = useState<string>('');
  const { refetchAllGroups } = useExalusContext();
  const { mutate: addGroup } = useExalusAddGroup();
  const { mutate: removeGroup } = useExalusRemoveGroup();
  const { mutate: editGroupNameAndIcon } = useExalusEditGroup();
  const { mutate: updateGroupWithChannels } = useExalusUpdateGroupWithChannels();
  const { setActiveGroup } = useExalusStorageContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { configurationApi } = useExalusServicesContext();
  const { handleError } = useHandleDataFrameErrors();

  const addNewGroup = async ({ name, iconName, groupedChannels }: CreateGroupVariables) => {
    turnOnBackdrop();
    await configurationApi.EnterConfigurationModeAsync();
    addGroup(
      { name, iconName },
      {
        onSuccess: async (data: IChannelsGroup | ResponseResult<AddNewGroupErrorCode>) => {
          await configurationApi.ExitConfigurationModeAsync();

          if ((data as IChannelsGroup)?.Guid) {
            updateGroupChannels(data as IChannelsGroup, groupedChannels, GroupMode.CREATE);
          } else {
            turnOffBackdrop();
            setError(t(`exalus.${(data as ResponseResult<AddNewGroupErrorCode>).Type}`));
          }
        },
      },
    );
  };

  const editGroup = async ({ name, iconName, groupedChannels, group }: EditGroupVariables, { onError }: Callback) => {
    if (group) {
      turnOnBackdrop();

      if (group.Name !== name || group.IconName !== iconName) {
        editGroupNameAndIcon(
          { groupGuid: group.Guid, name, iconName },
          {
            onSuccess: async (data: ResponseResult<AddNewGroupErrorCode>) => {
              await configurationApi.ExitConfigurationModeAsync();

              if (data.Type === AddNewGroupErrorCode.Success) {
                await updateGroupChannels(group, groupedChannels, GroupMode.EDIT, onError);
              } else {
                onError?.();
                turnOffBackdrop();
                setError(t(`exalus.${(data as ResponseResult<AddNewGroupErrorCode>).Type}`));
              }
            },
          },
        );
      } else {
        await updateGroupChannels(group, groupedChannels, GroupMode.EDIT, onError);
      }
    }
  };

  const deleteGroup = async (groupId?: string) => {
    if (groupId) {
      turnOnBackdrop();
      removeGroup(groupId, {
        onSuccess: async (data: Status) => {
          await configurationApi.ExitConfigurationModeAsync();

          if (data === Status.OK) {
            setActiveGroup(0);
            toastSuccess({ content: t('exalus.remove_success') });
            refetchAllGroups();
            turnOffBackdrop();
            history.goBack();
          } else {
            handleError(data);
            turnOffBackdrop();
          }
        },
      });
    }
  };

  const updateGroupChannels = async (
    group: IChannelsGroup,
    groupedChannels: GroupedDeviceChannelsCheckbox[],
    groupMode: GroupMode,
    onError?: () => void,
  ) => {
    let items: IDeviceChannel[] = [];

    groupedChannels.map((x) =>
      x.devices.map((y) => {
        y.channels.filter((ch) => ch.checked).map((z) => items.push(z.channel));
      }),
    );

    const objectsOrder = Object.entries(group.ObjectsOrder).map(([, value]) => value);
    items.map((ch) => {
      if (!objectsOrder.find((x) => x === ch.ChannelId)) {
        objectsOrder.push(ch.ChannelId);
      }
    });

    items = items.sort(
      (a: IDeviceChannel, b: IDeviceChannel) =>
        objectsOrder.findIndex((f) => f === a.ChannelId) - objectsOrder.findIndex((f) => f === b.ChannelId),
    );

    updateGroupWithChannels(
      { group, items },
      {
        onSuccess: async (data: ResponseResult<SetObjectsInGroupAndOrderResponseCode>) => {
          await configurationApi.ExitConfigurationModeAsync();

          if (data.Type === SetObjectsInGroupAndOrderResponseCode.Success) {
            toastSuccess({ content: t(groupModeToast[groupMode]) });
            refetchAllGroups();
            turnOffBackdrop();
            history.goBack();
          } else {
            onError?.();
            handleSetObjectsGroupError(data);
          }
        },
      },
    );
  };

  const handleSetObjectsGroupError = (data: ResponseResult<SetObjectsInGroupAndOrderResponseCode>) => {
    turnOffBackdrop();

    switch ((data as ResponseResult<SetObjectsInGroupAndOrderResponseCode>).Type) {
      case SetObjectsInGroupAndOrderResponseCode.GroupDoesNotExists: {
        toastError({ content: tc('exalus.setGroupsError.groupDoesNotExists') });
        break;
      }
      case SetObjectsInGroupAndOrderResponseCode.ObjectDoesNotExists: {
        toastError({ content: tc('exalus.setGroupsError.objectDoesNotExists') });
        break;
      }
      case SetObjectsInGroupAndOrderResponseCode.FatalError: {
        toastError({ content: tc('exalus.setGroupsError.fatalError') });
        break;
      }
      case SetObjectsInGroupAndOrderResponseCode.FunctionNotSupported: {
        toastError({ content: tc('exalus.setGroupsError.functionNotSupported') });
        break;
      }
    }
  };

  return {
    error,
    addNewGroup,
    editGroup,
    deleteGroup,
    updateGroupChannels,
  };
};
