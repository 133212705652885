import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Dialog } from '../../../../../../components/dialog/base';

interface PropsInterface {
  open: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  onSave: (data: number) => void;
  count: number;
  minCount?: number;
  maxCount?: number;
}

export const RepeatCountDialog: React.FC<PropsInterface> = ({
  open,
  setOpen,
  title,
  onSave,
  count,
  minCount = 1,
  maxCount = 100,
}) => {
  const { t } = useTranslation('common');
  const [selectedCount, setSelectedCount] = useState<number>(count);

  const values = useMemo(() => {
    const list: number[] = [];
    for (let i = minCount; i <= maxCount; i++) {
      list.push(i);
    }

    return list;
  }, [minCount, maxCount]);

  const onClose = useCallback(() => setOpen(false), []);

  const onSubmit = useCallback(() => {
    onSave(selectedCount);
    onClose();
  }, [selectedCount]);

  const initialSlide = useMemo(() => {
    return values.findIndex((value) => value === selectedCount);
  }, [values, selectedCount]);

  return (
    <Dialog setShow={setOpen} show={open} className="dialog--full-width">
      <div className="dialog-time-picker">
        <div className="dialog-time-picker__header">
          <h3 className="dialog-time-picker__heading">{title}</h3>
        </div>
        <div className="dialog-time-picker__time-wrapper">
          <div className="time-picker-wrapper">
            <div className="picker">
              <Swiper
                initialSlide={initialSlide}
                slidesPerView={3}
                direction="vertical"
                grabCursor
                centeredSlides
                slideToClickedSlide
                onSlideChange={(e) => {
                  setSelectedCount(values[e.realIndex]);
                }}
              >
                {values.map((value) => {
                  return (
                    <SwiperSlide key={value}>
                      <div className="time">{value}</div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="dialog-time-picker__buttons">
          <button className="dialog-time-picker__button" onClick={onClose}>
            {t('buttons.cancel')}
          </button>
          <button onClick={onSubmit} className="dialog-time-picker__button dialog-time-picker__button--active">
            {t('buttons.save')}
          </button>
        </div>
      </div>
    </Dialog>
  );
};
