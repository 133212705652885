import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { useDevices } from '../../device-list/hooks/use-devices';
import { GroupedDevice } from '../../device-list/types';
import { CheckChannel, GroupChannelsHook, GroupedDeviceChannelsCheckbox } from '../types';

export const useGroupChannels = (
  items: GroupedDeviceChannelsCheckbox[],
  setItems: Dispatch<SetStateAction<GroupedDeviceChannelsCheckbox[]>>,
  visibility?: boolean,
): GroupChannelsHook => {
  const { t } = useTranslation('groups');
  const { groupId } = useParams<{ groupId: string }>();
  const { groupedDevices, isFetched } = useDevices({ hideAll: true });

  useEffect(() => {
    if (groupedDevices) {
      const mappedDevices = groupedDevices.map((groupedDevice: GroupedDevice) => ({
        label: groupedDevice.label,
        devices: groupedDevice.devices.map((x: IDevice) => ({
          device: x,
          channels: x.Channels.map((y: IDeviceChannel) => ({
            id: y.ChannelId,
            channel: y,
            checked: visibility ? !y.IsHidden : !!y.ChannelGroups.find((x) => x === groupId),
            defaultChecked: visibility ? !y.IsHidden : !!y.ChannelGroups.find((x) => x === groupId),
          })),
        })),
      }));

      setItems(mappedDevices);
    }
  }, [groupedDevices, visibility]);

  const total = useMemo(() => {
    const channels: CheckChannel[] = [];

    items.map((x) =>
      x.devices.map((y) => {
        y.channels.map((z) => channels.push(z));
      }),
    );

    return channels;
  }, [items]);

  const selectedCount = useMemo(() => total.filter((y) => y.checked).length, [total]);

  const handleChannelOnChange = (id: string, groupLabel: string) => {
    const updatedItems = [...items];

    const foundGroupIndex = updatedItems.findIndex((x) => x.label === groupLabel);

    if (foundGroupIndex !== -1) {
      const foundDeviceIndex = updatedItems[foundGroupIndex].devices.findIndex(
        (x) => x.device.Guid === id.split('_')[0],
      );

      if (foundDeviceIndex !== -1) {
        const foundChannelIndex = updatedItems[foundGroupIndex].devices[foundDeviceIndex].channels.findIndex(
          (x) => x.id === id,
        );

        if (foundChannelIndex !== -1) {
          updatedItems[foundGroupIndex].devices[foundDeviceIndex].channels[foundChannelIndex].checked =
            !updatedItems[foundGroupIndex].devices[foundDeviceIndex].channels[foundChannelIndex].checked;
        }

        setItems(updatedItems);
      }
    }
  };

  const tabs = useMemo(
    () => [{ label: t('channels') + ` (${selectedCount}/${total.length})` }],
    [total.length, selectedCount, t],
  );

  return {
    handleChannelOnChange,
    tabs,
    items,
    isFetched,
  };
};
